<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="payLockYesNoCancelPopup"
      header="정산LOCK 해제"
      width="370"
      :animationSettings="{ effect: 'None' }"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      @close="onPayLockYesNoCancelPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">
                      [&nbsp;&nbsp;예&nbsp;&nbsp;] 선택한 {{ yesTitle }}별 "LOCK
                      해제"를 하시겠습니까?
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      [아니오] 내장팀 전체 "LOCK 해제"를 하시겠습니까?
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      [&nbsp;취소&nbsp;] LOCK 해제 작업을 취소하시겠습니까?
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onYesButtonClicked"
              >
                예
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onNoButtonClicked"
              >
                아니오
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePayLockYesNoCancelPopup"
              >
                취소
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "PayLockYesNoCancelPopup",
  components: {ErpButton},
  mixins: [commonMixin, confirmDialogMixin],
  async created() {},
  mounted() {},
  data() {
    return {
      popupData: [],
      yesTitle: null,
    };
  },
  methods: {
    showPayLockYesNoCancelPopup(popupData) {
      this.popupData = popupData;

      if (!!popupData && popupData.length > 0) {
        this.yesTitle =
          popupData[0].grpNo !== null && popupData[0].grpNo !== undefined
            ? "단체"
            : "팀";
      } else {
        this.yesTitle = "팀(단체)";
      }
    },
    onPayLockYesNoCancelPopupClosed() {
      this.$emit("popupClosed");
    },
    closePayLockYesNoCancelPopup() {
      this.$refs.payLockYesNoCancelPopup.hide();
    },
    onYesButtonClicked() {
      if (!(this.popupData.length > 0)) {
        this.infoToast("내장팀이 선택되지 않았습니다.");
        return;
      }

      this.$emit("yesButtonClicked", this.popupData[0]);
    },
    onNoButtonClicked() {
      this.$emit("noButtonClicked");
    },
  },
};
</script>
