<template>
  <div>
    <ejs-dialog
      ref="groupSettingPopup"
      :header="`단체팀 설정`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="337"
      height="161"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onGroupSettingPopupClosed"
    >
      <div class="window groupSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">단체 연결/해제 설정</div>
                      <div class="header-caption">
                        [선택 <strong>{{ selectedRowcnt }}</strong
                        >팀]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field groupName">
                              <!-- 필수 : required -->
                              <div class="title">단체명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        v-if="popupData.grpInfo"
                                        ref="grpName"
                                        v-model="
                                          popupData.grpInfo.grpName
                                        "
                                        @change="onGrpNameChanged"
                                        @keydown.enter="onGrpNameChanged"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          ref="grpSearchButton"
                                          @click.native="
                                            onGroupPopupOpen(true)
                                          "
                                        >
                                          단체 검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group text"
                                    v-if="this.popupData.grpInfo"
                                  >
                                    {{ popupData.grpInfo.grpNo }}
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="connect keyColor">
              <erp-button
                  id="groupSettingButton"
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                  @click.native="onGroupSetting"
              >
                단체 연결
              </erp-button>
            </li>
            <li class="release">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="'GroupSettingPopup.shortcuts.delete'"
                  :shortcut="{ key:'F4' }"
                  @click.native="onGroupUnSetting"
              >
                단체 해제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeGroupSettingPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getGroupList } from "@/api/group";
import { groupConnect } from "@/api/frontManagement";
import { formPreventEnterEvent } from "@/utils/formUtil";
import groupPopup from "../../golf-reservation/popup/GroupPopup";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "groupSettingPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    groupPopup,
    ErpButton
  },
  data() {
    return {
      popupData: {},
      selectedRowcnt: 0,
      isGroupPopupOpen: false,
    };
  },
  async mounted() {
    await this.$nextTick();
    this.$refs.grpName.focusIn();
  },
  methods: {
    formPreventEnterEvent,
    async onGroupSetting() {
      if (this.popupData.grpInfo.grpNo === null) {
        this.errorToast("단체를 선택해 주십시오");
        return;
      }
      if (await this.confirm("단체를 연결하시겠습니까?")) {
        await groupConnect("CONNECT", this.popupData);
        this.infoToast("단체가 연결되었습니다");
        this.$emit("popupClosed", { isReload: true });
      }
    },
    async onGroupUnSetting() {
      if (await this.confirm("단체를 해제하시겠습니까?")) {
        await groupConnect("DISCONNECT", this.popupData);
        this.infoToast("단체 연결이 해제되었습니다");
        this.$emit("popupClosed", { isReload: true });
      }
    },
    closeGroupSettingPopup() {
      this.$refs.groupSettingPopup.hide();
    },
    onGroupSettingPopupClosed() {
      this.$emit("popupClosed");
    },
    showGroupSettingPopup(popupData) {
      this.popupData = popupData;
      this.selectedRowcnt = popupData.chkinTimeList.length;
    },
    async onGrpNameChanged(args) {
      if (args.event) {
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        const response = await getGroupList({ searchValue: args.value });
        const groupList = response.value.groupInfoList;
        if (groupList && groupList.length > 1) {
          this.onGroupPopupOpen();
        } else if (groupList && groupList.length === 1) {
          // 그룹정보 설정
          this.setGrpInfos(groupList[0]);
        } else {
          this.onGroupPopupOpen(false, "TEMP");
        }
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.popupData.grpInfo.grpName;
      groupPopupData.resveDates = [this.popupData.bsnDate];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.popupData.grpInfo.grpName;
      }

      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }

      const $el = document.querySelector("#groupSettingButton");
      if ($el) {
        setTimeout(function () {
          document.activeElement.blur();
          $el.focus();
        });
      }
    },
    setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.popupData.grpInfo.grpNo = grpInfo.grpNo;
      this.popupData.grpInfo.grpKind = grpInfo.grpKind;
      this.popupData.grpInfo.grpName = grpInfo.grpName;
    },
    clearGrpInfo() {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.popupData.grpInfo.grpNo = null;
      this.popupData.grpInfo.grpKind = null;
      this.popupData.grpInfo.grpName = null;
    },
  },
};
</script>
