<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="checkCheckPopup"
      header="수표 조회"
      allowDragging="true"
      showCloseIcon="true"
      isModal="true"
      width="360"
      v-bind="dialogProps"
      @close="onCloseCheckCheckPopup"
    >
      <div id="checkCheckPopup" class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border: none; margin: -18px">
                    <ul class="lookupDetail-condition">
                      <li class="field">
                        <div class="title">수표종류</div>
                        <ul class="content">
                          <li class="item">
                            <ul class="check">
                              <li
                                v-for="item in checkInfoOptions.chkTypeOptions"
                                :key="item.comCode"
                              >
                                <label>
                                  <input
                                    type="radio"
                                    :id="'chkType' + item.comCode"
                                    v-model="checkInfo.chkType"
                                    :value="item.comCode"
                                    @change="onChkTypeRadioChange"
                                  />
                                  <i></i>
                                  <div class="label">
                                    {{ item.comName }}
                                  </div>
                                </label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">권종코드</div>
                        <ul class="content">
                          <li class="item">
                            <ejs-dropdownlist
                              id="checkInfoOptChkNo"
                              ref="checkInfoChkCode"
                              v-model="checkInfo.optChkNo"
                              :dataSource="checkInfoOptions.optChkNoOptions"
                              :allowFiltering="false"
                              :fields="commonOptions.commonCodeField"
                              cssClass="lookupDetail-condition-dropdown"
                            ></ejs-dropdownlist>
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">수표번호</div>
                        <ul class="content">
                          <li class="item">
                            <input-text
                              id="checkInfoTxtChkNo"
                              ref="checkInfoTxtChkNo"
                              v-model="checkInfo.txtChkNo"
                              placeholder="14자리(수표번호+판매은행+판매지점)"
                              :maxlength="14"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">수표금액</div>
                        <ul class="content">
                          <li class="item">
                            <input-number
                              id="checkInfoTxtChkAmt"
                              v-model="checkInfo.txtChkAmt"
                              :max="99999999"
                            ></input-number>
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">수표발행일</div>
                        <ul class="content">
                          <li class="item date">
                            <input-date
                              id="checkInfoChkDate"
                              v-model="checkInfo.ChkDate"
                            ></input-date>
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">계좌번호</div>
                        <ul class="content">
                          <li class="item">
                            <input-text
                              id="checkInfoTxtChkAcc"
                              ref="checkInfoTxtChkAcc"
                              v-model="checkInfo.txtChkAcc"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div class="title">주민등록번호</div>
                        <ul class="content">
                          <li class="item">
                            <input-text
                              id="checkInfoTxtChkId"
                              ref="checkInfoTxtChkId"
                              v-model="checkInfo.txtChkId"
                              :disabled="
                                !(
                                  checkInfo.chkType !== '0' &&
                                  checkInfo.chkType != null
                                )
                              "
                            />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :disabled="checkButtonDisabled"
                  @click.native="onCheckButtonClick"
              >
                조회
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onPopupCloseButtonClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import InputNumber from "@/components/common/InputNumber";
import InputDate from "@/components/common/datetime/InputDate";
import { validateFormRefs } from "@/utils/formUtil";
import {
  createSpinner,
  showSpinner,
  hideSpinner,
} from "@syncfusion/ej2-popups";
import { getTodayDate } from "@/utils/date";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";

import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {getDeviceInfo} from "@/utils/device";
import {openERPPay} from "@/utils/Pay";

export default {
  name: "CheckCheckPopup",
  components: {ErpButton, InputText, InputDate, InputNumber },
  props: {
    storeCode: {
      type: String,
      required: true,
    },
  },
  mixins: [confirmDialogMixin],
  created() {
    this.checkInfoOptions.chkTypeOptions = commonCodesGetCommonCode(
      "CHECK_KIND"
    );
    this.checkInfoOptions.optChkNoOptions = commonCodesGetCommonCode(
      "CHECK_TYPE"
    );

    const defaultFlagIndex = this.checkInfoOptions.chkTypeOptions.findIndex(
      (obj) => obj.defaultFlag === true
    );

    if (defaultFlagIndex !== -1) {
      this.checkInfo.chkType = this.checkInfoOptions.chkTypeOptions[
        defaultFlagIndex
      ].comCode;
    }

    const optChkNoIndex = this.checkInfoOptions.optChkNoOptions.findIndex(
      (obj) => obj.defaultFlag === true
    );

    if (optChkNoIndex !== -1) {
      this.checkInfo.optChkNo = this.checkInfoOptions.optChkNoOptions[
        optChkNoIndex
      ].comCode;
    }
  },
  mounted() {
    this.setCreateSpinner();
    this.showCheckCheckPopup();
  },
  data() {
    return {
      result: "",
      vanModule: {},
      checkInfo: {
        chkType: null,
        optChkNo: null,
        txtChkNo: null,
        ChkDate: getTodayDate(),
        txtChkDate: null,
        txtChkAmt: null,
        txtChkAcc: null,
        txtChkId: null,
        txtChkBank: null,
        txtChkBrch: null,
        txtChkNum: null,
      },
      commonOptions: {
        commonCodeField: { text: "comName", value: "comCode" },
      },
      checkInfoOptions: {
        chkTypeOptions: null,
        optChkNoOptions: null,
      },
      validateRefList: {
        checkInfoOptChkNo: { required: true },
        checkInfoTxtChkNo: { required: true },
        checkInfoTxtChkAmt: { required: true },
        checkInfoChkDate: { required: true },
      },
      checkButtonDisabled: false,
      van: null,
      bsnDate: null
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
  },
  methods: {
    validateFormRefs,
    showCheckCheckPopup() {
      this.$refs["checkCheckPopup"].show();

      this.initialize();
      // this.initialize().then(() => {
      //   this.$refs["checkCheckPopup"].show();
      // });
    },
    async initialize() {
      try {
        this.bsnDate = getTodayDate();

        ({ value: this.bsnInfo } = await getStoreBsnMstInfo(this.storeCode));

        const { vanKind } = this.bsnInfo;

        this.van = vanKind;
      } catch (error) {
        console.error("initialize.Error===>", error);
        return;
      }
    },
    onCloseCheckCheckPopup() {
      this.$emit("popupClosed");
    },
    onPopupCloseButtonClick() {
      this.$refs.checkCheckPopup.hide();
    },
    onChkTypeRadioChange: function (args) {
      if (args.target.value === "0") {
        this.checkInfo.txtChkId = null;
      }
    },
    startPay() {
      this.$refs["checkCheckPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["checkCheckPopup"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endPay() {
      this.$refs["checkCheckPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["checkCheckPopup"].$el.nextElementSibling.style.zIndex) - 1
      }`;
    },
    onCheckButtonClick: function () {
      // // 추후 validate 적용해야함.
      // if (this.validate()) {
      // };

      if (this.checkInfo.chkType == null) {
        this.errorToast("수표종류를 선택해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.optChkNo == null || this.checkInfo.optChkNo === "00") {
        this.errorToast("권종코드를 선택해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.ChkDate == null) {
        this.errorToast("수표발행일을 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkNo == null) {
        this.errorToast("수표번호 14자리를 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkNo.length !== 14) {
        this.errorToast("수표번호 14자리를 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkAmt == null) {
        this.errorToast("수표금액을 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkAmt === 0) {
        this.errorToast("수표금액은 0보다 큰 값을 입력해 주시기 바랍니다");
        return;
      }

      this.checkButtonDisabled = true;

      const txtChkDate = new Date(this.checkInfo.ChkDate);
      const txtYear = txtChkDate.getFullYear().toString().substr(2);
      const txtMonth = txtChkDate.getMonth() + 1;
      const txtDate = txtChkDate.getDate();

      this.checkInfo.txtChkDate =
        txtYear.toString() +
        (txtMonth.toString().length === 2
          ? txtMonth.toString()
          : "0" + txtMonth.toString()) +
        (txtDate.toString().length === 2
          ? txtDate.toString()
          : "0" + txtDate.toString());

      //best 추가
      this.checkInfo.chkType = "0"+this.checkInfo.chkType;
      this.checkInfo.txtChkBank = this.checkInfo.txtChkNo.substr(8,2);
      this.checkInfo.txtChkBrch = this.checkInfo.txtChkNo.substr(10);
      this.checkInfo.txtChkNum = this.checkInfo.txtChkNo.substr(0,8);
      this.viewCheck();
    },
    async viewCheck() {
      try {
        this.startPay();

        const addTransactionRequest = {
          van: this.van,
          payDivision: "CHECK",
          approvalDivision: "OK",
          posNo: getDeviceInfo().posNo,
          termId: this.bsnInfo.termId,
          checkPubDate: this.checkInfo.txtChkDate,
          checkNo: this.checkInfo.txtChkNo,
          checkAccount: this.checkInfo.txtChkAcc,
          checkType: this.checkInfo.optChkNo,
          chkType: this.checkInfo.chkType,
          chkId: this.checkInfo.txtChkId,
          chkNum: this.checkInfo.txtChkNum,
          chkBank: this.checkInfo.txtChkBank,
          chkBrch: this.checkInfo.txtChkBrch,
          payDate: this.bsnDate,
          totalAmount: this.checkInfo.txtChkAmt,
          taxAmount: 0,
          vatAmount: 0,
          notaxAmount: 0,
          serviceAmount: 0,
          storeCode: "FRONT",
          businessId: this.bsnInfo.bsnId
        };

        const {message} = await this.payVan(
          addTransactionRequest
        );

        await this.alert(message);
      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage = e.message;
          console.error(e);
        } else {
          this.operationMessage = e;
        }

        if (this.operationMessage !== "CONFIRM") {
          await this.errorPopup(
            "조회에 실패하였습니다.<br/>" + this.operationMessage
          );
        }
      } finally {
        this.checkButtonDisabled = false;
        this.endPay();
      }
    },
    async payVan(addTransactionRequest) {
      const { tid, status, message } = await openERPPay(addTransactionRequest);

      switch (status) {
        case "APPROVED":
        case "OK":
          return { tid, status, message };
        case "READY":
        case "REQUEST":
          throw new Error("수표조회가 취소되었습니다.");
        case "FAIL":
          throw new Error(message);
      }
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    setCreateSpinner() {
      createSpinner({
        // Specify the target for the spinner to show
        target: document.getElementById("checkCheckPopup"),
        type: "Fabric",
      });
    },
    getShowSpinner() {
      showSpinner(document.getElementById("checkCheckPopup"));
    },
    getHideSpinner() {
      hideSpinner(document.getElementById("checkCheckPopup"));
    },
  },
};
</script>

<style scoped></style>
