<template>
  <div>
    <ejs-dialog
      header="내장객을 삭제하시겠습니까?"
      ref="visitDeleteConfirmPopup"
      width="400"
      v-bind="dialogProps"
      :allowDragging="true"
      :isModal="true"
      :showCloseIcon="true"
      :animationSettings="{ effect: 'None' }"
      :close="handleDialogClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div class="section-body">
                  <div class="body-data">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">삭제사유</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-textarea
                                      class="lookup-condition-textarea"
                                      ref="delReason"
                                      v-model="delReason"
                                    />
                                  </div>
                                  <div class="validation">
                                    <div class="e-tip-content"></div>
                                    <div class="e-tip-top"></div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="delete keyColor">
            <li class="delete">
              <erp-button
                  button-div="DELETE"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  @click.native="deleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="delete">
              <erp-button
                  button-div="DELETE"
                  :is-custom-shortcut-button="true"
                  :shortcut-key="'VisitDeleteConfirmPopup.shortcut.allDelete'"
                  :shortcut="{key:'F3'}"
                  @click.native="allDeleteButtonClicked"
              >
                일괄삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window .windowContent .body-article .section-body {overflow: visible; border: none;}
body .window .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputTextarea from "@/components/common/text/InputTextarea";
import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getDeviceInfo} from "@/utils/device";

export default {
  name: "VisitDeleteConfirmPopup",
  components: {
    InputTextarea,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  created() {
  },
  data() {
    return {
      popupData: null,
      delReason: ".",
      validateRefList: {
        delReason: {
          required: true
        }
      },
      timeData: {
        chkinId: null,
        resveId: null
      }
    };
  },
  computed: {
    dialogProps() {
      return {
        position: { x: "center", y: "center" }
      };
    }
  },
  methods: {
    validateFormRefs,
    handleDialogClose() {
      this.$emit("popupClosed");
    },
    closeButtonClicked() {
      this.$refs.visitDeleteConfirmPopup.hide();
    },
    showPopup(popupData) {
      this.popupData = popupData;
    },
    async deleteButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      const deleteVisitors = this.popupData.deletedRows.map(data => {
        return {
          visitId: data.visitId,
          chkinId: data.chkinId
        };
      });

      // 내장 정보 등록 저장 API
      GolfErpAPI.deleteVisitor(
        this.popupData.teamInfo,
        deleteVisitors,
        this.popupData.resveId,
        this.delReason,
        getDeviceInfo().posNo
      )
        .then((response) => {
          // alert("저장하였습니다");
          // 저장후 받아오는 chkinId와 resveId 셋팅.
          if (response.chkinId) {
            this.timeData.chkinId = response.chkinId;
          } else {
            this.timeData.chkinId = null;
          }
          if (response.resveId) {
            this.timeData.resveId = response.resveId;
          } else {
            this.timeData.resveId = null;
          }

          if (!this.timeData.chkinId && !this.timeData.resveId) {
            // 저장후 chkinId와 resveId가 둘다 없는 경우 Layer 닫고 내장객 현황 타임목록 Refresh.
            this.$emit("dataRefresh");
          } else {
            this.$emit("popupConfirm", {
              deletedIdx: this.popupData.deletedIdx,
              timeData: this.timeData
            });
          }

          this.closeButtonClicked();
        })
        .catch((error) => {
          console.log("saveCheckin.err.===>", error);
        });
    },
    async allDeleteButtonClicked() {
      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      const deleteVisitors = this.popupData.visitInfoList.map(data => {
        return {
          visitId: data.visitId,
          chkinId: data.chkinId
        };
      });

      // 내장 정보 등록 저장 API
      GolfErpAPI.deleteVisitor(
        this.popupData.teamInfo,
        deleteVisitors,
        this.popupData.resveId,
        this.delReason,
        getDeviceInfo().posNo
      )
        .then((response) => {
          // alert("저장하였습니다");
          // 저장후 받아오는 chkinId와 resveId 셋팅.
          if (response.chkinId) {
            this.timeData.chkinId = response.chkinId;
          } else {
            this.timeData.chkinId = null;
          }
          if (response.resveId) {
            this.timeData.resveId = response.resveId;
          } else {
            this.timeData.resveId = null;
          }

          if (!this.timeData.chkinId && !this.timeData.resveId) {
            // 저장후 chkinId와 resveId가 둘다 없는 경우 Layer 닫고 내장객 현황 타임목록 Refresh.
            this.$emit("dataRefresh");
          } else {
            this.$emit("popupConfirm", {
              deletedIdx: this.popupData.deletedIdx,
              timeData: this.timeData
            });
          }

          this.closeButtonClicked();
        })
        .catch((error) => {
          console.log("saveCheckin.err.===>", error);
        });
    }
  }
};
</script>