<template>
  <div>
    <ejs-dialog
        ref="newPartialSumPopup"
        header="금액 이동"
        :allowDragging="true"
        :showCloseIcon="true"
        :width="isFoodSalesVisible ? 1856 : 1511"
        height="595"
        :animationSettings="{ effect: 'None' }"
        :isModal="true"
        @beforeClose="onPopupBeforeClose"
        @close="onPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">분할 방법</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              name="divideByNPopupDivideDiv"
                                              v-model="popupData.divideDiv"
                                              value="T"
                                              @change="divideDivChanged"
                                            />
                                            <i></i>
                                            <div class="label">팀</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              name="divideByNPopupDivideDiv"
                                              v-model="popupData.divideDiv"
                                              value="G"
                                              :disabled="
                                                !(popupData && popupData.grpNo)
                                              "
                                              @change="divideDivChanged"
                                            />
                                            <i></i>
                                            <div class="label">단체</div>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">분할 매장</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data header">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field checkbox">
                                <div class="title">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              :checked="
                                                storeCodes.length ===
                                                totalStoreCodes.length
                                              "
                                              @change="storeCodeCheckAllChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="title">매장</div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul
                              class="data-content"
                              v-for="code in totalStoreCodes"
                              :key="code.comCode"
                            >
                              <li class="field checkbox">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="storeCodes"
                                              :value="code.comCode"
                                              @change="storeCodeCheckBoxChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ code.comName }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-03">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">분할 단위</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data header">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field radio">
                                <div class="title"></div>
                              </li>
                              <li class="field unit">
                                <div class="title">단위</div>
                              </li>
                              <li class="field scroll">
                                <div class="title"></div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul
                              class="data-content"
                              v-for="code in totalDivideUnit"
                              :key="code.comCode"
                            >
                              <li class="field radio">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              id="divideUnit"
                                              v-model="divideUnit"
                                              :value="code.comCode"
                                              @change="divideUnitRadioChanged"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field unit">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ code.comName }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-04">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">
                          분할 목록
                        </div>
                      </div>
                      <div class="header-right">
                        <ul class="lookup-condition">
                          <li class="field">
                            <ul class="content">
                              <li class="item">
                                <ul class="check">
                                  <li>
                                    <label>
                                      <input
                                        type="checkbox"
                                        v-model="isEnterTheAmountDirectly"
                                        @change="onIsEnterTheAmountDirectlyChecked"
                                      />
                                      <i></i>
                                      <div class="label">금액직접입력</div>
                                    </label>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-box">
                        <ejs-grid-wrapper
                          ref="visitPaysGrid"
                          v-bind="gridOptions"
                          :dataSource="visitPays"
                          @headerCellInfo="onVisitPaysGridHeaderCellInfo"
                          @queryCellInfo="onVisitPaysGridQueryCellInfo"
                          @recordDoubleClick="onVisitPaysGridRecordDoubleClick"
                          @cellSaved="onVisitPaysGridCellSaved"
                          @actionBegin="onVisitPaysActionBegin"
                          @actionComplete="onVisitPaysActionComplete"
                        />
                      </div>
                      <div
                        class="body-footer"
                        style="
                          border: 1px solid #ccc;
                          border-top: none;
                        "
                      >
                        <ul>
                          <li
                            v-for="[key,value] in Object.entries(totalInfo).filter(item => item[0] !== 'visitCount')"
                            :key="key"
                            :style="{
                              width: `${key === 'visitCountName' ? '398px' : (key !== 'totAmt' ? totalInfoColWidth : '71px')}`,
                              textAlign: 'right',
                            }"
                            @dblclick="onDifferenceInfoDbclick($event, key, value)"
                          >
                            {{ key === "visitCountName" ? value : numberWithCommas(value)}}
                          </li>
                        </ul>
                      </div>
                      <div
                        class="body-footer"
                        style="
                          border: 1px solid #ccc;
                          border-top: none;
                        "
                      >
                        <ul>
                          <li
                            v-for="[key,value] in Object.entries(differenceInfo).filter(item => item[0] !== 'visitCount')"
                            :key="key"
                            :style="{
                              width: `${key === 'visitCountName' ? '398px' : (key !== 'totAmt' ? totalInfoColWidth : '71px')}`,
                              textAlign: 'right',
                              color: `${key !== 'visitCountName' && value !== 0 ? 'red' : ''}`,
                              fontWeight: `${key !== 'visitCountName' && value !== 0 ? 'bold' : ''}`,
                            }"
                            @dblclick="onDifferenceInfoDbclick($event, key, value)"
                          >
                            {{ key === "visitCountName" ? value : numberWithCommas(value)}}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <section
                    class="article-section section-05 reservation-accordion"
                    :class="[{ 'reservation-close': !isFoodSalesVisible },]"
                  >
                    <div class="section-header">
                      <div class="header-left">
                        <div
                          class="header-title"
                          @click="onFoodSalesHeaderTitleClick"
                        >
                          식음매출
                        </div>
                        <div class="header-caption">
                          [{{ foodSales.length }}건]
                        </div>
                      </div>
                    </div>
                    <div class="section-body" style="height: calc(100% - 46px)">
                      <div class="body-box">
                        <ejs-grid-wrapper
                          ref="foodSalesGrid"
                          v-bind="foodSalesGridOptions"
                          :dataSource="foodSales"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <div class="lookup-condition">
            <ul class="button">
              <li class="reset">
                <erp-button
                    button-div="GET"
                    :is-icon-custom="true"
                    @click.native="onInitButtonClicked"
                >
                  초기화
                </erp-button>
              </li>
            </ul>
          </div>
          <ul class="button">
            <li class="">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  :is-custom-shortcut-button="true"
                  shortcut-key="NewPartialSumPopup.shortcut.divide"
                  :shortcut="{key:'F3'}"
                  @click.native="onDivideButtonClicked"
              >
                1/N 분할
              </erp-button>
            </li>
            <li class="keyColor confirm">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window {min-width: calc(1300px - 2px);}

body .window .windowContent .body-article .article-left {width: 164px;}
body .window .windowContent .body-article .article-right {width: calc(100% - 164px);}
body .window .windowContent .body-article .article-section {height: auto;}

body .window .windowContent .article-section .section-body {overflow: visible; border: none;}

body .window .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}
body .window .windowContent .article-section.section-01 .body-data .field .content {width: 151px;}
body .window .windowContent .article-section.section-01 .body-data .field .content .item {text-align: center; line-height: 0;}
body .window .windowContent .article-section.section-01 .body-data .field .content .item .check {display: inline-block; padding: 9px 15px; line-height: 1.5;}

body .window .windowContent .article-section.section-02 .section-body {overflow: visible; border: none;}
body .window .windowContent .article-section.section-02 .body-data .field.checkbox {width: 40px;}
body .window .windowContent .article-section.section-02 .body-data .field.checkbox .title,
body .window .windowContent .article-section.section-02 .body-data .field.checkbox .content label {padding: 0 7px;}
body .window .windowContent .article-section.section-02 .body-data .field.store {width: calc((100% - 40px) + 2px);}

body .window .windowContent .article-section.section-03 .body-data .field {width: calc((100% - 40px) + 2px);}
body .window .windowContent .article-section.section-03 .body-data .field.radio {width: 40px;}
body .window .windowContent .article-section.section-03 .body-data .field.radio .title,
body .window .windowContent .article-section.section-03 .body-data .field.radio .content label {padding: 0 7px;}
body .window .windowContent .article-section.section-03 .body-data.header .field.unit {width: calc((100% + 1px) - (57px - 1px));}
body .window .windowContent .article-section.section-03 .body-data.body .field.unit {width: calc((100% + 1px) - (57px - 1px - 17px));}
body .window .windowContent .article-section.section-03 .body-data.body {overflow: hidden; height: 97px;}
body .window .windowContent .article-section.section-03 .body-data.body .data-outer {height: 100%; overflow-y: scroll;}

body .window .windowContent .article-section.section-04 {height: 100%; width: 1172px; float: left;}
body .window .windowContent .article-section.section-04 .section-body {height: calc(100% - 46px);}
body .window .windowContent .article-section.section-04 .section-body .body-box {height: calc(100% - 48px);}
body .window .windowContent .article-section.section-04 .section-body .body-footer {overflow: hidden; box-sizing: border-box; height: 24px; padding: 0 8px; border-top: 1px solid #e0e0e0; background-color: #f9f9f9; color: #000;}
body .window .windowContent .article-section.section-04 .section-body .body-footer ul {display: flow-root; list-style: none; width: 2000px; padding: 0; margin: 0 -9px;}
body .window .windowContent .article-section.section-04 .section-body .body-footer ul:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .window .windowContent .article-section.section-04 .section-body .body-footer ul li {float: left; padding: 3px 9px 3px 10px; margin: 0 0 -1px 0; background: transparent url('../../../assets/images/common/division.png') no-repeat left center;}
body .window .windowContent .article-section.section-04 .section-body .body-footer ul li:first-child {padding: 3px 9px; background: none;}

body .window .windowContent .article-section.section-05 {height: 100%; width: 500px;}
body .window .windowContent .article-section.section-05 .section-body {height: 100%;}
body .window .windowContent .article-section.section-05 .section-body .body-box {height: 100%;}

body .window .article-section.section-05.reservation-accordion > .section-header .header-title {padding: 0 0 0 24px; background: transparent url('../../../assets/images/common/accordion-left.png') no-repeat left center; cursor: pointer;}
body .window .article-section.section-05.reservation-accordion.reservation-close > .section-header .header-title {padding: 0 0 0 24px; background: transparent url('../../../assets/images/common/accordion-right.png') no-repeat left center; cursor: pointer;}
body .window .article-section.section-05.reservation-accordion.reservation-close {width: 155px;}
body .window .article-section.section-05.reservation-accordion.reservation-close .section-body {border-left: 1px dotted #ccc !important;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";

import {
  Edit,
  ForeignKey,
  Aggregate,
  Group,
  ExcelExport,
  Page,
} from '@syncfusion/ej2-vue-grids';

import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
  commonCodesGetComName
} from "@/utils/commonCodes";
import {getVisitPays} from "@/api/frontManagementCheckin";
import {numberWithCommas} from "@/utils/number";
import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import { groupBy as _groupBy, keys as _keys } from "lodash";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "NewPartialSumPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton
  },
  data() {
    return {
      isSave: false,
      popupData: {},
      totalDivideUnit: commonCodesGetCommonCode("DIVIDE_UNIT", true),
      divideUnit: commonCodeGetComCodeDefaultValue("DIVIDE_UNIT"),
      storeCodes: commonCodesGetCommonCode("STORE_GROUP")
        .filter(code => code.comCodeAttrbList.filter(attrb => attrb.idx === 1).map(attrb => attrb.attrb).includes("Y"))
        .map(
          (code) => code.comCode
        ),
      totalStoreCodes:
        commonCodesGetCommonCode("STORE_GROUP")
          .filter(code => code.comCodeAttrbList.filter(attrb => attrb.idx === 1).map(attrb => attrb.attrb).includes("Y") && code.useFlag),
      isEnterTheAmountDirectly: true,
      isEnterTheAmountDirectlyChecked: false,
      isFoodSalesVisible: true,
      visitPaysGridBatchCurrentViewRecords: [],
      orgVisitPays: [],
      visitPays: [],
      foodSales: [],
      totalInfo: {
        visitCount: 0,
        visitCountName: "0명",
        totAmt: 0,
        frontAmt: 0,
        cartAmt: 0,
        rentAmt: 0,
        grillAmt: 0,
        teeAmt: 0,
        shopAmt: 0,
        cfrontAmt: 0,
        etcAmt: 0,
      },
      differenceInfo: {
        visitCount: 0,
        visitCountName: "차액",
        totAmt: 0,
        frontAmt: 0,
        cartAmt: 0,
        rentAmt: 0,
        grillAmt: 0,
        teeAmt: 0,
        shopAmt: 0,
        cfrontAmt: 0,
        etcAmt: 0,
      },
      copyData: {
        rowIndex: null,
        field: null,
        data: 0,
      },
    };
  },
  computed: {
    totalInfoColWidth() {
      return (478 / Object.entries(this.totalInfo).filter(item => !['visitCount', 'visitCountName', 'totAmt'].includes(item[0])).length) + "px";
    },
    foodSalesGridOptions() {
      return {
        provides: [
          Edit,
          Aggregate,
          Group,
          ExcelExport,
          ForeignKey,
          Page,
        ],
        allowFiltering: false,
        allowSorting: false,
        noColumnWidth: 35,
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        columns: [
          {
            field: "storeCode",
            headerText: "매장",
            type: "string",
            minWidth: 16,
            width: 80,
            isCommonCodeField: true,
            groupCode: "STORE_CODE",
          },
          {
            field: "visitName",
            headerText: "내장객",
            type: "string",
            minWidth: 16,
            width: 80,
          },
          {
            field: "productName",
            headerText: "상품명",
            type: "string",
            minWidth: 16,
            width: 130,
          },
          {
            field: "saleQty",
            headerText: "수량",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 45,
            textAlign: "right",
          },
          {
            field: "totAmt",
            headerText: "금액",
            type: "number",
            isNumericType: true,
            minWidth: 16,
            width: 90,
            textAlign: "right",
          },
        ],
      };
    },
    gridOptions() {
      const storeCodes = JSON.parse(JSON.stringify(this.totalStoreCodes));
      storeCodes.push(
        {
          comCode: "etc",
          comName: "기타매출",
        },
      );
      const storeColumns = storeCodes.map(store => {
        return {
          field: store.comCode.toLowerCase().concat("Amt"),
          headerText: store.comName,
          type: "number",
          isNumericType: true,
          minWidth: 16,
          width: (630 / storeCodes.length),
          textAlign: "right",
          allowEditing: this.isEnterTheAmountDirectly,
        };
      });
      storeColumns.unshift(
        {
          field: "visitId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "startTime",
          headerText: "시간",
          type: "string",
          minWidth: 16,
          width: 60,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "startCourse",
          headerText: "코스",
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "center",
          allowEditing: false,
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
        },
        {
          field: "lockerNo",
          headerText: "락카",
          type: "string",
          minWidth: 16,
          width: 60,
          textAlign: "center",
          allowEditing: false,
        },
        {
          field: "visitName",
          headerText: "내장객",
          type: "string",
          minWidth: 16,
          width: 90,
          allowEditing: false,
        },
        {
          field: "isSelect",
          headerText: "",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          isSelectAllColumn: true,
          minWidth: 16,
          width: 40,
          textAlign: "Center",
        },
        {
          field: "totAmt",
          headerText: "합계",
          type: "number",
          isNumericType: true,
          minWidth: 16,
          width: 90,
          textAlign: "right",
          allowEditing: false,
        },
        {
          field: "isDivide",
          headerText: "",
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          isSelectAllColumn: true,
          minWidth: 16,
          width: 40,
          textAlign: "Center",
        },
      );

      return {
        provides: [
          Edit,
          Aggregate,
          Group,
          ExcelExport,
          ForeignKey,
          Page,
        ],
        allowFiltering: false,
        allowSorting: false,
        noColumnWidth: 35,
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        columns: storeColumns,
      };
    },
    divideButtonProps() {
      return {
        shortcutKey: "NewPartialSumPopup.shortcuts.divide",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F3,
        },
      };
    },
  },
  created() {},
  methods: {
    numberWithCommas,
    showPopup(args) {
      this.popupData = JSON.parse(JSON.stringify(args));

      this.getVisitPays();
    },
    async getVisitPays() {
      const {
        value: {
          visitPays,
          storeSales,
        },
      } = await getVisitPays({
        chkinId: this.popupData.chkinId,
        grpNo: this.popupData.divideDiv === "G" ? this.popupData.grpNo : null,
      });

      let chkinId = null;
      let teamSno = 1;
      this.visitPays =
        visitPays.map((visitPay, idx) => {
          // teamSno : 팀별 Row 색상 구분을 위한 데이터.
          if (chkinId !== null && chkinId !== visitPay.chkinId) {
            teamSno += 1;
          }
          let rtnData = ({
            ...visitPay,
            frontAmt: visitPay.greenFeeAmt,
            teamSno: teamSno,
            isSelect: true,
            isDivide: true,
            sortNo: idx,
          });
          this.totalStoreCodes.forEach(storeCode => {
            if (!rtnData[`${storeCode.comCode.toLowerCase().concat("Amt")}`]) {
              rtnData[`${storeCode.comCode.toLowerCase().concat("Amt")}`] = 0;
            }
          });
          chkinId = visitPay.chkinId;
          return rtnData;
        });
      this.orgVisitPays = JSON.parse(JSON.stringify(this.visitPays));

      this.foodSales = storeSales.filter(item => item.storeSaleAttrb === "FOOD");

      this.totalAndDifferenceInfoSetting();
    },
    totalAndDifferenceInfoSetting() {
      this.totalInfo = {
        visitCount: this.orgVisitPays.length,
        visitCountName: this.orgVisitPays.length + "명",
        totAmt: this.orgVisitPays.map(item => item.totAmt).reduce((acc, cur) => acc + cur, 0),
        frontAmt: this.orgVisitPays.map(item => item.frontAmt).reduce((acc, cur) => acc + cur, 0),
        cartAmt: this.orgVisitPays.map(item => item.cartAmt).reduce((acc, cur) => acc + cur, 0),
        rentAmt: this.orgVisitPays.map(item => item.rentAmt).reduce((acc, cur) => acc + cur, 0),
        grillAmt: this.orgVisitPays.map(item => item.grillAmt).reduce((acc, cur) => acc + cur, 0),
        teeAmt: this.orgVisitPays.map(item => item.teeAmt).reduce((acc, cur) => acc + cur, 0),
        shopAmt: this.orgVisitPays.map(item => item.shopAmt).reduce((acc, cur) => acc + cur, 0),
        cfrontAmt: this.orgVisitPays.map(item => item.cfrontAmt).reduce((acc, cur) => acc + cur, 0),
        etcAmt: this.orgVisitPays.map(item => item.etcAmt).reduce((acc, cur) => acc + cur, 0),
      };

      let batchCurrentViewRecords = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords()));
      // 최초 팝업 오픈시 Grid에 데이터 셋팅 전 실행될 경우 데이터가 없는 상황을 위함.
      if (
        !(batchCurrentViewRecords.length > 0) ||
        batchCurrentViewRecords.length !== this.visitPays.length
      ) {
        batchCurrentViewRecords = JSON.parse(JSON.stringify(this.visitPays));
      }

      this.differenceInfo = {
        visitCount: batchCurrentViewRecords.length,
        visitCountName: "차액",
        totAmt: this.totalInfo.totAmt - batchCurrentViewRecords.map(item => item.totAmt).reduce((acc, cur) => acc + cur, 0),
        frontAmt: this.totalInfo.frontAmt - batchCurrentViewRecords.map(item => item.frontAmt).reduce((acc, cur) => acc + cur, 0),
        cartAmt: this.totalInfo.cartAmt - batchCurrentViewRecords.map(item => item.cartAmt).reduce((acc, cur) => acc + cur, 0),
        rentAmt: this.totalInfo.rentAmt - batchCurrentViewRecords.map(item => item.rentAmt).reduce((acc, cur) => acc + cur, 0),
        grillAmt: this.totalInfo.grillAmt - batchCurrentViewRecords.map(item => item.grillAmt).reduce((acc, cur) => acc + cur, 0),
        teeAmt: this.totalInfo.teeAmt - batchCurrentViewRecords.map(item => item.teeAmt).reduce((acc, cur) => acc + cur, 0),
        shopAmt: this.totalInfo.shopAmt - batchCurrentViewRecords.map(item => item.shopAmt).reduce((acc, cur) => acc + cur, 0),
        cfrontAmt: this.totalInfo.cfrontAmt - batchCurrentViewRecords.map(item => item.cfrontAmt).reduce((acc, cur) => acc + cur, 0),
        etcAmt: this.totalInfo.etcAmt - batchCurrentViewRecords.map(item => item.etcAmt).reduce((acc, cur) => acc + cur, 0),
      };
    },
    async closePopup() {
      this.$refs.newPartialSumPopup.hide();
    },
    async onPopupBeforeClose(args) {
      if (this.isDifferent()) {
        // 수정 사항이 있을 경우 팝업을 닫지 않기 위함
        args.cancel = true;
        if (!(await this.confirm("수정된 내용이 적용되지 않았습니다.<br>종료하시겠습니까?"))) {
          return;
        }
        // args.cancel = true; 이면 아래와 같이 작성해야 팝업이 닫힘.
        this.$emit("popupClosed", this.isSave);
      }
    },
    onPopupClosed() {
      this.$emit("popupClosed", this.isSave);
    },
    async divideDivChanged() {
      this.getVisitPays();
    },
    storeCodeCheckAllChecked(args) {
      const {
        target: {
          checked
        }
      } = args;

      if (checked) {
        this.storeCodes =
          commonCodesGetCommonCode("STORE_GROUP")
            .filter(code => code.comCodeAttrbList.filter(attrb => attrb.idx === 1).map(attrb => attrb.attrb).includes("Y"))
            .map(
              (code) => code.comCode
            );
      } else {
        this.storeCodes = [];
      }
    },
    storeCodeCheckBoxChecked() {},
    divideUnitRadioChanged() {},
    onVisitPaysGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;

      if (field === "totAmt") {
        node.style.borderRight = "1px solid #000";
      }
    },
    onVisitPaysGridQueryCellInfo(args) {
      const {
        cell,
        data,
        column: {
          field,
        },
      } = args;

      if (data.teamSno%2 === 0) {
        cell.style.backgroundColor = "#D3ECF5";
      }

      if (
        ["isSelect","isDivide"].includes(field) ||
        (this.isEnterTheAmountDirectly && field !== "totAmt" && field.endsWith("Amt"))
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }

      if (field === "totAmt") {
        cell.style.borderRight = "1px solid #000";
      }
    },
    onVisitPaysGridRecordDoubleClick(args) {
      // 금액직접입력 상태에서는 더블클릭 사용 못함.
      if (this.isEnterTheAmountDirectly) {
        return;
      }

      const {
        column: {
          field
        },
        rowData,
        rowIndex
      } = args;

      if (
        ["isSelect","isDivide","startTime","startCourse","lockerNo","visitName","totAmt"].includes(field) ||
        !field
      ) {
        return;
      }

      const visitPayRecord = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords().filter(item => item.visitId === rowData.visitId)[0]));

      // this.copyData.data !== 0 이면 복사된 데이터가 있는 경우.
      if (this.copyData.data !== 0) {
        // 복사한 항목과 다른 항목에는 합산 불가능하게 하기 위함.(예: 복사된 입장료 데이터는 카트대여 데이터에 합산할 수 없음)
        if (this.copyData.field !== field) {
          this.errorToast("같은 항목이 아닙니다");
          return;
        }

        this.$refs.visitPaysGrid.updateCell(rowIndex, field, visitPayRecord[field] + this.copyData.data);
        this.$refs.visitPaysGrid.updateCell(rowIndex, "totAmt", visitPayRecord.totAmt + this.copyData.data);

        // 복사 데이터 초기화
        this.copyData = {
          rowIndex: null,
          field: null,
          data: 0,
        };
      } else {
        this.$refs.visitPaysGrid.updateCell(rowIndex, field, 0);
        this.$refs.visitPaysGrid.updateCell(rowIndex, "totAmt", visitPayRecord.totAmt - visitPayRecord[field]);

        // 더블클릭한 데이터 복사
        this.copyData = {
          rowIndex: rowIndex,
          field: field,
          data: visitPayRecord[field],
        };
      }

      // 데이터 조정후 합계 및 차액 계산.
      this.totalAndDifferenceInfoSetting();
    },
    onVisitPaysGridCellSaved(args) {
      const {
        cell: {
          parentNode: {
            rowIndex
          },
        },
        column: {
          field
        },
        rowData,
        previousValue,
        value,
      } = args;

      if (["isSelect","isDivide"].includes(field)) {
        return;
      }

      if (previousValue === value) {
        return;
      }

      // 수정된 Row의 합계 금액 계산후 셋팅
      this.$refs.visitPaysGrid.updateCell(rowIndex, "totAmt", rowData.totAmt + (value - rowData[field]));

      // 데이터 조정후 합계 및 차액 계산.
      this.totalAndDifferenceInfoSetting();
    },
    onDifferenceInfoDbclick(args, key, value) { // 차액 항목 복사 기능
      if (key !== "visitCountName" && value !== 0) {
        this.infoToast("차액이 복사되었습니다");
        this.copyData = {
          rowIndex: null,
          field: key,
          data: value,
        };
      }
    },
    async onInitButtonClicked() {
      if (!(await this.confirm("데이터를 초기화 하시겠습니까?"))) {
        return;
      }

      await this.init();
    },
    async init() {
      await this.getVisitPays();

      this.copyData = {
        rowIndex: null,
        field: null,
        data: 0,
      };

      this.visitPaysGridBatchCurrentViewRecords = [];
      this.isEnterTheAmountDirectlyChecked = false;

      this.totalAndDifferenceInfoSetting();
    },
    onDivideButtonClicked() {
      let visitPays = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords()));
      let divideData = JSON.parse(JSON.stringify(visitPays.filter(visitPay => visitPay.isDivide)));
      let visitorCount = visitPays.filter(visitPay => visitPay.isSelect).length;

      if (!(visitorCount > 0)) {
        return;
      }

      this.storeCodes.forEach(storeCode => {
        const amtCode = storeCode.toLowerCase();

        if (this.popupData.divideDiv === "T") { // 팀별 분할
          // 팀별 분할을 위해 chkinId 수집.
          const chkinIds = _keys(_groupBy(this.orgVisitPays.map(item => item.chkinId))).map(item => Number(item));

          // 팀별 분할을 위해 chkinIds loop.
          chkinIds.forEach(chkinId => {
            visitPays = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords())).filter(item => item.chkinId === chkinId);
            // 본인 금액을 분할할 대상(isDivide)인 데이터.
            divideData = JSON.parse(JSON.stringify(visitPays.filter(visitPay => visitPay.isDivide)));
            visitorCount = visitPays.filter(visitPay => visitPay.isSelect).length;

            // Check(isSelect)된 내장객이 없는 경우 Return.
            if (!(visitorCount > 0)) {
              return;
            }

            // 해당 매장 데이터 합산
            const totAmt = divideData
              .map(visitPay => visitPay[amtCode.concat("Amt")])
              .reduce((acc, cur) => acc + cur, 0);

            // 합산 데이터가 없는 경우 Return.
            if (!(totAmt > 0)) {
              return;
            }

            // 분할 단위
            const divideUnit = Number(this.divideUnit);
            // 기본 분할 금액
            const tempAmt = Math.floor(totAmt / visitorCount / divideUnit) * divideUnit;
            // 적용될 두번째 분할 금액(기본 분할 금액에 분할 단위를 더한 금액)
            const secondAmt = tempAmt + divideUnit;
            // 적용될 첫번째 분할 금액
            const firstAmt = Math.round(tempAmt / 10) * 10;
            // 첫번째 분할 금액을 몇명에게 적용할 것인지에 대한 데이터
            const firstLoop = Math.floor((totAmt - (secondAmt * visitorCount)) / (firstAmt - secondAmt));
            // 전체 금액에서 첫번째 분할 금액과 두번째 분할 금액을 모두 적용한 총액을 뺀 나머지 금액.
            const remainder = totAmt - ((firstAmt * firstLoop) + (secondAmt * (visitorCount - firstLoop)));

            // 분할 여부 Count.
            let divideCnt = 0;
            visitPays
              .sort((a, b) => a.sortNo - b.sortNo) // 매장별 공평하게 나머지 금액을 주기 위한 정렬.
              .forEach(visitPay => {
                let calcAmt = 0;
                // (!visitPay.isDivide)본인 금액을 분할할 대상이 아니지만 (visitPay.isSelect)분할된 금액을 나눠가질 대상인 경우.
                if (!visitPay.isDivide && visitPay.isSelect) {
                  calcAmt += visitPay[amtCode.concat("Amt")];
                }

                // 분할된 금액을 나눠가질 내장객(isSelect)인 경우.
                if (visitPay.isSelect) {
                  divideCnt += 1;

                  // 첫번째 분할 금액 적용 대상이면 (divideCnt <= firstLoop) 첫번째 금액(firstAmt) 적용, 아니면 두번째 금액(secondAmt) 적용
                  // divideCnt === (1 % visitorCount) + 1 이면 나머지 금액 포함 대상.
                  calcAmt += divideCnt <= firstLoop ?
                    (divideCnt === (1 % visitorCount) + 1 ? firstAmt + remainder : firstAmt) :
                    (divideCnt === (1 % visitorCount) + 1 ? secondAmt + remainder : secondAmt)
                  ;
                }

                if (visitPay.isSelect || visitPay.isDivide) {
                  // 분할된 금액을 나눠가질 내장객(isSelect)이거나 본인 금액을 분할할 대상(isDivide)이면 데이터 셋팅)
                  this.$refs.visitPaysGrid.updateCell(visitPay._rid - 1, amtCode.concat("Amt"), calcAmt);
                  if (visitPay.isSelect) {
                    // 분할된 금액을 나눠가질 내장객(isSelect)이면 내부 sortNo 값 셋팅)
                    visitPay.sortNo = divideCnt <= firstLoop ? visitPay.sortNo + 999 : visitPay.sortNo;
                  }
                }
              });
          });
        } else { // 단체 분할
          // 해당 매장 데이터 합산
          const totAmt = divideData
            .map(visitPay => visitPay[amtCode.concat("Amt")])
            .reduce((acc, cur) => acc + cur, 0);

          // 합산 데이터가 없는 경우 Return.
          if (!(totAmt > 0)) {
            return;
          }

          // 분할 단위
          const divideUnit = Number(this.divideUnit);
          // 기본 분할 금액
          const tempAmt = Math.floor(totAmt / visitorCount / divideUnit) * divideUnit;
          // 적용될 두번째 분할 금액(기본 분할 금액에 분할 단위를 더한 금액)
          const secondAmt = tempAmt + divideUnit;
          // 적용될 첫번째 분할 금액
          const firstAmt = Math.round(tempAmt / 10) * 10;
          // 첫번째 분할 금액을 몇명에게 적용할 것인지에 대한 데이터
          const firstLoop = Math.floor((totAmt - (secondAmt * visitorCount)) / (firstAmt - secondAmt));
          // 전체 금액에서 첫번째 분할 금액과 두번째 분할 금액을 모두 적용한 총액을 뺀 나머지 금액.
          const remainder = totAmt - ((firstAmt * firstLoop) + (secondAmt * (visitorCount - firstLoop)));

          // 분할 여부 Count.
          let divideCnt = 0;
          visitPays
            .sort((a, b) => a.sortNo - b.sortNo) // 매장별 공평하게 나머지 금액을 주기 위한 정렬.
            .forEach(visitPay => {
              let calcAmt = 0;
              // (!visitPay.isDivide)본인 금액을 분할할 대상이 아니지만 (visitPay.isSelect)분할된 금액을 나눠가질 대상인 경우.
              if (!visitPay.isDivide && visitPay.isSelect) {
                calcAmt += visitPay[amtCode.concat("Amt")];
              }

              // 분할된 금액을 나눠가질 내장객(isSelect)인 경우.
              if (visitPay.isSelect) {
                divideCnt += 1;

                // 첫번째 분할 금액 적용 대상이면 (divideCnt <= firstLoop) 첫번째 금액(firstAmt) 적용, 아니면 두번째 금액(secondAmt) 적용
                // divideCnt === (1 % visitorCount) + 1 이면 나머지 금액 포함 대상.
                calcAmt += divideCnt <= firstLoop ?
                  (divideCnt === (1%visitorCount) + 1 ? firstAmt + remainder : firstAmt) :
                  (divideCnt === (1%visitorCount) + 1 ? secondAmt + remainder : secondAmt)
                ;
              }

              if (visitPay.isSelect || visitPay.isDivide) {
                // 분할된 금액을 나눠가질 내장객(isSelect)이거나 본인 금액을 분할할 대상(isDivide)이면 데이터 셋팅)
                this.$refs.visitPaysGrid.updateCell(visitPay._rid - 1, amtCode.concat("Amt"), calcAmt);
                if (visitPay.isSelect) {
                  // 분할된 금액을 나눠가질 내장객(isSelect)이면 내부 sortNo 값 셋팅)
                  visitPay.sortNo = divideCnt <= firstLoop ? visitPay.sortNo + 999 : visitPay.sortNo;
                }
              }
            });
        }
      });

      // 내장객별 합계 금액 셋팅을 위한 데이터.
      let visitPaysByTotal = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords()));
      visitPaysByTotal.forEach((visitPay, idx) => {
        visitPay.sortNo = idx;
        // 분할된 금액을 나눠가질 대상(isSelect)이 아니고 본인 금액을 분할할 대상(isDivide)도 아니면 Return.
        if (!visitPay.isSelect && !visitPay.isDivide) {
          return;
        }

        let totAmt = 0;
        // 매장별 금액 합산
        this.totalStoreCodes.forEach(storeCode => {
          const amtCode = storeCode.comCode.toLowerCase().concat("Amt");

          totAmt += visitPay[amtCode];
        });

        this.$refs.visitPaysGrid.updateCell(idx, "totAmt", totAmt);
      });

      // 데이터 조정후 합계 및 차액 계산.
      this.totalAndDifferenceInfoSetting();
    },
    isDifferent() {
      const visitPays = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords()));

      // Check되는 항목(isSelect, isDivide) 및 Grid 자체 항목(_rid)를 제외하고 비교하기 위한 데이터 조합.
      return JSON.stringify(this.orgVisitPays.map(item => ({
        ...item,
        isSelect: true,
        isDivide: true,
        _rid: null,
        _no: null,
      }))) !== JSON.stringify(visitPays.map(item => ({
        ...item,
        isSelect: true,
        isDivide: true,
        _rid: null,
        _no: null,
      })));
    },
    async onSaveButtonClicked() {
      if (!this.isDifferent()) {
        this.errorToast("변경된 내용이 없습니다");
        return;
      }

      if (this.differenceInfo.totAmt !== 0) {
        this.errorToast("차액 합계가 0이 아닙니다");
        return;
      }

      if (!(await this.confirm("적용하시겠습니까?"))) {
        return;
      }

      let payDivides = [];

      const visitPays = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords()));

      visitPays.forEach(visitPay => {
        this.totalStoreCodes.forEach(storeCode => {
          const code = storeCode.comCode;
          const amtCode = storeCode.comCode.toLowerCase().concat("Amt");

          // 매장별 원본 데이터 취합
          const orgAmt = this.orgVisitPays
            .filter(item => item.visitId === visitPay.visitId)
            .map(item => item[amtCode])
            .reduce((acc, cur) => acc + cur, 0);
          // 매장별 변경된 데이터
          const amt = visitPay[amtCode];

          // (원본 데이터 - 변경된 데이터) * -1
          const divideAmt = (orgAmt - amt) * -1;

          if (divideAmt !== 0) {
            // 정산 분할 정보(TG_PAY_DIVIDE) 테이블에 INSERT될 데이터 조합.
            payDivides.push({
              visitId: visitPay.visitId,
              storeGroup: code,
              divideDiv: this.popupData.divideDiv,
              divideAmt: divideAmt,
              divideHist: storeCode.comName
                .concat("-부분합산(NEW)(")
                .concat(commonCodesGetComName("DIVIDE_DIV", this.popupData.divideDiv))
                .concat(")"),
            });
          }
        });
      });

      // 정산 분할 정보(TG_PAY_DIVIDE) 테이블에 데이터 INSERT.
      await GolfErpAPI.payDivide(payDivides);

      this.isSave = true;

      this.infoToast("저장되었습니다");

      // 데이터 저장후 재조회
      this.getVisitPays();
    },
    onIsEnterTheAmountDirectlyChecked() {
      // 금액직접입력 체크시 Grid 재조회되면서 수정된 값 초기화되는 부분을 위함
      this.isEnterTheAmountDirectlyChecked = true;
    },
    onVisitPaysActionBegin(args) {
      if (args?.requestType === "refresh") {
        // 금액직접입력 체크시 Grid 재조회되면서 수정된 값 초기화되는 부분을 위함
        this.visitPaysGridBatchCurrentViewRecords = JSON.parse(JSON.stringify(this.$refs.visitPaysGrid.getBatchCurrentViewRecords()));
      }
    },
    onVisitPaysActionComplete(args) {
      if (args?.requestType === "refresh") {
        // 금액직접입력 체크시 Grid 재조회되면서 수정된 값 초기화되는 부분을 위함
        if (this.isEnterTheAmountDirectlyChecked) {
          if (this.visitPaysGridBatchCurrentViewRecords.length > 0) {
            this.visitPaysGridBatchCurrentViewRecords.forEach(record => {
              let totAmt = 0;
              this.totalStoreCodes.forEach(storeCode => {
                const amtCode = storeCode.comCode.toLowerCase().concat("Amt");

                this.$refs.visitPaysGrid.updateCell(record._rid - 1, amtCode, record[amtCode]);

                totAmt += record[amtCode] || 0;
              });

              this.$refs.visitPaysGrid.updateCell(record._rid - 1, "totAmt", totAmt);
            });

            this.visitPaysGridBatchCurrentViewRecords = [];
          }

          this.totalAndDifferenceInfoSetting();

          this.isEnterTheAmountDirectlyChecked = false;
        }
      }
    },
    onFoodSalesHeaderTitleClick() {
      this.isFoodSalesVisible = !this.isFoodSalesVisible;
    },
  },
};
</script>
