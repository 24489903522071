<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="resveTimeAddPopup"
      :header="`예약 타임 추가`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="282"
      :isModal="true"
      :close="onResveTimeAddPopupClosed"
    >
      <div class="window reservationTimeAdd">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">부 구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="partDiv"
                                        name="partDiv"
                                        v-model="partDiv"
                                        :dataSource="
                                          resveTimeAddDropdownOptions.partDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          resveTimeAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                        id="resveTime"
                                        name="resveTime"
                                        v-model="resveTime"
                                        format="HH:mm"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">코스</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="courseCode"
                                        name="courseCode"
                                        v-model="courseCode"
                                        :dataSource="
                                          resveTimeAddDropdownOptions.courseCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          resveTimeAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">홀 구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="holeDiv"
                                        name="holeDiv"
                                        v-model="holeDiv"
                                        :dataSource="
                                          resveTimeAddDropdownOptions.holeDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          resveTimeAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onResveTimeAdd"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeResveTimeAddPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdxAttrb,
} from "@/utils/commonCodes";
import InputTime from "@/components/common/datetime/InputTime";
import ErpButton from "@/components/button/ErpButton.vue";
import { validateFormRefs } from "@/utils/formUtil";

export default {
  name: "resveTimeAddPopup",
  mixins: [commonMixin],
  components: {
    InputTime,
    ErpButton,
  },
  data() {
    return {
      holeDiv: null,
      courseCode: commonCodesGetCommonCode("COURSE_CODE", true)[0]?.comCode,
      resveTime: null,
      partDiv: "1",
      resveTimeAddDropdownOptions: {
        fields: { text: "comName", value: "comCode" },
        partDivOptions: commonCodesGetCommonCode("PART_DIV"),
        courseCodeOptions: commonCodesGetCommonCode("COURSE_CODE"),
        holeDivOptions: commonCodesGetCommonCode("HOLE_DIV"),
      },
      validateRefList: {
        resveTime: { required: true },
      },
    };
  },
  methods: {
    validateFormRefs,
    showResveTimeAddPopup(bsnDate, holeDiv, popupType) {
      this.holeDiv = holeDiv;

      if (popupType === "walkIn") {
        // 워크인 팝업. 9홀, 18홀만
        this.resveTimeAddDropdownOptions.holeDivOptions = this.resveTimeAddDropdownOptions.holeDivOptions.filter(
          (holeDiv) => holeDiv.comCode === "9" || holeDiv.comCode === "18"
        );
      } else if (popupType === "addHole") {
        this.resveTimeAddDropdownOptions.holeDivOptions = commonCodesGetCommonCodeByIdxAttrb(
          "HOLE_DIV",
          2,
          "ADD"
        );
      }
    },
    onResveTimeAddPopupClosed() {
      this.$emit("popupClosed");
    },
    onResveTimeAdd() {
      if (this.validate()) {
        let data = {
          partDiv: this.partDiv,
          resveTime: this.resveTime,
          resveCourse: this.courseCode,
          holeDiv: this.holeDiv,
        };
        this.$emit("popupClosed", data);
      }
    },
    closeResveTimeAddPopup() {
      this.$refs.resveTimeAddPopup.hide();
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
