<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="partialSumPopup"
      :header="`부분 합산`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="862"
      height="600"
      :isModal="true"
      :close="onPartialSumPopupClosed"
    >
      <div class="window partialSum">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <div class="article-left">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">합산 방법</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="popupData.divideDiv"
                                              value="T"
                                              @change="divideDivChanged"
                                            />
                                            <i></i>
                                            <div class="label">팀</div>
                                          </label>
                                        </li>
                                        <li>
                                          <label>
                                            <input
                                              type="radio"
                                              v-model="popupData.divideDiv"
                                              value="G"
                                              :disabled="
                                                !(popupData && popupData.grpNo)
                                              "
                                              @change="divideDivChanged"
                                            />
                                            <i></i>
                                            <div class="label">단체</div>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-02">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">합산 매장</div>
                      </div>
                    </div>
                    <div class="section-body">
                      <div class="body-data header">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul class="data-content">
                              <li class="field checkbox">
                                <div class="title">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              :checked="
                                                storeCode.length ===
                                                totalStoreCodes.length
                                              "
                                              @change="storeCodeCheckAllChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="title">매장</div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                      <div class="body-data body">
                        <div class="data-outer">
                          <div class="data-inner">
                            <ul
                              class="data-content"
                              v-for="code in totalStoreCodes"
                              :key="code.comCode"
                            >
                              <li class="field checkbox">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <!-- 포커스 : focus -->
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="storeCode"
                                              :value="code.comCode"
                                              @change="storeCodeCheckBoxChecked"
                                            />
                                            <i></i>
                                          </label>
                                        </li>
                                      </ul>
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li class="field store">
                                <div class="content">
                                  <ul class="row">
                                    <li class="item form-group text">
                                      {{ code.comName }}
                                      <div class="validation">
                                        <div class="e-tip-content"></div>
                                        <div class="e-tip-top"></div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                            </ul>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div class="article-right">
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-03">
                    <div class="section-body">
                      <ejs-tab
                        id="element"
                        :animation="divideTabAnimation"
                        :selected="divideTabSelected"
                      >
                        <div class="e-tab-header">
                          <div>전체</div>
                          <!--<div>품목별</div>-->
                        </div>
                        <div class="e-content">
                          <div>
                            <article class="body-article article-0101">
                              <!-- 아코디언 : accordion / 닫힘 : close -->
                              <section class="article-section section-0301">
                                <div class="section-body">
                                  <div class="body-data header">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul class="data-content">
                                          <li class="field team">
                                            <div class="title">팀</div>
                                          </li>
                                          <li class="field time">
                                            <div class="title">시간</div>
                                          </li>
                                          <li class="field course">
                                            <div class="title">코스</div>
                                          </li>
                                          <li class="field checkbox">
                                            <div class="title"></div>
                                          </li>
                                          <li class="field visitor">
                                            <div class="title">내장객</div>
                                          </li>
                                          <li class="field locker">
                                            <div class="title">락카</div>
                                          </li>
                                          <li class="field checkbox">
                                            <div class="title">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          :checked="
                                                            amtsCheckAllChecked(
                                                              allPartialSumInfoList
                                                            )
                                                          "
                                                          @change="
                                                            amtsCheckAllChanged(
                                                              $event,
                                                              allPartialSumInfoList
                                                            )
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field total">
                                            <div class="title">이용총액</div>
                                          </li>
                                          <li class="field partial">
                                            <div class="title">
                                              합산 예상금액
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                  <div class="body-data body">
                                    <div class="data-outer">
                                      <div class="data-inner">
                                        <ul
                                          class="data-content"
                                          v-for="(
                                            divideInfo, divideInfoIdx
                                          ) in allPartialSumInfoList"
                                          :key="divideInfo.visitId"
                                        >
                                          <li class="field team">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li
                                                      v-if="
                                                        divideInfoIdx === 0 ||
                                                        (divideInfoIdx > 0 &&
                                                          divideInfo.chkinId !==
                                                            allPartialSumInfoList[
                                                              divideInfoIdx - 1
                                                            ].chkinId)
                                                      "
                                                    >
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          :checked="
                                                            teamCheckAllChecked(
                                                              allPartialSumInfoList,
                                                              divideInfo
                                                            )
                                                          "
                                                          @change="
                                                            teamCheckAllChanged(
                                                              $event,
                                                              allPartialSumInfoList,
                                                              divideInfo
                                                            )
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field time">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                  v-if="
                                                    divideInfoIdx === 0 ||
                                                    (divideInfoIdx > 0 &&
                                                      divideInfo.chkinId !==
                                                        allPartialSumInfoList[
                                                          divideInfoIdx - 1
                                                        ].chkinId)
                                                  "
                                                >
                                                  {{ divideInfo.startTime }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field course">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                  v-if="
                                                    divideInfoIdx === 0 ||
                                                    (divideInfoIdx > 0 &&
                                                      divideInfo.chkinId !==
                                                        allPartialSumInfoList[
                                                          divideInfoIdx - 1
                                                        ].chkinId)
                                                  "
                                                >
                                                  {{
                                                    commonCodesGetComName(
                                                      "COURSE_CODE",
                                                      divideInfo.startCourse
                                                    )
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field checkbox">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          v-model="
                                                            divideInfo.visitSumFlag
                                                          "
                                                          @change="
                                                            visitSumFlagChanged(
                                                              $event,
                                                              divideInfo
                                                            )
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field visitor">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{ divideInfo.visitName }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field locker">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{ divideInfo.lockerNo }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field checkbox">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group check"
                                                >
                                                  <!-- 포커스 : focus -->
                                                  <ul class="check">
                                                    <li>
                                                      <label>
                                                        <input
                                                          type="checkbox"
                                                          v-model="
                                                            divideInfo.amtSumFlag
                                                          "
                                                          @change="
                                                            amtVisitIdCheckboxChanged
                                                          "
                                                        />
                                                        <i></i>
                                                      </label>
                                                    </li>
                                                  </ul>
                                                  <div class="validation">
                                                    <div
                                                      class="e-tip-content"
                                                    ></div>
                                                    <div
                                                      class="e-tip-top"
                                                    ></div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field total">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{
                                                    divideInfo.totAmt
                                                      | numberWithCommas
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                          <li class="field partial">
                                            <div class="content">
                                              <ul class="row">
                                                <li
                                                  class="item form-group text"
                                                >
                                                  {{
                                                    divideInfo.sumTotAmt
                                                      | numberWithCommas
                                                  }}
                                                </li>
                                              </ul>
                                            </div>
                                          </li>
                                        </ul>
                                        <div class="border-left"></div>
                                        <div class="border-right"></div>
                                        <div class="border-top"></div>
                                        <div class="border-bottom"></div>
                                      </div>
                                    </div>
                                    <div class="border-left"></div>
                                    <div class="border-right"></div>
                                    <div class="border-top"></div>
                                    <div class="border-bottom"></div>
                                  </div>
                                  <div
                                    class="body-footer"
                                    style="
                                      border: 1px solid #ccc;
                                      border-top: none;
                                    "
                                  >
                                    <ul>
                                      <li>
                                        {{
                                          totalTeamCount(allPartialSumInfoList)
                                            .totalCount
                                        }}팀
                                      </li>
                                      <li>
                                        {{ allPartialSumInfoList.length }}명
                                      </li>
                                      <li>
                                        이용총액:
                                        {{
                                          totalTotAmt(allPartialSumInfoList)
                                            | numberWithCommas
                                        }}
                                      </li>
                                      <li>
                                        합산 예상금액 :
                                        {{
                                          totalSumTotAmt(allPartialSumInfoList)
                                            | numberWithCommas
                                        }}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </section>
                            </article>
                          </div>
                        </div>
                      </ejs-tab>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-icon-custom="true"
                  @click.native="sumProcess"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePartialSumPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetComName,
  commonCodesGetCommonCode, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import { orderBy as _orderBy } from "lodash";

import {
  getPartialSumByPut,
  savePartialSum
} from "@/api/frontManagementCheckin";
import { numberWithCommas } from "@/utils/number";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "partialSumPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton
  },
  filters: {
    numberWithCommas,
  },
  computed: {
    amtsCheckAllChecked() {
      return (divideList) => {
        return (
          divideList.filter((divide) => divide.amtSumFlag).length ===
          divideList.length
        );
      };
    },
    productCodeCheckAllChecked() {
      return (divideList) => {
        const countingObject = divideList.reduce(
          (countInfo, divideInfo) => {
            divideInfo.prodInfoList.forEach((prodInfo) => {
              countInfo.totalCount += 1;
              if (prodInfo.prodDivideFlag) {
                countInfo.prodDivideFlagTrueCount += 1;
              }
            });
            return countInfo;
          },
          { totalCount: 0, prodDivideFlagTrueCount: 0 }
        );
        return (
          countingObject.totalCount === countingObject.prodDivideFlagTrueCount
        );
      };
    },
    teamCheckAllChecked() {
      return (divideList, divideInfo, isProd) => {
        const teamList = divideList.filter(
          (divide) => divide.chkinId === divideInfo.chkinId
        );
        const teamCount = teamList.length;
        const isAmtSumFlagAllClicked =
          teamCount === teamList.filter((divide) => divide.amtSumFlag).length;

        if (!isProd) {
          return isAmtSumFlagAllClicked;
        } else {
          let prodDivideFlagAllTrue = true;
          teamList.some((teamInfo) => {
            if (
              teamInfo.prodInfoList.filter((prod) => prod.prodDivideFlag)
                .length !== teamInfo.prodInfoList.length
            ) {
              prodDivideFlagAllTrue = false;
              return false;
            }
          });
          return isAmtSumFlagAllClicked && prodDivideFlagAllTrue;
        }
      };
    },
    /**
     * 팀 count 계산. chkinId 종류를 counting 한다
     */
    totalTeamCount() {
      return (divideList) => {
        return divideList.reduce(
          (accumulator, divideInfo) => {
            if (accumulator.currentChkinId !== divideInfo.chkinId) {
              accumulator.totalCount = accumulator.totalCount + 1;
              accumulator.currentChkinId = divideInfo.chkinId;
            }
            return accumulator;
          },
          { totalCount: 0, currentChkinId: null }
        );
      };
    },
    /**
     * 이용총액 합계
     */
    totalTotAmt() {
      return (divideList) => {
        return divideList.reduce((total, divideInfo) => {
          total += divideInfo.totAmt;
          return total;
        }, 0);
      };
    },
    /**
     * 분할 예상금액 합계
     */
    totalSumTotAmt() {
      return (divideList) => {
        return divideList.reduce((total, divideInfo) => {
          total += divideInfo.sumTotAmt;
          return total;
        }, 0);
      };
    },
  },
  data() {
    return {
      // 공통
      divideFlag: "ALL",
      popupData: {},
      storeCode: commonCodesGetCommonCode("STORE_GROUP").map(
        (code) => code.comCode
      ),
      totalStoreCodes: commonCodesGetCommonCode("STORE_GROUP"),

      // ALL
      allPartialSumInfoList: [], // API 조회 결과를 담아놓음

      // PROD
      prodPartialSumInfoList: [], // API 조회 결과를 담아놓음

      // 기타
      divideTabAnimation: {
        previous: { effect: "None" },
        next: { effect: "None" },
      },
      isSaved: false,
    };
  },
  methods: {
    commonCodesGetComName,
    async sumProcess() {
      // Validation
      let sumList = [];
      let chkinIdList = [];
      if (this.divideFlag === "ALL") {
        sumList = this.allPartialSumInfoList;
      } else if (this.divideFlag === "PROD") {
        sumList = this.prodPartialSumInfoList;
      }

      if (this.popupData.divideDiv === "T") {
        // 팀인 경우 팀 별 하나씩 체크 필요

        // #1. 팀 별 체크를 위해서 존재하는 chkinId 목록(= 팀 목록)을 뽑아 냄
        chkinIdList = sumList.reduce((chkinIds, sumInfo) => {
          if (!chkinIds.includes(sumInfo.chkinId)) {
            chkinIds.push(sumInfo.chkinId);
          }
          return chkinIds;
        }, []);

        // #2. 팀 내 한명씩 선택되었는지 확인 필요
        let isSelectedPerTeam = true;
        let isSelectedAmt = true;
        if (chkinIdList.length > 0) {
          chkinIdList.some((chkinId) => {
            if (
              sumList.filter(
                (sum) => sum.chkinId === chkinId && sum.visitSumFlag
              ).length !== 1
            ) {
              isSelectedPerTeam = false;
              return false;
            }
            if (
              sumList.filter((sum) => sum.chkinId === chkinId && sum.amtSumFlag)
                .length === 0
            ) {
              isSelectedAmt = false;
              return false;
            }
          });
        }
        if (!isSelectedPerTeam) {
          this.errorToast("팀 당 1명의 내장객을 선택해 주셔야 합니다");
          return;
        }
        if (!isSelectedAmt) {
          this.errorToast("팀 당 1건의 이용총액을 선택해 주셔야 합니다");
          return;
        }
      } else if (this.popupData.divideDiv === "G") {
        // 단체인 경우 하나만 체크하면 됨
        if (sumList.filter((sum) => sum.visitSumFlag).length !== 1) {
          this.errorToast("1명의 내장객을 선택해 주셔야 합니다");
          return;
        }
        if (sumList.filter((sum) => sum.amtSumFlag).length === 0) {
          this.errorToast("1건의 이용총액을 선택해 주셔야 합니다");
          return;
        }
      }

      let requestObject = {
        bsnDate: this.popupData.bsnDate,
        chkinId: this.popupData.chkinId,
        grpNo:
          this.popupData.divideDiv === "G" ? this.popupData.grpNo : undefined,
        partialSumDiv: this.popupData.divideDiv,
        partialSumStoreList: this.storeCode,
        partialSumList: [],
      };

      // Request Body 생성
      if (this.popupData.divideDiv === "T") {
        // 팀인 경우, 팀 별 partialSumList Element 하나씩
        if (chkinIdList.length > 0) {
          chkinIdList.some((chkinId) => {
            let teamList = sumList.filter((sum) => sum.chkinId === chkinId);
            let payerId = teamList.find((sum) => sum.visitSumFlag).visitId;

            let partialSumListElement = {
              partialSumDetailList: [],
              payerId: payerId,
            };

            teamList.forEach((teamInfo) => {
              if (teamInfo.amtSumFlag) {
                let partialSumDetailListElement = {
                  visitId: teamInfo.visitId,
                };

                if (this.divideFlag === "PROD") {
                  partialSumDetailListElement.slipList = [];
                  teamInfo.prodInfoList.forEach((prodInfo) => {
                    if (prodInfo.prodDivideFlag) {
                      partialSumDetailListElement.slipList.push({
                        slipId: prodInfo.slipId,
                      });
                    }
                  });
                }
                partialSumListElement.partialSumDetailList.push(
                  partialSumDetailListElement
                );
              }
            });

            requestObject.partialSumList.push(partialSumListElement);
          });
        }
      } else if (this.popupData.divideDiv === "G") {
        // 단체인 경우, partialSumList Element 하나
        let payerId = sumList.find((sum) => sum.visitSumFlag).visitId;

        let partialSumListElement = {
          partialSumDetailList: [],
          payerId: payerId,
        };

        sumList.forEach((sumInfo) => {
          if (sumInfo.amtSumFlag) {
            let partialSumDetailListElement = {
              visitId: sumInfo.visitId,
            };

            if (this.divideFlag === "PROD") {
              partialSumDetailListElement.slipList = [];
              sumInfo.prodInfoList.forEach((prodInfo) => {
                if (prodInfo.prodDivideFlag) {
                  partialSumDetailListElement.slipList.push({
                    slipId: prodInfo.slipId,
                  });
                }
              });
            }
            partialSumListElement.partialSumDetailList.push(
              partialSumDetailListElement
            );
          }
        });

        requestObject.partialSumList.push(partialSumListElement);
      }

      // 부분합산 저장 API 호출
      try {
        await savePartialSum(requestObject);
        this.infoToast(this.$t("main.popupMessage.saved"));
        this.isSaved = true;
        this.closePartialSumPopup();
      } catch (e) {
        console.error(e);
      }
    },
    closePartialSumPopup() {
      this.$refs.partialSumPopup.hide();
    },
    onPartialSumPopupClosed() {
      if (this.isSaved) {
        this.$emit("popupClosed", { isReload: true });
      } else {
        this.$emit("popupClosed");
      }
    },
    async showPartialSumPopup(popupData) {
      this.popupData = popupData;

      await this.getPartialSumApi("ALL", true);
      // await this.getPartialSumApi("PROD", true);
    },
    /**
     * 분할정보 조회 API
     * @param isFirst 첫 번째 호출 여부. 만약 첫 번째 호출인 경우, openFlag 파라미터 값 넣기
     */
    async getPartialSumApi(partialSumFlag, openFlag = false) {
      let paramData = {};

      if (partialSumFlag === "ALL") {
        paramData = {
          partialSumFlag: partialSumFlag,
          sumDiv: this.popupData.divideDiv,
          bsnDate: this.popupData.bsnDate,
          chkinId: this.popupData.chkinId,
          visitIdList: this.getVisitIdList(
            this.allPartialSumInfoList,
            "visitSumFlag"
          ),
          grpNo: this.popupData.grpNo,
          storeCode: this.storeCode,
          amtVisitIdList: this.getVisitIdList(
            this.allPartialSumInfoList,
            "amtSumFlag"
          ),
          openFlag: openFlag,
        };
      } else if (partialSumFlag === "PROD") {
        let slipIdList = [];
        this.prodPartialSumInfoList.forEach((divideInfo) => {
          divideInfo.prodInfoList.forEach((prodInfo) => {
            if (prodInfo.prodDivideFlag) {
              slipIdList.push(prodInfo.slipId);
            }
          });
        });

        paramData = {
          partialSumFlag: partialSumFlag,
          sumDiv: this.popupData.divideDiv,
          bsnDate: this.popupData.bsnDate,
          chkinId: this.popupData.chkinId,
          visitIdList: this.getVisitIdList(
            this.prodPartialSumInfoList,
            "visitSumFlag"
          ),
          grpNo: this.popupData.grpNo,
          storeCode: this.storeCode,
          amtVisitIdList: this.getVisitIdList(
            this.prodPartialSumInfoList,
            "amtSumFlag"
          ),
          slipIdList: slipIdList,
          openFlag: openFlag,
        };
      }

      if (openFlag) {
        paramData.visitIdList.push(this.popupData.visitId);
      }

      const response = await getPartialSumByPut(partialSumFlag, paramData);
      if (partialSumFlag === "ALL") {
        this.allPartialSumInfoList = _orderBy(response.value.allPartialSumInfoList.map(item => ({
          ...item,
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
        })), ["startTime", "courseCodeSortNo", "visitSno"]);
      } else if (partialSumFlag === "PROD") {
        this.prodPartialSumInfoList = response.value.prodPartialSumInfoList;
      }
    },
    /**
     * 특정 flag 값이 true 인 visitId 값을 가져온다
     * @param divideList 검색할 array list
     * @param fieldName 체크할 flag 필드 명. visitSumFlag 또는 amtSumFlag
     */
    getVisitIdList(divideList, fieldName) {
      return divideList
        .filter((divideInfo) => divideInfo[fieldName] === true)
        .map((trueDivideInfo) => trueDivideInfo.visitId);
    },
    /**
     * 분할 매장 전체 선택 체크박스 값이 바뀐 경우
     */
    async storeCodeCheckAllChecked(args) {
      if (args.target.checked) {
        this.storeCode = commonCodesGetCommonCode("STORE_GROUP").map(
          (code) => code.comCode
        );
      } else {
        this.storeCode = [];
      }
      await this.getPartialSumApi(this.divideFlag);
    },
    async storeCodeCheckBoxChecked() {
      await this.getPartialSumApi(this.divideFlag);
    },
    async divideTabSelected(args) {
      if (args.selectedIndex === 0) {
        this.divideFlag = "ALL";
        await this.getPartialSumApi(this.divideFlag);
      } else if (args.selectedIndex === 1) {
        this.divideFlag = "PROD";
        await this.getPartialSumApi(this.divideFlag);
      }
    },
    async visitIdCheckboxChanged() {
      await this.getPartialSumApi(this.divideFlag);
    },
    async visitSumFlagChanged(args, divideInfo) {
      if (args.target.checked) {
        let sumList = [];
        if (this.divideFlag === "ALL") {
          sumList = this.allPartialSumInfoList;
        } else if (this.divideFlag === "PROD") {
          sumList = this.prodPartialSumInfoList;
        }
        if (this.popupData.divideDiv === "G") {
          // 단체인 경우 : 전체 1명만 체크해야 하기 때문에, 나머지를 모두 체크 해제해야
          sumList.forEach((sum) => {
            if (sum.visitId !== divideInfo.visitId) {
              sum.visitSumFlag = false;
            }
          });
        } else if (this.popupData.divideDiv === "T") {
          // 팀인 경우 : 팀 내 1명만 체크해야 하기 때문에, 팀 내 나머지를 모두 체크 해제해야
          sumList.forEach((sum) => {
            if (
              sum.visitId !== divideInfo.visitId &&
              sum.chkinId === divideInfo.chkinId
            ) {
              sum.visitSumFlag = false;
            }
          });
        }
      }
      // api 재조회
      await this.getPartialSumApi(this.divideFlag);
    },
    async amtVisitIdCheckboxChanged(args, divideInfo) {
      if (divideInfo !== undefined) {
        divideInfo.prodInfoList.forEach((info) => {
          info.prodDivideFlag = args.target.checked;
        });
      }
      await this.getPartialSumApi(this.divideFlag);
    },
    async amtsCheckAllChanged(args, divideList) {
      divideList.forEach((divide) => {
        divide.amtSumFlag = args.target.checked;

        if (divide.prodInfoList !== undefined) {
          divide.prodInfoList.forEach((prod) => {
            prod.prodDivideFlag = args.target.checked;
          });
        }
      });
      // api 재조회
      await this.getPartialSumApi(this.divideFlag);
    },
    async teamCheckAllChanged(args, divideList, divideInfo, isProd) {
      const teamList = divideList.filter(
        (divide) => divide.chkinId === divideInfo.chkinId
      );
      teamList.forEach((team) => {
        if (args.target.checked) {
          // team의 amtSumFlag true로 설정
          team.amtSumFlag = true;
        } else {
          // team의 amtSumFlag false로 설정
          team.amtSumFlag = false;
        }
      });

      // PROD 모드인 경우 상품 checkbox도 모두 처리
      if (isProd) {
        teamList.forEach((divideInfo) => {
          divideInfo.prodInfoList.forEach((prod) => {
            if (args.target.checked) {
              prod.prodDivideFlag = true;
            } else {
              prod.prodDivideFlag = false;
            }
          });
        });
      }

      // api 재조회
      await this.getPartialSumApi(this.divideFlag);
    },
    async divideDivChanged(args) {
      // 팀 -> 단체 이동 시 내장객 checkbox 모두 초기화 & API 재 호출(ALL, PROD 둘 다)
      if (args.target.value === "G") {
        this.allPartialSumInfoList.forEach((divide) => {
          divide.visitSumFlag = false;
        });
        this.prodPartialSumInfoList.forEach((divide) => {
          divide.visitSumFlag = false;
        });
      }

      // api 재조회
      await this.getPartialSumApi(this.divideFlag);
    },
  },
};
</script>
