<template>
  <div style="padding: 4px 0">
    <input-number
      v-model="divideNumber"
      :allow-minus="false"
      :min="min"
      :defaultValue="defaultValue"
      :max="max"
      style="background-color: #fff; float: left; width: 27px"
    />
    <div style="float: left; line-height: 27px">명</div>
    <erp-button button-div="GET" @click.native="divide" class="btn" style="margin-left: 2px !important">
      분할
    </erp-button>
    <div style="clear: both; height: 0"></div>
  </div>
</template>

<style scoped>
.btn.e-btn {float: left; margin-left: 8px;}
.btn.e-btn,
.btn.e-btn:hover,
.btn.e-btn:focus {box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.06);}
.btn.e-btn {padding: 5px 8px 3px 23px; border-color: #ccc; border-radius: 3px; background: #fff url('../../assets/images/common/button-icon-default.png') no-repeat left -84px; color: #000; font-size: 12px;}
.btn.e-btn:hover,
.btn.e-btn:focus {background-color: #f0f0f0;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import inputNumber from "@/components/common/InputNumber";

export default {
  name: "payDivideTemplate",
  props: ["min", "max", "defaultValue", "divideCallback"],
  components: {
    inputNumber,
    ErpButton
  },
  data() {
    return {
      divideNumber: this.defaultValue,
      data() {
        return { data: { data: {} } };
      },
    };
  },
  methods: {
    divide() {
      this.divideCallback(this.divideNumber);
    },
  },
};
</script>
