<template>
  <div>
    <ejs-dialog
      ref="dutchPayPopup"
      header="품목별 1/N"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="popupWidth"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :close="onPopupClose"
      :isModal="true"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">품목 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-check">
                        <li>
                          <div style="padding-right: 6px;">분할구분</div>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="selectDiv"
                                name="selectDiv"
                                value="TEAM"
                            />
                            <i></i>
                            <div class="label">팀</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                                type="radio"
                                v-model="selectDiv"
                                name="selectDiv"
                                value="GRP"
                                :disabled="!popupData.grpNo"
                            />
                            <i></i>
                            <div class="label">단체</div>
                          </label>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <li>
                          <erp-button
                            button-div="GET"
                            @click.native="onReset"
                          >
                            초기화
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="height: calc(100% - 46px)">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="dutchPayGrid"
                        v-bind="gridOptions"
                        @queryCellInfo="onQueryCellInfo"
                        @gridCheckboxChanged="onGridCheckboxChanged"
                        @recordClick="onRecordClick"
                        @cellSave="onCellSave"
                        @cellEdit="onCellEdit"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onSave"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onPopupClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import {Aggregate, Edit, ForeignKey, Group, Resize, Freeze} from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {getVisitPays} from "@/api/frontManagementCheckin";
import {numberWithCommas} from "@/utils/number";
import {
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetCommonCodeByIdx,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetComName,
  commonCodesGetSortNo
} from "@/utils/commonCodes";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "DutchPayByItemPopup",
  components: {
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [messagePopupDialogMixin],
  data() {
    return {
      aggregateList: [],
      popupData: {},
      selectDiv: "TEAM",
      dataSource: [],
      visitPaysColumns: [],
    };
  },
  computed: {
    popupWidth() {
      // 팝업 Width 최소값 설정
      // 단체면 최소 2팀이 보이도록 설정
      // return this.visitPaysColumns.map(item => {
      //   return item.columns.length;
      // }).reduce((acc, cur) => acc + cur, 0) > 4 ? 1790 : 1270;

      const totalColumns = this.visitPaysColumns.reduce((acc, item) => acc + item.columns.length, 0);
      return totalColumns > 4 ? 1870 : 1350;
    },
    isGroup() {
      return this.selectDiv !== "TEAM";
    },
    gridOptions() {
      return {
        provides: [Resize, Edit, ForeignKey, Aggregate, Freeze],
        dataSource: this.dataSource,
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
        },
        columns: this.computedColumns,
        isNOColumnDisplay: false,
        aggregates: this.productAggregate,
        allowGrouping: true,
        allowFiltering: false,
      };
    },
    productAggregate() {
      return this.aggregateList.map((data) => ({
        columns: [
            {
              field: 'productName',
              aggregationType: 'TotalCaption',
              customAggregate: `${data.comName} 합계`,
            },
          {
            field: 'totAmt',
            aggregationType: 'TotalCaption',
            customAggregate: () => {
              const gridDataList = this.$refs.dutchPayGrid.getBatchCurrentViewRecords();
              return gridDataList.reduce((acc,curr) => {
                if(curr.storeCode === data.comCode) {
                  acc += curr.totAmt || 0;
                }
                return acc;
                },0);
            }
          }
        ]
      }));
    },
    computedColumns() {
      const columns = [
        {
          headerText: "매장",
          field: "storeName",
          width: 80,
          allowEditing: false,
          freeze:'Left',
        },
        {
          headerText: "지불자",
          field: "payerName",
          width: 80,
          allowEditing: false,
          freeze:'Left',
        },
        {
          headerText: "전표번호",
          field: "slipNo",
          width: 70,
          allowEditing: false,
          freeze:'Left'
        },
        {
          headerText: "품목",
          field: "productName",
          width: 150,
          allowEditing: false,
          freeze:'Left'
        },
        {
          headerText: "매출 정보",
          freeze:'Left',
          columns: [
            {
              headerText: "수량",
              field: "saleQty",
              width: 50,
              textAlign: "center",
              allowEditing: false,
              freeze:'Left',
              valueAccessor: (_, { saleQty }) => {
                return numberWithCommas(saleQty);
              },
            },
            {
              headerText: "단가",
              field: "salePrice",
              width: 80,
              textAlign: "right",
              allowEditing: false,
              freeze:'Left',
              valueAccessor: (_, { salePrice }) => {
                return numberWithCommas(salePrice);
              },
            },
            {
              headerText: "금액",
              field: "totAmt",
              width: 80,
              textAlign: "right",
              allowEditing: false,
              freeze:'Left',
              valueAccessor: (_, { totAmt }) => {
                return numberWithCommas(totAmt);
              },
            }
          ]
        },
        {
          headerText: "분할",
          freeze:'Left',
          columns: [
            {
              freeze:'Left',
              headerText: "수량",
              field: "splitQty",
              width: 50,
              textAlign: "center",
              allowEditing: false,
              valueAccessor: (field, data) => {
                return Object.entries(data).reduce((acc, cur) => {
                  const [key, value] = cur;
                  if (key.endsWith("-qty")) {
                    acc += value;
                  }
                  return acc;
                }, 0);
              },
            },
            {
              freeze:'Left',
              headerText: "금액",
              field: "splitPrice",
              width: 80,
              textAlign: "right",
              allowEditing: false,
              valueAccessor: (_, data) => {
                const sumSplitPrice = Object.entries(data).reduce((acc, cur) => {
                  const [key, value] = cur;
                  if (key.endsWith("-price")) {
                    acc += value;
                  }
                  return acc;
                }, 0);

                return numberWithCommas(`${sumSplitPrice}`);
              },
            },
            {
              freeze:'Left',
              headerText: "1/N",
              field: "isSplitAmount",
              minWidth: 16,
              width: 50,
              textAlign: "center",
              type: "boolean",
              displayAsCheckBox: true,
              editType: "booleanedit",
            }
          ]
        },
      ];
      columns.push(...this.visitPaysColumns);
      return columns;
    },
  },
  methods: {
    showPopup(args) {
      this.popupData = JSON.parse(JSON.stringify(args));
      if (args.grpNo) {
        this.selectDiv = "GRP";
      }
      this.getVisitPays(args);
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
    onReset() {
      // 초기화 버튼 클릭
      this.getVisitPays({
        chkinId: this.popupData.chkinId,
        grpNo: this.popupData.grpNo,
      });
    },
    async getVisitPays(args) {
      try {
        const {
          value: {
            storeSales,
            visitPays
          }
        } = await getVisitPays(args);

        const foodComCodes = commonCodesGetCommonCodeByIdxAttrb("STORE_CODE", 2, "FOOD")
            .map(({comCode}) => comCode);
        let beforeChangeStore = null;
        const foodData = orderBy(storeSales.filter((item) => foodComCodes.includes(item.storeCode)).map((data) => {
          return {
            sortNo: commonCodesGetSortNo("STORE_CODE", data.storeCode),
            ...data,
          };
        }),['sortNo'],['asc']).map((item) => {
          //line 옵션을 sort orderby map 과 같이 돌려버리면 이상한 곳에 line 이 그려짐
          const lineFlag = (beforeChangeStore && beforeChangeStore !== item.storeCode);
          beforeChangeStore = JSON.parse(JSON.stringify(item.storeCode));
          return {
            ...item,
            lineFlag,
          };
        });
        const orderedVisitPays = orderBy(visitPays.map(item => {
          return {
            ...item,
            sortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
          };
        }), ['startTime', 'sortNo'], ['asc', 'asc']);
        this.dataSource = this._joinArray(foodData, orderedVisitPays);

        this.aggregateList = commonCodesGetCommonCodeByIdx('STORE_CODE ',2)
            .filter(({comCodeAttrbList:[{attrb}]}) => attrb === 'FOOD' || attrb === 'STAR')
            .filter(({comCode}) => this.dataSource.map((m) => m.storeCode).includes(comCode));

        const columnDataOrigin = this._groupByCourseAndTime(orderedVisitPays, this.isGroup);
        this.visitPaysColumns = this._createColumnData(columnDataOrigin);
      } catch (e) {
        console.error(e);
      }
    },
    _joinArray(foodData, visitPays) {
      const visitPayersAddedPrice = visitPays.reduce((acc, cur) => {
        acc[`${cur.startTime}-${cur.visitId}-price`] = 0;
        acc[`${cur.startTime}-${cur.visitId}-price-origin`] = 0;
        acc[`${cur.startTime}-${cur.visitId}-qty`] = 0;
        acc[`${cur.startTime}-${cur.visitId}-chkinId`] = cur.chkinId;
        return acc;
      }, {});

      return foodData.map((item) => {
        return {
          ...visitPayersAddedPrice,
          ...item,
          [`${item.startTime}-${item.payerId}-price`]: item.salePrice * item.saleQty,
          [`${item.startTime}-${item.payerId}-price-origin`]: item.salePrice * item.saleQty,
          [`${item.startTime}-${item.payerId}-qty`]: item.saleQty,
          isSplitAmount: false,
          priceSelectCount: 0,
          chkinId: visitPays.find(visit => visit.visitId === item.payerId).chkinId,
        };
      });
    },
    /**
     * v2/front/pay-divide 호출후 팝업닫기
     *   [
     *     {
     *       visitId: 0, // 내장객ID
     *       storeGroup: "", // 매장그룹(공통코드 STORE_CODE 속성 1의 ATTRB 사용)
     *       divideDiv: "", // 분할구분(팀 = T, 단체 = G)
     *       divideAmt: "", // 분할금액(변경 금액 - 기존 금액)
     *       divideHist: "" // 분할이력(매장명-품목별1/N(팀 또는 단체)
     *     }
     *   ]
     */
    async onSave() {
      try {
        // 분배금액 합계가 총금액과 일치하는지 확인
        for (const item of this.dataSource) {
          const priceSum = this._getColumnSum(item, "-price");
          
          if(item.totAmt !== priceSum) {
            const currentViewRecords = this.$refs.dutchPayGrid.getBatchCurrentViewRecords();
            const rowIndex = currentViewRecords.findIndex(data => data.slipId === item.slipId);
            this.$refs.dutchPayGrid.editCell(rowIndex, `${item.startTime}-${item.payerId}-qty`);
            this.errorToast(`전표번호 ${item.slipNo}-${item.productName}의 분배 금액이 일치하지 않습니다.`);
            return;
          }
        }

        const postData = this._getPostData(this.dataSource, this.isGroup);
        const isDivided = postData.some(({ divideAmt }) => divideAmt < 0);
        if(!isDivided) {
          this.errorToast("분배 금액이 변경되지 않았습니다.");
          return;
        }

        await GolfErpAPI.patchDutchPayByItem(postData);
        this.infoToast("저장되었습니다.");
        this.onPopupClose();
      } catch (e) {
        this.errorToast("저장시 오류가 발생하였습니다.");
      }
    },
    onQueryCellInfo(args) {
      const { cell, column: { field },data: { lineFlag, dcAmt, }} = args;

      if ((dcAmt || 0) !== 0) {
        cell.style.backgroundColor = "#e0e0e0";
      }

      if(lineFlag) {
        cell.style.borderTop = "2px solid red";
      }
      if (field === "isSplitAmount") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      field.endsWith("-price") && cell.innerText
        ? cell.classList.add('hasValue')
        : cell.classList.remove('hasValue');
    },
    _getPostData(dataSource, isGroup) {
      return dataSource.reduce((acc, cur) => {
        const priceInfoListPerRow = this._getPriceInfoListPerRow(cur, isGroup);
        const divideDiv =
          Object.keys(groupBy(priceInfoListPerRow.filter(item => item.divideAmt !== 0), "chkinId")).length > 1
            ? "G"
            : "T";
        priceInfoListPerRow.map(item => {
          item.divideDiv = divideDiv;
        });
        acc.push(...priceInfoListPerRow);
        return acc;
      }, []);
    },
    _getPriceInfoListPerRow(row, isGroup) {
      return Object.keys(row)
        .filter(rowKey => rowKey.endsWith('-price'))
        .map(rowKey => {
          const originKey = `${rowKey}-origin`;
          const visitId = rowKey.split('-')[1];
          const chkinId = row[rowKey.split("-")[0] + "-" + rowKey.split("-")[1] + "-chkinId"];
          const storeGroup = commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", row.storeCode, 1);
          const divideDiv = isGroup ? 'G' : 'T';
          const divideAmt = row[rowKey] - row[originKey];
          const divideType = isGroup ? '단체' : '팀';
          const divideHist = `${row.storeName}-${row.productName}1/N(${divideType})`;
          const slipId = row.slipId;

          return { visitId, storeGroup, divideDiv, divideAmt, divideHist, chkinId, slipId };
        });
    },
    _getColumnSum(data, columnWord) {
      return Object.keys(data).reduce((acc, cur) => {
        if (cur.endsWith(columnWord)) {
          acc += Number(data[cur]);
        }
        return acc;
      }, 0);
    },
    _createColumnData(dataObject) {
      const columns = [];
      for (const key in dataObject) {
        if (key in dataObject) {
          const subColumns = dataObject[key].map(({ startTime, visitName, visitId })=> ({
            headerText: visitName,
            width: 80,
            columns: [
              {
                headerText: "금액",
                field: `${startTime}-${visitId}-price`,
                width: 80,
                textAlign: "right",
                allowEditing: false,
                valueAccessor: (field, data) => {
                  return data[field] ? numberWithCommas(data[field]) : "";
                },
              },
              {
                headerText: "수량",
                field: `${startTime}-${visitId}-qty`,
                width: 50,
                textAlign: "center",
                allowEditing: true,
                valueAccessor: (field, data) => {
                  return data[field] ? data[field] : "";
                },
              }
            ]
          }));

          columns.push({
            headerText: key,
            columns: subColumns
          });
        }
      }
      return columns;
    },
    /**
     * 코스+시간으로 컬럼 그룹핑
     * 하위 컬럼으로 방문자표시
     * 각 방문자의 하위컬럼으로 금액, 수량
     */
    _groupByCourseAndTime(data, isGroup) {
      let groupIndex = 0;
      let nowStartTime = null;
      return data.reduce((acc, cur) => {
        let teamTitle = `${commonCodesGetComName("COURSE_CODE", cur.startCourse)} ${cur.startTime}`;
        if (isGroup) {
          groupIndex = nowStartTime !== cur.startTime ? groupIndex + 1 : groupIndex;
          nowStartTime = cur.startTime;
          teamTitle = `${groupIndex}조 ${teamTitle}`;
        }

        if (!acc[teamTitle]) {
          acc[teamTitle] = [];
        }

        const hasVisitName = acc[teamTitle]
            .map((item) => item.visitName)
            .includes(cur.visitName);

        if (!hasVisitName) {
          acc[teamTitle].push({
            visitName: cur.visitName,
            visitId: cur.visitId,
            startTime: cur.startTime,
          });
        }

        return acc;
      }, {});
    },
    _getPersonCount(obj, columnWord) {
      return Object.keys(obj).map(key => {
        const rtn = {
          count: 0,
          isDivide: false,
        };
        if (key.endsWith(columnWord)) {
          rtn.isDivide = (!this.isGroup ? obj[key] === obj.chkinId : true);
          rtn.count = rtn.isDivide ? 1 : 0;

          return rtn;
        }
      }).filter(item => item);
    },
    _getDivideAmount(total, personCountArr) {
      const personCount =
        typeof personCountArr === "number"
          ? personCountArr
          : personCountArr.reduce((acc, cur) => acc + cur.count, 0);
      const baseAmount = Math.floor(total / personCount / 100) * 100;
      let extraAmount = total - (baseAmount * personCount);
      const amounts =
        typeof personCountArr === "number"
        ? Array(personCount).fill(baseAmount)
        : Array(personCountArr.length).fill(baseAmount).map((item, idx) => item = (personCountArr[idx].isDivide ? item : 0));
      let index = 0;

      while (extraAmount > 0 && index < (typeof personCountArr === "number" ? personCountArr : personCountArr.length)) {
        if (amounts[index] > 0) {
          amounts[index] += 100;
          extraAmount -= 100;
        }
        index++;
      }

      return amounts;
    },
    _getRowData(originData, productCode, slipId) {
      const [rowData] = originData.filter((item) => {
        return item.productCode === productCode && item.slipId === slipId;
      });
      return rowData;
    },
    onGridCheckboxChanged(args) {
      const { value: isSplit, rowData } = args;
      if ((rowData.dcAmt || 0) !== 0) {
        return;
      }
      // 현재 선택한 row와 동일한 dataSource의 값 가져오기 - aggregate때문에 rowIndex가 달라지기때문에 이 방법 사용
      const dataSourceRowData = this._getRowData(this.dataSource, rowData.productCode, rowData.slipId);
      const personCountObj = this._getPersonCount(dataSourceRowData, "-chkinId");
      const distributeAmount = this._getDivideAmount(dataSourceRowData.totAmt, personCountObj);

      let index = 0;
      for (const rowDataKey in dataSourceRowData) {
        const isPriceColumn = rowDataKey.endsWith("-price");
        const qtyKey = rowDataKey.replace("price", "qty");

        // 1/N 금액분할
        if(isSplit && isPriceColumn) {
          dataSourceRowData[rowDataKey] = distributeAmount[index];
          dataSourceRowData[qtyKey] = 0;
          dataSourceRowData.isSplitAmount = true;
          index++;
        }
        // 1/N 원복
        if(!isSplit && isPriceColumn) {
          const isMatchingPayer = rowDataKey.includes(rowData.payerId);
          dataSourceRowData[rowDataKey] = isMatchingPayer ? rowData.totAmt : 0;
          dataSourceRowData[qtyKey] = isMatchingPayer ? rowData.saleQty : 0;
          dataSourceRowData.isSplitAmount = false;
        }
      }
    },
    onRecordClick(args){
      const {rowData, column: { field: clickField } } = args;
      if(!clickField.endsWith('-price')) {
        return false;
      }

      if ((rowData.dcAmt || 0) !== 0) {
        this.errorToast("할인된 품목은 1/N 하실 수 없습니다");
        return;
      }

      let priceArray = this._getPriceFields(rowData);
      const dataSourceRowData = this._getRowData(this.dataSource, rowData.productCode, rowData.slipId);
      const isSelfClick = priceArray.includes(clickField);
      const isPriceClickField = !!dataSourceRowData[clickField];

      const distributeCount = this._getDivideCount(priceArray, isSelfClick, isPriceClickField);
      const amounts  = this._getDivideAmount(rowData.totAmt, distributeCount);

      this._updateDataSourceRowData(dataSourceRowData, priceArray, amounts, clickField, isSelfClick, isPriceClickField);
    },
    _updateDataSourceRowData(dataSourceRowData, priceArray, amounts, clickField, isSelfClick, isPriceClickField) {
      if (isSelfClick && isPriceClickField) {
        dataSourceRowData[clickField] = 0;
        priceArray = priceArray.filter(priceField => priceField !== clickField);
      }

      priceArray.forEach((priceField, idx) => {
        dataSourceRowData[priceField] = amounts[idx];
      });

      if (!isSelfClick) {
        dataSourceRowData[clickField] = amounts[priceArray.length];
      }
    },
    _getPriceFields(rowData) {
      return Object.keys(rowData).filter(key => key.endsWith('-price') && rowData[key]);
    },
    _getDivideCount(priceArray, isSelfClick, isPriceClickField) {
      return priceArray.length + (isSelfClick ? (isPriceClickField ? -1 : 0) : 1);
    },
    onCellSave(args) {
      const { rowData, previousValue, value: inputValue, columnName } = args;
      if(inputValue !== 0 && !inputValue) { return; }
      // 값이 변경되지 않았으면 return
      if(previousValue === inputValue) {
        return false;
      }

      const dataSourceRowData = this._getRowData(this.dataSource, rowData.productCode, rowData.slipId);
      dataSourceRowData[columnName] = Number(inputValue);

      // 수량이 총수량보다 크면 return
      const qtySum = Object.keys(dataSourceRowData).reduce((acc, cur) => {
        if(cur.endsWith('-qty')) {
          acc += Number(dataSourceRowData[cur]);
        }
        return acc;
      }, 0);
      if(qtySum > rowData.saleQty) {
        dataSourceRowData[columnName] = previousValue;
        this.errorToast("분배 수량이 큽니다.");
        return false;
      }

      // 변경된 수량으로 금액 재분배
      const priceColumn = columnName.replace('qty', 'price');
      dataSourceRowData[priceColumn] = inputValue * rowData.salePrice;
    },
    onCellEdit(args) {
      const { columnName, rowData, } = args;

      if ((rowData.dcAmt || 0) !== 0) {
        if (
          columnName === "isSplitAmount" ||
          columnName.endsWith("-price") ||
          columnName.endsWith("-qty")
        ) {
          this.errorToast("할인된 품목은 1/N 하실 수 없습니다");
          return args.cancel = true;
        }
      }
    },
  },
};
</script>

<style scoped>
body .window .windowContent .article-section .section-body {overflow: visible; border: none;}

input[type="radio"]:disabled + i {
  background-color: #adb5bd;
  color: #adb5bd;
}

input[type="radio"]:disabled ~ .label {
  color: #adb5bd;
  cursor: not-allowed;
}
</style>