<template>
  <div>
    <ejs-dialog
      ref="changePayerPopup"
      :header="`내장팀 이동`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :animationSettings="animationSettings"
      :close="onPopupClosed"
      width="900"
      height="500"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">예약자</div>
                    <div class="content">
                        <li class="item form-group" >
                          <div class="form" >
                            <input-text  v-model="searchFilterOptions.resveName" :readonly="false"/>
                          </div>
                        </li>
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">코스</div>
                    <div class="content">
                        <li class="item form-group" >
                          <ejs-dropdownlist
                              v-model="searchFilterOptions.courseCode"
                              :dataSource="commonCodeOptions.courseCode"
                              :fields="commonCodeOptions.fields"
                              :allowFiltering="false"
                              cssClass="lookup-condition-dropdown"
                          >
                          </ejs-dropdownlist>
                        </li>
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">부구분</div>
                    <div class="content">
                        <li class="item form-group" >
                          <ejs-dropdownlist
                              v-model="searchFilterOptions.partDiv"
                              :dataSource="commonCodeOptions.partDiv"
                              :fields="commonCodeOptions.fields"
                              :allowFiltering="false"
                              cssClass="lookup-condition-dropdown"
                          >
                          </ejs-dropdownlist>
                        </li>
                    </div>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button button-div="GET" :is-shortcut-button="true" @click.native="onPopupSearch">조회</erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">{{gridField.title}}</div>
                      <div class="header-caption">
                        [ {{gridField.count }} 건 ]
                      </div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none; overflow: hidden">
                    <div class="body-grid">
                      <template>
                        <ejs-grid-wrapper
                          ref="grid"
                          v-bind="initGrid"
                          @actionComplete="gridComplete"
                          @onGridDialogDoubleClickedOrEnterKeyed="onPopupConfirm"
                        />
                      </template>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="SAVE" :is-icon-custom="true" @click.native="onPopupConfirm">확인</erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onPopupClosed">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {getMoveTeam } from "@/api/frontManagement";
import {commonCodesGetCommonCode, commonCodesGetComName,} from "@/utils/commonCodes";
import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {mapGetters} from "vuex";

export default {
  name: "MoveTeamPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton
  },
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      animationSettings: { effect: "None" },
      //조회필드
      searchFilterOptions: {
        bsnDate: null,
        chkinId: null,
        grpNo:null,

        resveName: "",
        partDiv: "",
        courseCode: "",
      },
      //드롭다운 코드
      commonCodeOptions: {
        courseCode: commonCodesGetCommonCode("COURSE_CODE", false, true, "", "전체"),
        partDiv: commonCodesGetCommonCode("PART_DIV", false, true, "", "전체"),
        fields: {text: "comName", value: "comCode"},
      },
      //그리드 필드
      gridField : {
        title: "타임목록",
        dataSource: [],
        count: 0,
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    ...mapGetters(["userIp","userId","username"]),
    initGrid() {
      return {
        columns: [
          {field: "partDiv",        textAlign: 'center',  allowEditing: false, width: 50, visible: true, type: "string", headerText: "부", isCommonCodeField: true, groupCode: "PART_DIV"},
          {field: "resveTime",      textAlign: 'center',  allowEditing: false, width: 80, visible: true, type: "string", headerText: "시간", },
          {field: "resveCourse",    textAlign: 'center',  allowEditing: false, width: 80, visible: true, type: "string", headerText: "코스", isCommonCodeField: true, groupCode: "COURSE_CODE"},
          {field: "holeDiv",        textAlign: 'center',  allowEditing: false, width: 50, visible: true, type: "string", headerText: "홀",},
          {field: "resveName",      textAlign: 'left',    allowEditing: false, width: 120, visible: true, type: "string", headerText: "예약자",},
          {field: "grpName",        textAlign: 'left',    allowEditing: false, width: 120, visible: true, type: "string", headerText: "단체명",},
          /*{field: "visitCnt",       textAlign: 'center',  allowEditing: false, width: 100, visible: true, type: "string", headerText: "인원",},*/
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: false,
        isShowProgress: true,
        isNOColumnDisplay: true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        isInPopup:true, //더블클릭 이벤트 필수
        allowPaging: false,
        pageSettings: {pageSize: 50},
        resizeSettings: {mode: "Normal"},
      };
    },
  },
  /**
   * ##################################### 화면 시작시 DATA  ########################################
   */
  created() {
  },
  async mounted() {
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //초기파라미터
    initShow(popupData) {
      this.searchFilterOptions.chkinId = popupData.chkinId;
      this.searchFilterOptions.bsnDate = popupData.bsnDate;
      this.searchFilterOptions.grpNo   = popupData.grpNo;

      this.onPopupSearch();
    },
    //조회클릭
    async onPopupSearch(){
      this.gridField.dataSource= (await getMoveTeam(this.searchFilterOptions)).value;
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    //팝업닫기
    onPopupClosed() {
      this.$emit("popupClosed");
    },
    //확인버튼 클릭
    async onPopupConfirm() {
      const selectedRowData = this.$refs.grid.getSelectedRecords();
      if (selectedRowData.length === 0) {
        this.errorToast("이동할 타임을 선택해 주세요");
        return;
      }
      const timeTxt = selectedRowData[0].resveTime;
      const partTxt = commonCodesGetComName("PART_DIV",selectedRowData[0].partDiv);
      const cosTxt  = commonCodesGetComName("COURSE_CODE",selectedRowData[0].resveCourse);

      if (await this.confirm(`[${partTxt},${cosTxt}] ${timeTxt} 예약타임으로 내장팀을 이동하시겠습니까? `)) {
        this.$emit("popupConfirmed", this.searchFilterOptions.chkinId, selectedRowData[0].resveId );
      }
    },
  },
};
</script>

<style scoped></style>
