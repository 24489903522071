<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="holeAddPopup"
      :header="`홀 추가`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="300"
      :isModal="true"
      :close="onHoleAddPopupClosed"
    >
      <div class="window holeAdd">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-box">
                      <ul>
                        <li>시간 : {{ resveTime }}</li>
                        <li>
                          코스 :
                          {{
                            commonCodesGetComName("COURSE_CODE", resveCourse)
                          }}
                        </li>
                      </ul>
                      <ul>
                        <li>예약자명 : {{ resveName }}</li>
                      </ul>
                      <ul>
                        <li>단체명 : {{ resveGrpName }}</li>
                      </ul>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field groupName">
                              <!-- 필수 : required -->
                              <div class="title required">단체명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="grpName"
                                        id="grpName"
                                        name="grpName"
                                        v-model="grpName"
                                        @change="onGrpNameChanged"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          ref="grpSearchButton"
                                          @click.native="onGroupPopupOpen(true)"
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ grpNo }}
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">부 구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="partDiv"
                                          id="partDiv"
                                          name="partDiv"
                                          v-model="partDiv"
                                          :dataSource="
                                          resveTimeAddDropdownOptions.partDivOptions
                                        "
                                          :allowFiltering="false"
                                          :fields="
                                          resveTimeAddDropdownOptions.fields
                                        "
                                          cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-time
                                          ref="resveTime"
                                          id="resveTime"
                                          name="resveTime"
                                          v-model="resveTime"
                                          format="HH:mm"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">코스</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                          ref="courseCode"
                                          id="courseCode"
                                          name="courseCode"
                                          v-model="courseCode"
                                          :dataSource="
                                          resveTimeAddDropdownOptions.courseCodeOptions
                                        "
                                          :allowFiltering="false"
                                          :fields="
                                          resveTimeAddDropdownOptions.fields
                                        "
                                          cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">홀 구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="holeDiv"
                                        id="holeDiv"
                                        name="holeDiv"
                                        v-model="holeDiv"
                                        :dataSource="
                                          holeAddDropdownOptions.holeDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="holeAddDropdownOptions.fields"
                                        cssClass="body-data-dropdown"
                                        @change="onHoleAddHoleDivChange"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                  @click.native="onHoleAdd"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeHoleAddPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
  </div>
</template>

<style scoped>
body .body-data .data-content .title {width: 80px;}
body .body-data .data-content .content {width: calc((100% - 80px) + 1px);}
body .window.holeAdd .windowContent .article-section.section-02 .body-data .field.groupName .content .item.text {width: 50px;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetComName,
  commonCodesGetCommonCodeByIdxAttrb, commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import groupPopup from "../../golf-reservation/popup/GroupPopup";
import { numberWithCommas } from "@/utils/number";
import { getGroupList } from "@/api/group";
import InputText from "@/components/common/text/InputText";
import InputTime from "@/components/common/datetime/InputTime";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { validateFormRefs } from "@/utils/formUtil";
import {getDeviceInfo} from "@/utils/device";

export default {
  name: "holeAddPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
    InputText,
    InputTime,
    groupPopup,
  },
  data() {
    return {
      isGroupPopupOpen: false,
      resveTime: null,
      resveCourse: null,
      resveName: null,
      resveGrpName: null,
      bsnDate: null,
      grpName: null,
      grpNo: null,
      grpKind: null,
      holeDiv: "9+",
      courseCode: commonCodesGetCommonCode("COURSE_CODE", true)[0]?.comCode,
      partDiv: "1",
      resveTimeAddDropdownOptions: {
        fields: { text: "comName", value: "comCode" },
        partDivOptions: commonCodesGetCommonCode("PART_DIV"),
        courseCodeOptions: commonCodesGetCommonCode("COURSE_CODE"),
        holeDivOptions: commonCodesGetCommonCode("HOLE_DIV"),
      },
      chkinId: null,
      resveId: null,
      contactTel: null,
      optFlag: null,
      validateRefList: {
        grpName: { required: true },
        partDiv: { required: true },
        resveTime: { required: true },
        courseCode: { required: true },
        holeDiv: { required: true },
      },
      holeAddDropdownOptions: {
        fields: { text: "comName", value: "comCode" },
        holeDivOptions: commonCodesGetCommonCodeByIdxAttrb(
          "HOLE_DIV",
          2,
          "ADD"
        ),
      }
    };
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    commonCodesGetComName,
    showHoleAddPopup(bsnDate, selectedRow) {
      this.bsnDate = bsnDate;

      this.resveTime = selectedRow.startTime;
      this.resveCourse = selectedRow.startCourse;
      this.resveName = selectedRow.resveName;

      if (selectedRow.grpNo !== undefined) {
        this.resveGrpName = selectedRow.grpName;
        this.grpNo = selectedRow.grpNo;
        this.grpKind = selectedRow.grpKind;
        this.grpName = selectedRow.grpName;
      }

      this.chkinId = selectedRow.chkinId;
      this.resveId = selectedRow.resveId;
      this.contactTel = selectedRow.contactTel;
      this.optFlag = selectedRow.optFlag;
    },
    onHoleAddPopupClosed() {
      this.$emit("popupClosed");
    },
    async onHoleAdd() {
      if (this.validate()) {
        await this.holeAddApi();
      }
    },
    onHoleAddHoleDivChange() {
      console.log("onHoleAddHoleDivChange.===>", this.holeDiv);
    },
    closeHoleAddPopup() {
      this.$refs.holeAddPopup.hide();
    },
    async onGrpNameChanged(args) {
      if (args.event && !!args.value) {
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        const response = await getGroupList({ searchValue: args.value });
        const groupList = response.value.groupInfoList;
        if (groupList && groupList.length > 1) {
          this.onGroupPopupOpen();
        } else if (groupList && groupList.length === 1) {
          // 그룹정보 설정
          this.setGrpInfos(groupList[0]);
        } else {
          this.onGroupPopupOpen(false, "TEMP");
        }
      } else {
        this.clearGrpInfo();
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.grpName
        ? (groupPopupData.grpName = this.grpName)
        : null;
      groupPopupData.resveDates = [this.bsnDate];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.grpName;
      }

      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }
    },
    setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.grpNo = grpInfo.grpNo;
      this.grpKind = grpInfo.grpKind;
      this.grpName = grpInfo.grpName;
    },
    clearGrpInfo() {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.grpNo = null;
      this.grpKind = null;
      this.grpName = null;
    },
    async holeAddApi() {
      const reservationTime = {};
      reservationTime.partDivision = this.partDiv;
      reservationTime.reservationTime = this.resveTime || "00:00";
      reservationTime.reservationCourse = this.courseCode;
      reservationTime.holeDivision = this.holeDiv;

      const paymtCountData = await GolfErpAPI.fetchPaymtCodeCountByChkinId({
        visitDate: this.bsnDate,
        chkinId: this.chkinId
      });

      if (paymtCountData.totalCount > 0) {
        this.infoToast(
          paymtCountData.contents + "<br>" +
          "이미 지역민 할인 적용 되셨습니다."
        );
      }

      await GolfErpAPI.postAdditionalHole(
        this.grpNo,
        this.chkinId,
        this.holeDiv,
        this.bsnDate,
        reservationTime,
        getDeviceInfo().posNo || null,
        this.grpKind,
        this.grpName
      );
      this.infoToast(this.$t("main.popupMessage.saved"));
      this.$emit("popupClosed", { isReload: true });
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  }
};
</script>
