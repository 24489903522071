<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">영업일자</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref="visitSearchDatePicker"
                    v-model="bsnDateValueModel"
                    type="lookup-condition"
                    :notClear="true"
                    :isGetDateInfo="true"
                    @change="onBsnDateChange"
                />
              </li>
              <li class="item text">
                <!-- TODO : getDayOfWeekCaptionColor의 첫번째 arg인 date값이 null이면 bsnCode와 dwCode로 색상 가져오도록 해놓았음 -->
                (<span
                  :style="{
                    color: getDayOfWeekCaptionColor(
                      null,
                      bsnDateInfo.bsnCode,
                      bsnDateInfo.dwCode
                    ),
                  }"
              >{{ dayOfWeekCaption }}</span
              >) (<span
                  :style="{
                    color: commonCodesGetColorValue(
                      'BSN_CODE',
                      bsnDateInfo.bsnCode
                    ),
                  }"
              >{{
                  commonCodesGetComName("BSN_CODE", bsnDateInfo.bsnCode)
                }}</span
              >)
              </li>
            </ul>
          </li>
          <li class="field course">
            <div class="title">코스</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    ref="visitSearchCourseCode"
                    v-bind="courseCodeDropdownListProps"
                    v-model="courseCode"
                    @change="onVisitSearchCourseCodeChange"
                />
              </li>
            </ul>
          </li>
          <li class="field partDivision">
            <div class="title">부구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    cssClass="lookup-condition-dropdown"
                    ref="visitSearchPartDiv"
                    v-bind="partDivDropdownListProps"
                    v-model="partDiv"
                    @change="onVisitSearchPartDivChange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="onSearchBtnClicked"
              :ignore="isPopupOpened"
          >
            조회
          </erp-button>
        </div>
        <ul class="lookup-condition sub">
          <li class="field reservationName">
            <div class="title">예약자/내장객/캐디명</div>
            <ul class="content">
              <li class="item input">
                <input-text
                    ref="input-visit-name"
                    id="visitFindName"
                    v-model="visitFindName"
                    @click="visitFindAllClear"
                    @keydown.native.enter="onVisitFindNameEntered"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        :is-icon-custom="true"
                        @click.native="openCheckinVisitsFindPopup"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field lockerNumber">
            <div class="title">락카번호</div>
            <ul class="content">
              <li class="item">
                <input-text
                    v-model="visitFindLockerNo"
                    @focus="visitFindAllClear"
                    @keydown.native.enter="onVisitFindLockerNoEntered"
                />
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="lookup-right">
        <ul class="lookup-button">
          <li class="etc">
            <ejs-dropdownbutton
                ref="etcMenuDropdownButton"
                cssClass="lookup-button-dropdown"
                :items="etcMenuItems"
                @select="onETCShortcutMenuSelected"
            >
              기타
            </ejs-dropdownbutton>
          </li>
          <li class="shortcut">
            <ejs-dropdownbutton
                ref="shortcutMenuDropdownButton"
                cssClass="lookup-button-dropdown"
                :items="shortcutMenuItems"
                @select="onShortcutMenuSelected"
            >
              바로가기
            </ejs-dropdownbutton>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body" :class="bodyClassName">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">타임 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
              <ul class="header-label">
                <li class="field">
                  <div class="title">타임상태</div>
                  <ul class="content">
                    <li class="item">
                      <label>
                        <input
                            type="checkbox"
                            v-model="gridFilterCheck.rainCancelFlag"
                            @change="onGridFilterCheckChange"
                        />
                        <i
                            :style="
                            gridFilterCheck.rainCancelFlag === true
                              ? timeStatusRainLabelStyle
                              : ''
                          "
                        ></i>
                        <div class="label">우천취소</div>
                      </label>
                    </li>
                    <li class="item">
                      <label>
                        <input
                            type="checkbox"
                            v-model="gridFilterCheck.resveFlag"
                            @change="onGridFilterCheckChange"
                        />
                        <i
                            :style="
                            gridFilterCheck.resveFlag === true
                              ? timeStatusReservedLabelStyle
                              : ''
                          "
                        ></i>
                        <div class="label">예약</div>
                      </label>
                    </li>
                    <li class="item">
                      <label>
                        <input
                            type="checkbox"
                            v-model="gridFilterCheck.checkinFlag"
                            @change="onGridFilterCheckChange"
                        />
                        <i
                            :style="
                            gridFilterCheck.checkinFlag === true
                              ? timeStatusCheckInLabelStyle
                              : ''
                          "
                        ></i>
                        <div class="label">체크인</div>
                      </label>
                    </li>
                    <li class="item">
                      <label>
                        <input
                            type="checkbox"
                            v-model="gridFilterCheck.payingFlag"
                            @change="onGridFilterCheckChange"
                        />
                        <i
                            :style="
                            gridFilterCheck.payingFlag === true
                              ? timeStatusPayingLabelStyle
                              : ''
                          "
                        ></i>
                        <div class="label">정산중</div>
                      </label>
                    </li>
                    <li class="item">
                      <label>
                        <input
                            type="checkbox"
                            v-model="gridFilterCheck.payCompleteFlag"
                            @change="onGridFilterCheckChange"
                        />
                        <i
                            :style="
                            gridFilterCheck.payCompleteFlag === true
                              ? timeStatusCheckOutLabelStyle
                              : ''
                          "
                        ></i>
                        <div class="label">정산완료</div>
                      </label>
                    </li>
                    <!--                    <li class="item">-->
                    <!--                      <i :style="timeStatusEmptyLabelStyle" />-->
                    <!--                      <div class="label">-->
                    <!--                        예약가능-->
                    <!--                      </div>-->
                    <!--                    </li>-->
                    <!--                    <li class="item">-->
                    <!--                      <i :style="timeStatusRainLabelStyle" />-->
                    <!--                      <div class="label">-->
                    <!--                        우천취소-->
                    <!--                      </div>-->
                    <!--                    </li>-->
                    <!--                    <li class="item">-->
                    <!--                      <i :style="timeStatusReservedLabelStyle" />-->
                    <!--                      <div class="label">-->
                    <!--                        예약-->
                    <!--                      </div>-->
                    <!--                    </li>-->
                    <!--                    <li class="item">-->
                    <!--                      <i :style="timeStatusCheckInLabelStyle" />-->
                    <!--                      <div class="label">-->
                    <!--                        체크인-->
                    <!--                      </div>-->
                    <!--                    </li>-->
                    <!--                    <li class="item">-->
                    <!--                      <i :style="timeStatusPayingLabelStyle" />-->
                    <!--                      <div class="label">-->
                    <!--                        정산중-->
                    <!--                      </div>-->
                    <!--                    </li>-->
                    <!--                    <li class="item">-->
                    <!--                      <i :style="timeStatusCheckOutLabelStyle" />-->
                    <!--                      <div class="label">-->
                    <!--                        정산완료-->
                    <!--                      </div>-->
                    <!--                    </li>-->
                  </ul>
                </li>
                <li class="field">
                  <div class="title">예약자</div>
                  <ul class="content">
                    <li class="item">
                      <i
                          :style="reserveNameRoomPkgStyle"
                      ></i>
                      <div class="label">객실PKG</div>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">5인</div>
                  <ul class="content">
                    <li class="item">
                      <label>
                        <input
                            type="checkbox"
                            v-model="visit5Flag"
                            @change="onGridFilterCheckChange"
                        />
                        <i></i>
                        <div class="label">5인 내장객</div>
                      </label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="settings">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="openGroupSettingPopup"
                  >
                    단체팀 설정
                  </erp-button>
                </li>
                <li class="settings">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="openRainSettingPopup"
                  >
                    악천후 설정
                  </erp-button>
                </li>
                <li class="registration">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="openGalleryAddPopup"
                  >
                    갤러리 등록
                  </erp-button>
                </li>
                <li v-if="isVisivleWorkInBtn" class="registration">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="openWalkInAddPopup"
                  >
                    워크인 등록
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="openHoleAddPopup"
                  >
                    홀 추가
                  </erp-button>
                </li>
                <li v-if="false" class="lookup">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="openReservationTimeDetailPopup"
                  >
                    예약상세 조회
                  </erp-button>
                </li>
                <li v-if="false" class="lookup">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="viewReservationInfo"
                  >
                    예약정보 조회
                  </erp-button>
                </li>
                <li class="lookup">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="openDailyStatusPopup"
                  >
                    일일 종합 현황
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="GET"
                      :ignore="
                      isPopupOpened ||
                      bodyClassName === $t('className.frontCheckin.content')"
                      :is-icon-custom="true"
                      @click.native="onLockerViewClicked"
                      :is-custom-shortcut-button="true"
                      :shortcut-key="'FrontCheckinVisit.shortcuts.getLockerView'"
                      :shortcut="{key:'F12'}"
                  >
                    락카상황판
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="SAVE"
                      :ignore="
                      isPopupOpened ||
                      bodyClassName === $t('className.frontCheckin.content')
                    "
                      :is-icon-custom="true"
                      @click.native="openPayLockYesNoCancelPopup"
                      :is-custom-shortcut-button="true"
                      :shortcut-key="'FrontCheckinVisit.shortcuts.saveOpenPayLock'"
                      :shortcut="{  altKey: true, key:'Q'}"
                      class="dev-unlock-area"
                  >
                    LOCK해제
                  </erp-button>
                </li>
                <li class="header-button">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="onClickTakeCompanionMember"
                  >
                    동반자 가져오기
                  </erp-button>
                </li>
                <li class="move">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="onClickMoveVisitTeam"
                  >
                    내장팀 이동
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
                <ul class="lookupDetail-condition" style="padding: 0; display: inline; position: absolute; right: 0">
                  <li class="field">
                    <div class="title" style="width: auto">메모모드</div>
                    <ul class="content">
                      <li class="item switch">
                        <div class="systemESswitch">
                          <ejs-switch
                              v-model="isMemoMode"
                              :checked="isMemoMode"
                          />
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
                ref="checkinTimeGrid"
                :allowExcelExport="true"
                :dataSource="checkinTimeList"
                v-bind="checkinTimeGridProps"
                :isAutoSelectCell="false"
                :isAutoSelectRow="isAutoSelectRow"
                @headerCellInfo="onCheckinTimeHeaderCellInfo"
                @queryCellInfo="onCheckinTimeQueryCellInfo"
                @dataBound="onDataBound"
                @recordClick="onRecordClick"
                @rowSelected="onRowSelected"
                @actionComplete="onCheckinTimeActionComplete"
                @gridCheckboxChanged="onCheckinTimeGridgridCheckboxChanged"
            />
          </div>
        </section>
      </article>
      <!-- 체크인 화면 컴포넌트. 임시로 여기에 배치함-->
      <checkin-layer
          v-if="isCheckinLayerOpened"
          ref="checkinLayer"
          :bsnDate="bsnDateValueModel"
          :bsnDateInfo="bsnDateInfo"
          :saved-search-visit-name="visitFindName"
          :isFindVisitPopupOpen="isFindVisitOneRowPopupOpen"
          :isBackLockerStatusPopupOpen="isLockerStatusPopupOpen"
          :isPayLockYesNoCancelPopupOpen="isPayLockYesNoCancelPopupOpen"
          @footerMessage="onFooterMessage"
          @checkinLayerThumbnailOpen="onCheckinLayerThumbnailOpen"
          @checkinLayerClose="onCheckinLayerClose"
          @checkinLayerContentOpen="onCheckinLayerContentOpen"
          @focusBySelectedRow="onFocusBySelectedRow"
          @dataRefresh="fetchVisitTimeList"
      />
    </div>
    <find-visit-one-row-popup
        ref="findVisitOneRowPopup"
        v-if="isFindVisitOneRowPopupOpen"
        @popupClosed="onFindVisitOneRowPopupClosed"
    />
    <group-setting-popup
        ref="groupSettingPopup"
        v-if="isGroupSettingPopupOpen"
        @popupClosed="onGroupSettingPopupClosed"
    />
    <rain-setting-popup
        ref="rainSettingPopup"
        v-if="isRainSettingPopupOpen"
        @popupClosed="onRainSettingPopupClosed"
    />
    <gallery-add-popup
        :bsn-date="bsnDateValueModel"
        ref="galleryAddPopup"
        v-if="isGalleryAddPopupOpen"
        @popupClosed="onGalleryAddPopupClosed"
    />
    <walk-in-add-popup
        ref="walkInAddPopup"
        v-if="isWalkInAddPopupOpen"
        @popupClosed="onWalkInAddPopupClosed"
    />
    <hole-add-popup
        ref="holeAddPopup"
        v-if="isHoleAddPopupOpen"
        @popupClosed="onHoleAddPopupClosed"
    />
    <daily-status-popup
        ref="dailyStatusPopup"
        v-if="isDailyStatusPopupOpen"
        @popupClosed="onDailyStatusPopupClosed"
    />
    <reservation-time-detail-popup
        ref="reservationTimeDetailPopup"
        v-if="isReservationTimeDetailPopupOpen"
        @popupClosed="onReservationTimeDetailPopupClosed"
    />
    <reservation-info-popup
        v-if="isReservationInfoPopupOpen"
        ref="reservationInfoPopup"
        @popupClosed="onReservationInfoPopupClose"
    />
    <locker-status-popup
        v-if="isLockerStatusPopupOpen"
        ref="lockerStatusPopup"
        @popupClosed="onLockerStatusPopupClose"
        :popupOpened="isLockerStatusPopupOpened"
    />
    <cash-receipt-popup
        ref="cashReceiptPopup"
        v-if="isCashReceiptPopupOpen"
        @popupClosed="onCashReceiptPopupClose"
    />
    <check-check-popup
        ref="checkCheckPopup"
        storeCode="FRONT"
        v-if="isCheckCheckPopupOpen"
        @popupClosed="onCheckCheckPopupClose"
    />
    <pay-lock-yes-no-cancel-popup
        ref="payLockYesNoCancelPopup"
        v-if="isPayLockYesNoCancelPopupOpen"
        @yesButtonClicked="onPayLockYesNoCancelPopupYesButtonClicked"
        @noButtonClicked="onPayLockYesNoCancelPopupNoButtonClicked"
        @popupClosed="onPayLockYesNoCancelPopupClose"
    />
    <partner-certification-popup
        ref="partnerCertificationPopup"
        v-if="isPartnerCertificationPopupOpen"
        @popupClosed="onPartnerCertificationPopupClose"
    />
    <no-front-and-cart-sales-slip-popup
        ref="noFrontAndCartSalesSlipPopup"
        v-if="isNoFrontAndCartSalesSlipPopupOpen"
        @popupClosed="onNoFrontAndCartSalesSlipPopupClose"
    />
    <locker-use-visitor-popup
        ref="lockerUseVisitorPopup"
        v-if="isLockerUseVisitorPopupOpen"
        @popupConfirm="onLockerUseVisitorPopupConfirm"
        @popupClosed="onLockerUseVisitorPopupClose"
    />
    <move-team-popup
        ref="moveTeamPopup"
        v-if="isMoveTeamPopup"
        @popupClosed="moveTeamPopupClosed"
        @popupConfirmed="moveTeamPopupConfirmed"
    />
  </div>
</template>



<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { mapActions, mapGetters } from "vuex";
import {
  Edit,
  Resize,
  ForeignKey,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";

import _orderBy from "lodash/orderBy";
import {
  commonCodesGetCommonCode,
  commonCodesGetColorValue,
  commonCodesGetComName,
  commonCodesGetStandardInfo, commonCodesGetSortNo,
  commonCodesGetCodeAbrv,
} from '@/utils/commonCodes';
import {
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
  getTodayNavigationDate,
  getFormattedDate,
  DATE_FORMAT_YYYY_MM_DD, getTodayDate,
} from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { getDeviceInfo } from "@/utils/device";
import {
  getVisitTimeList,
  getFindVisitList,
  putVisitTimeList,
  putKioskSet,
  putMoveTeam,
} from "@/api/frontManagement";
import {
  getTsLockerDalyCount,
  createLockerInfoInBatch,
} from "@/api/lockerInfoList";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import checkinLayer from "./CheckinLayer";
import findVisitOneRowPopup from "@/views/common/FindVisitOneRowPopup";
import groupSettingPopup from "./popup/GroupSettingPopup";
import rainSettingPopup from "./popup/RainSettingPopup";
import galleryAddPopup from "../../components/popups/GalleryAddPopup";
import walkInAddPopup from "./popup/WalkInAddPopup";
import holeAddPopup from "./popup/HoleAddPopup";
import dailyStatusPopup from "./popup/DailyStatusPopup";
import lockerStatusPopup from "./popup/LockerStatusPopup";
import reservationTimeDetailPopup from "./popup/ReservationTimeDetailPopup";
import reservationInfoPopup from "@/views/common/ReservationInfoPopup";
import InputDate from "@/components/common/datetime/InputDate";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import moment from "moment";
import CashReceiptPopup from "@/views/common/CashReceiptPopup";
import CheckCheckPopup from "@/components/popups/CheckCheckPopup";
import PayLockYesNoCancelPopup from "./popup/PayLockYesNoCancelPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import PartnerCertificationPopup from "@/views/common/PartnerCertificationPopup";
import NoFrontAndCartSalesSlipPopup from "@/views/checkin/popup/NoFrontAndCartSalesSlipPopup";
import LockerUseVisitorPopup from "@/views/checkin/popup/LockerUseVisitorPopup";

import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import MoveTeamPopup from "@/views/checkin/popup/MoveTeamPopup";

import { openNewWindow } from "@/utils/appInfo";
import {
  addShortcut,
  keypressEventEqualsShortcut,
  removeShortcut,
  SHORTCUT_KEYS,
} from "@/utils/KeyboardUtil";

import vipIcon from '@/assets/images/common/vip.png';
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";

const GRID_COLUMN_RESVE_NAME_IDX = 9;
const GRID_COLUMN_MEMO_ICON_IDX = 13;
const GRID_COLUMN_CHECKIN_ICON_IDX = 14;
const GRID_COLUMN_CHECKOUT_ICON_IDX = 15;

const SHORTCUT_CHECKIN = {
  key: "FrontCheckinVisit.shortcuts.checkin",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F7,
  },
};
const SHORTCUT_CHECKOUT = {
  key: "FrontCheckinVisit.shortcuts.checkout",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.F8,
  },
};
const SHORTCUT_FOCUS_VISIT_NAME = {
  key: "FrontCheckinVisit.shortcuts.focusVisitName",
  shortcut: {
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    key: SHORTCUT_KEYS.Insert,
  },
};
export default {
  name: "frontCheckinVisit",
  components: {
    MoveTeamPopup,
    InputText,
    CashReceiptPopup,
    EjsGridWrapper,
    InputDate,
    lockerStatusPopup,
    checkinLayer,
    findVisitOneRowPopup,
    groupSettingPopup,
    rainSettingPopup,
    galleryAddPopup,
    walkInAddPopup,
    holeAddPopup,
    dailyStatusPopup,
    reservationTimeDetailPopup,
    reservationInfoPopup,
    CheckCheckPopup,
    PayLockYesNoCancelPopup,
    PartnerCertificationPopup,
    NoFrontAndCartSalesSlipPopup,
    LockerUseVisitorPopup,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  async created() {
    const queryBsnDate = this.$route.query.bsnDate;
    const queryTimeId = this.$route.query.timeId;
    const queryVisitId = this.$route.query.visitId;

    // 영업일자 날짜 설정
    if (queryBsnDate && moment(queryBsnDate, "YYYY-MM-DD", true).isValid()) {
      this.bsnDate = queryBsnDate;
    } else {
      const todayDate = await getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD);
      this.bsnDate = moment(todayDate, DATE_FORMAT_YYYY_MM_DD).toDate();
    }

    // timeId가 querystring 파라미터로 오면, 해당 row 선택 처리
    if (queryTimeId && !isNaN(queryTimeId)) {
      this.openedTimeId = parseInt(queryTimeId);
    }
    if (queryVisitId && !isNaN(queryVisitId)) {
      this.openedVisitId = parseInt(queryVisitId);
    }

    this.$EventBus.$on("setInTheMenuOnTheRight", (args) =>
        this.setInTheMenuOnTheRight(args)
    );

    addShortcut(SHORTCUT_CHECKIN.key, SHORTCUT_CHECKIN.shortcut);
    addShortcut(SHORTCUT_CHECKOUT.key, SHORTCUT_CHECKOUT.shortcut);
    addShortcut(
        SHORTCUT_FOCUS_VISIT_NAME.key,
        SHORTCUT_FOCUS_VISIT_NAME.shortcut
    );
    window.addEventListener("keydown", this.onWindowKeypress, true);
    document.onkeydown = async (event) => {
      if ((event.which || event.keyCode) === 116) {
        if (this.isTeamAndVisitInfoChanged()) {
          event.preventDefault();
          if (await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?")) {
            location.reload();
          }
        }
        if (this.isClientMonitorUse) {
          this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
        }
      }
    };

    this.$EventBus.$on("memoConfirm", (args) => {
      this.memoConfirm(args);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("setInTheMenuOnTheRight");
    this.$EventBus.$off("memoConfirm");
  },
  destroyed() {
    window.removeEventListener("keydown", this.onWindowKeypress, true);
    removeShortcut(SHORTCUT_FOCUS_VISIT_NAME.key);
    removeShortcut(SHORTCUT_CHECKOUT.key);
    removeShortcut(SHORTCUT_CHECKIN.key);
  },
  async mounted() {
    const a = await commonCodesGetCommonCode("DIVIDE_DIV");
    if (this.bsnDate) {
      const { data } = await GolfErpAPI.fetchCalenderInfo(
          this.bsnDateValueModel,
          true
      );
      this.bsnDateInfo.bsnCode = data.bsnCode;
      this.bsnDateInfo.dwCode = data.dwCode;
    }

    // 내장객 조회
    await this.fetchVisitTimeList();

    // 일별 락카정보 셋팅.
    this.lockerDailySetting(this.bsnDateValueModel);

    if(this.$route.query?.chkinId && this.$route.query.bsnDate) {
      const find = this.checkinTimeList.find(time => time.chkinId === Number(this.$route.query.chkinId));
      await this.openCheckinLayer(find, GRID_COLUMN_CHECKOUT_ICON_IDX, true);
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isTeamAndVisitInfoChanged()) {
      if (
          !this.isReservationDetailModifyMode &&
          !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
      ) {
        return;
      }
    }
    if (this.isClientMonitorUse) {
      this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
    }
    next();
  },
  data() {
    return {
      vipIcon,

      visit5Flag: false,
      failedFindReservation: false, //예약자 찾기 실패
      setInTheMenuOnTheRightPreviousRowIndex: null,
      openedTimeId: null,
      openedVisitId: null,
      count: 0,

      isPartnerCertificationPopupOpen: false,
      isNoFrontAndCartSalesSlipPopupOpen: false,
      isLockerUseVisitorPopupOpen: false,
      isCheckinLayerOpened: false,
      bodyClassName: null,

      bsnDate: new Date(),
      courseCode: "",
      partDiv: "",
      visitFindName: null, // 예약자/내장객명
      visitFindLockerNo: null, // 락카번호
      savedSearchValue: null,
      savedSearchTimeId: null,

      clientMonitorState: false, // 고객 모니터 ON/OFF 토글
      selectedRowData: null, // 선택된 Row Data

      checkinTimeList: [],
      pureCheckinTimeList: [],

      isFindVisitOneRowPopupOpen: false,
      isGroupSettingPopupOpen: false,
      isRainSettingPopupOpen: false,
      isGalleryAddPopupOpen: false,
      isWalkInAddPopupOpen: false,
      isHoleAddPopupOpen: false,
      isDailyStatusPopupOpen: false,
      isReservationTimeDetailPopupOpen: false,
      isReservationInfoPopupOpen: false,
      isLockerStatusPopupOpen: false,
      isCashReceiptPopupOpen: false,
      isCheckCheckPopupOpen: false,
      isPayLockYesNoCancelPopupOpen: false,
      isMoveTeamPopup: false,
      bsnDateInfo: {
        bsnCode: null,
        dwCode: null,
      },
      gridFilterCheck: {
        emptyFlag: false,
        rainCancelFlag: false,
        resveFlag: true,
        checkinFlag: true,
        payingFlag: true,
        payCompleteFlag: true,
      },
      paymentVisitStatements: [],
      isMemoMode: true,
    };
  },
  computed: {
    ...mapGetters("dialog", ["activeDialog"]),
    ...mapGetters(["userId"]),

    isVisivleWorkInBtn(){
      return this.bsnDateValueModel == getTodayDate();
    },
    isAutoSelectRow() {
      return !this.isCheckinLayerOpened;
    },
    isLockerStatusPopupOpened() {
      return (
          this.isLockerStatusPopupOpen
      );
    },
    isPopupOpened() {
      return (
          this.isPartnerCertificationPopupOpen ||
          this.isFindVisitOneRowPopupOpen ||
          this.isGroupSettingPopupOpen ||
          this.isRainSettingPopupOpen ||
          this.isGalleryAddPopupOpen ||
          this.isWalkInAddPopupOpen ||
          this.isHoleAddPopupOpen ||
          this.isDailyStatusPopupOpen ||
          this.isReservationTimeDetailPopupOpen ||
          this.isReservationInfoPopupOpen ||
          this.isLockerStatusPopupOpen ||
          this.isCashReceiptPopupOpen ||
          this.isCheckCheckPopupOpen ||
          this.isPayLockYesNoCancelPopupOpen ||
          this.isLockerUseVisitorPopupOpen ||
          this.isMoveTeamPopup
      );
    },
    isClientMonitorUse() {
      return getDeviceInfo().isClientMonitorUse;
    },
    lockerViewShortcutButtonProps() {
      return {
        shortcutKey: "FrontCheckinVisit.shortcuts.lockerView",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F12,
        },
      };
    },
    payLockYesNoCancelPopupShortcutButtonProps() {
      return {
        shortcutKey: "FrontCheckinVisit.shortcuts.payLockYesNoCancelPopup",
        shortcut: {
          ctrlKey: false,
          altKey: true,
          shiftKey: false,
          key: SHORTCUT_KEYS.Q,
        },
      };
    },
    bsnDateValueModel: {
      get() {
        if (!this.bsnDate) {
          return;
        }
        return moment(this.bsnDate).format(DATE_FORMAT_YYYY_MM_DD);
      },
      set(value) {
        const bsnDate = moment(value, DATE_FORMAT_YYYY_MM_DD);
        if (bsnDate.isValid()) {
          this.bsnDate = bsnDate.toDate();
        } else {
          this.bsnDate = undefined;
        }
      },
    },
    dayOfWeekCaptionStyle() {
      return {
        color: getDayOfWeekCaptionColor(this.bsnDate),
      };
    },
    dayOfWeekCaption() {
      return getDayOfWeekCaption(this.bsnDate);
    },
    weekdayWeekendCaptionStyle() {
      return {
        color: getWeekdayWeekendCaptionColor(this.bsnDate),
      };
    },
    weekdayWeekendCaption() {
      return getWeekdayWeekendCaption(this.bsnDate);
    },
    courseCodes() {
      return commonCodesGetCommonCode("COURSE_CODE");
    },
    courseCodeDropdownListProps() {
      const dataSource = JSON.parse(JSON.stringify(this.courseCodes));
      dataSource.unshift({
        comCode: "",
        comName: "전체",
      });

      return {
        allowFiltering: false,
        dataSource,
        fields: { text: "comName", value: "comCode" },
      };
    },
    partDivs() {
      return commonCodesGetCommonCode("PART_DIV");
    },
    partDivDropdownListProps() {
      const dataSource = JSON.parse(JSON.stringify(this.partDivs));
      dataSource.unshift({
        comCode: "",
        comName: "전체",
      });

      return {
        allowFiltering: false,
        dataSource,
        fields: { text: "comName", value: "comCode" },
      };
    },
    etcMenuItems() {
      return [
        // {
        //   id: 1,
        //   text: "일일 종합 현황",
        // },
        {
          id: 2,
          text: "현금영수증 발행/취소",
        },
        {
          id: 3,
          text: "수표조회",
        },
        // {
        //   id: 4,
        //   text: "공제조합/협회인증",
        // },
      ];
    },
    shortcutMenuItems() {
      return [
        {
          id: 1,
          text: "락카번호 관리",
        },
        {
          id: 2,
          text: "단체팀 재구성",
        },
        {
          id: 3,
          text: "예약접수등록",
        },
        {
          id: 4,
          text: "예약 방명록 출력",
        },
      ];
    },
    timeStatusEmptyLabelStyle() {
      return {
        "background-color": commonCodesGetColorValue("CHKIN_STATUS", "EMPTY"),
      };
    },
    timeStatusRainLabelStyle() {
      return {
        "background-color": commonCodesGetColorValue("CHKIN_STATUS", "RAIN"),
      };
    },
    timeStatusReservedLabelStyle() {
      return {
        "background-color": commonCodesGetColorValue("CHKIN_STATUS", "RESVE"),
      };
    },
    timeStatusCheckInLabelStyle() {
      return {
        "background-color": commonCodesGetColorValue("CHKIN_STATUS", "CHKIN"),
      };
    },
    timeStatusPayingLabelStyle() {
      return {
        "background-color": commonCodesGetColorValue("CHKIN_STATUS", "PAYING"),
      };
    },
    timeStatusCheckOutLabelStyle() {
      return {
        "background-color": commonCodesGetColorValue("CHKIN_STATUS", "CHKOUT"),
      };
    },
    reserveNameRoomPkgStyle() {
      return {
        "background-color": commonCodesGetColorValue("COLOR_MEMBER_DIV", "ROOM_PKG"),
      };
    },
    checkinTimeGridProps() {
      return {
        columns: [
          {field: "timeId",              headerText: "타임ID",   allowEditing: false, isPrimaryKey: true, visible: false, type: "number",},
          {field: "startTime",           headerText: "시간",     allowEditing: false, minWidth: 16, width: 70, textAlign: "center", type: "string",},
          {field: "startCourse",         headerText: "코스",     allowEditing: false, isCommonCodeField: true, groupCode: "COURSE_CODE", minWidth: 16, width: 70, textAlign: "left", type: "string",},
          {field: "holeDiv",             headerText: "홀",       allowEditing: false, isCommonCodeField: true, groupCode: "HOLE_DIV", minWidth: 16, width: 55, textAlign: "left", type: "string",},
          {field: "selfFlag",            headerText: "S",        allowEditing: false, minWidth: 16, width: 50, textAlign: "center", editType: "booleanedit", type: "boolean", displayAsCheckBox: true,},
          {field: "kioskUseFlag",        headerText: "K",                             minWidth: 16, width: 50, textAlign: "center", editType: "booleanedit", type: "boolean", displayAsCheckBox: true,},
          {field: "grpName",             headerText: "단체명",   allowEditing: false, minWidth: 16, width: 100, textAlign: "left", type: "string",},
          {field: "resveChannel",        headerText: "채널",     allowEditing: false, isCommonCodeField: true, groupCode: "RESVE_CHANNEL", minWidth: 16, width: 70, textAlign: "left", type: "string",},
          {field: "resveName",           headerText: "예약자",   allowEditing: false, minWidth: 16, width: 130, textAlign: "left", type: "string",},
          {field: "memberNo",            headerText: "회원번호", allowEditing: false, minWidth: 16, width: 80, textAlign: "center", type: "string", valueAccessor: (field, data) => gridUtilGetMemberNoColumnAccess(field, data),},
          {field: "visitCount",          headerText: "인원",     allowEditing: false, minWidth: 16, width: 60, textAlign: "center", type: "string",},
          {field: "cartKind",            headerText: "",         allowEditing: false, minWidth: 16, width: 32, textAlign: "center", type: "string", valueAccessor: () => ''},
          {field: "memo",                headerText: "",         allowEditing: false, allowFiltering: false, minWidth: 16, width: 32, type: "string",},
          {field: "ciIcon",              headerText: "",         allowEditing: false, minWidth: 16, width: 32, allowFiltering: false,},
          {field: "coIcon",              headerText: "",         allowEditing: false, minWidth: 16, width: 32, allowFiltering: false,},
          {field: "visitName1",          headerText: "내장객1",  allowEditing: false, minWidth: 16, width: 90, textAlign: "left", type: "string",},
          {field: "visitName2",          headerText: "내장객2",  allowEditing: false, minWidth: 16, width: 90, textAlign: "left", type: "string",},
          {field: "visitName3",          headerText: "내장객3",  allowEditing: false, minWidth: 16, width: 90, textAlign: "left", type: "string",},
          {field: "visitName4",          headerText: "내장객4",  allowEditing: false, minWidth: 16, width: 90, textAlign: "left", type: "string",},
          {field: "visitConfirmFlag",    headerText: "확정",     allowEditing: false, minWidth: 16, width: 60, textAlign: "center", type: "boolean", displayAsCheckBox: true,},
          {field: "visitName5",          headerText: "내장객5",  allowEditing: false, minWidth: 16, width: 90, textAlign: "left", type: "string",},
          {field: "visitCnt",            headerText: "",         allowEditing: false, minWidth: 16, width: 45, textAlign: "center", type: "number", format: "N",},
          {field: "kepFlag",             headerText: "보관",                          minWidth: 16, width: 65, textAlign: "center", editType: "booleanedit", type: "boolean", displayAsCheckBox: true,},
          {field: "caddieName",          headerText: "캐디",     allowEditing: false, minWidth: 16, width: 70, textAlign: "left", type: "string",},
          {field: "cartNo",              headerText: "카트",     allowEditing: false, minWidth: 16, width: 70, textAlign: "left", type: "string",},
          {field: "teamSaleAmt",         headerText: "매출액",   allowEditing: false, minWidth: 16, width: 80, textAlign: "right", type: "number", format: "N",},
          {field: "teamPayAmt",          headerText: "정산액",   allowEditing: false, minWidth: 16, width: 80, textAlign: "right", type: "number", format: "N",},
          {field: "teamNoPayAmt",        headerText: "미정산액", allowEditing: false, minWidth: 16, width: 80, textAlign: "right", type: "number", format: "N",},
          {field: "chkinTime",           headerText: "C/I",      allowEditing: false, minWidth: 16, width: 65, textAlign: "left", type: "string", isTimeType: true,},
          // TODO : 현재 이 컬럼때문에, 예약자/내장객명 첫 입력 시 그리드가 한 번 refresh 되는 버그 있음. 이 컬럼 주석처리 시 없어짐. 이후 진행 예정
          {field: "chkoutTime",          headerText: "C/O",      allowEditing: false, minWidth: 16, width: 65, textAlign: "left", type: "string", isTimeType: true,},
          {field: "promtnName",          headerText: "프로모션", allowEditing: false, minWidth: 16, width: 100, textAlign: "left", type: "string",},
          {field: "paymtEventTitle",     headerText: "이벤트",   allowEditing: false, minWidth: 16, width: 100, textAlign: "left", type: "string",},
          {field: "joinFlag",            headerText: "J",        allowEditing: false, minWidth: 16, width: 50, textAlign: "center", type: "boolean", displayAsCheckBox: true,},
          {field: "optFlag",             headerText: "",         allowEditing: false, visible: false,},
          {field: "resveConfirmOptFlag", headerText: "",         allowEditing: false, visible: false,},
          {field: "chkinStatus",                                 allowEditing: false, width: 100, visible: false, type: "string",},
        ],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        filterSettings: { type: "Menu" },
        provides: [Edit, Resize, ForeignKey, ExcelExport],
        selectionSettings: {
          type: "Multiple",
          mode: "Both",
          enableToggle: false,
        },
      };
    },
  },
  methods: {
    ...mapActions("memoView", ["clearMemo"]),
    // ...mapMutations("storePointOfSaleSystem/checkCheckPopup", {
    //   openCheckCheckPopup: "open"
    // }),

    // event handlers
    numberWithCommas,
    getDayOfWeekCaptionColor,
    commonCodesGetColorValue,
    commonCodesGetComName,
    getCurrentBizCode,
    setAbrvName(name,div) {
      return name ?
          commonCodesGetCodeAbrv("MEMBER_DIV", div)
              ? commonCodesGetCodeAbrv("MEMBER_DIV", div) + " " + name
              : "" + name
          : "";
      },
    setInTheMenuOnTheRight(args) {
      this.setInTheMenuOnTheRightPreviousRowIndex = this.$refs.checkinTimeGrid.getSelectedRowIndexes();
      this.openedTimeId = args.timeId;
      if (getFormattedDate(this.bsnDate) === args.bsnDate) {
        this.onDataBound();
      } else {
        this.bsnDate = args.bsnDate;
      }
    },
    onDataBound() {
      if (this.openedTimeId) {
        const idxToSelect = this.$refs.checkinTimeGrid.getRowIndexByPrimaryKey(
            this.openedTimeId
        );
        if (idxToSelect >= 0) {
          this.$refs.checkinTimeGrid.selectRow(idxToSelect);
        }

        // time id가 타임 목록 전체에 없으면 return;
        if (idxToSelect < 0) {
          return;
        }

        if (idxToSelect >= 0) {
          const selectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();

          const eventData = {
            cellIndex: GRID_COLUMN_RESVE_NAME_IDX,
            rowData: selectedRecords[0],
          };

          this.onRecordClick(eventData);

          if (this.openedVisitId) {
            this.setCheckinLayerUI(
                this.checkinTimeList[idxToSelect],
                GRID_COLUMN_CHECKOUT_ICON_IDX,
                false,
                true,
            );
          }
        }

        this.openedTimeId = null;
        this.openedVisitId = null;
        this.setInTheMenuOnTheRightPreviousRowIndex = null;
      }

      // 메모 자동오픈이 아닌 사용자가 클릭해서 열리는 방식으로 변경. 그로인해 clearMemo 주석처리.
      // this.clearMemo();
    },
    isTeamAndVisitInfoChanged() {
      if (this.isCheckinLayerOpened) {
        return this.$refs.checkinLayer.isTeamInfoAndvisitInfoListIsChanged();
      } else {
        return false;
      }
    },
    async onSearchBtnClicked() {
      if (
          this.isTeamAndVisitInfoChanged() &&
          !this.$refs.checkinLayer?.isCheckinLayerThumbnail
      ) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
      }
      // Layer 상세 화면이 떠 있을 때에는 상세 refresh
      if (
          this.isCheckinLayerOpened &&
          this.$refs.checkinLayer.isCheckinLayerStatisIsContent()
      ) {
        await this.$refs.checkinLayer.onRefreshBtnClicked(null, false);
      }

      this.closeCheckinAndGetCheckinTimeList();
    },
    /**
     * Checkin Layer를 닫은 후 조회하기
     */
    closeCheckinAndGetCheckinTimeList() {
      this.fetchVisitTimeList();
    },
    async onBsnDateChange(args) {
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
      }

      this.closeCheckinAndGetCheckinTimeList();

      // 일별 락카정보 셋팅.
      this.lockerDailySetting(this.bsnDateValueModel);

      this.bsnDateInfo = args;
    },
    async onVisitSearchCourseCodeChange() {
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
      }
      this.closeCheckinAndGetCheckinTimeList();
    },
    async onVisitSearchPartDivChange() {
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
      }
      this.closeCheckinAndGetCheckinTimeList();
    },
    onCheckinTimeHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node
      } = args;
      if(field === 'cartKind') {
        node.classList.add(this.$t("className.grid.cartImage"));
      } else if (field === 'memo') {
        node.classList.add(this.$t("className.grid.memoArea"));
      } else if (field === 'ciIcon') {
        node.classList.add(this.$t("className.frontCheckin.checkinIcon"));
      } else if (field === 'coIcon') {
        node.classList.add(this.$t("className.frontCheckin.checkoutIcon"));
      } else if (field === 'chkinTime') {
        node.classList.add(this.$t("className.frontCheckin.checkinTimeIcon"));
      } else if (field === 'chkoutTime') {
        node.classList.add(this.$t("className.frontCheckin.checkoutTimeIcon"));
      } else if (field === 'visitConfirmFlag') {
        node.classList.add(this.$t("className.grid.confirmArea"));
      } else if (field === 'visitCnt') {
        node.classList.add(this.$t("className.grid.peopleArea"));
      } else if (field === 'caddieName') {
        node.classList.add(this.$t("className.grid.caddieArea"));
      } else if (field === 'kepFlag') {
        node.classList.add(this.$t("className.grid.lockerArea"));
      } else if (field === "resveName") {
        node.classList.add(this.$t("className.grid.clickAreaUp"));
      }
    },
    onCheckinTimeQueryCellInfo(args) {
      const {
        column: {
          edit,
          field,
        },
        cell,
        data,
      } = args;
      if (!(edit.parent.sortedColumns.length > 0)) {
        if (data.partDivLineFlag) {
          cell.classList.add(
              this.$t("className.grid.devReservationPartDivLine")
          );
        }
      }
      if (cell.classList.contains("e-gridchkbox")) {
        // 체크박스 수정영역
        cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (field === "resveName") {
        // 예약자
        cell.style = "position : relative";
        cell.classList.add(this.$t("className.grid.clickAreaUp"));
        if (data.galleyTeamFlag) {
          cell.style.color = "#FF0000";
        }
        if (data.resveCmpnFlag) {
          cell.classList.add(this.$t("className.grid.incomeDeduction"));
        }
        if (data.vipFlag) {
          cell.classList.add(this.$t("className.grid.vipArea"));
          cell.innerHTML = `<div class="vip">VIP</div>${data.resveName}`;
        }
        if (data.roomResveGroupCount) {
          cell.style.background = commonCodesGetColorValue("COLOR_MEMBER_DIV", "ROOM_PKG");
        }
      } else if(field === "cartKind") {
        if(data.cartKind === 'LIMOUSINE'){
          cell.classList.add(this.$t("className.grid.cartImage"));
        }
      } else if (field === "memo") {
        // 메모가 있는 경우에는 클래스 추가
        if (data.memoFlag) {
          if (data.newMemoFlag && commonCodesGetStandardInfo("memoConfirmFlag")) {
            cell.classList.add(this.$t("className.grid.newMemoArea"));
          } else {
            cell.classList.add(this.$t("className.grid.memoArea"));
          }
        }
      } else if (field === "ciIcon") {
        cell.classList.add(this.$t("className.frontCheckin.checkinIcon"));
      } else if (field === "coIcon") {
        cell.classList.add(this.$t("className.frontCheckin.checkoutIcon"));
      } else if (field === "visitCnt") {
        cell.innerHTML = cell.innerHTML + "명";
      } else if (
          field === "visitName1" ||
          field === "visitName2" ||
          field === "visitName3" ||
          field === "visitName4" ||
          field === "visitName5"
      ) {
        // 내장객 1 ~ 5
        cell.style.color = this.getVisitNameTextColor(
            field,
            data
        );
        cell.style.backgroundColor = this.getVisitNameBackColor(
            field,
            data
        );
      } else if (field === "startTime") {
        // 시간 column
        cell.style.backgroundColor = commonCodesGetColorValue(
            "CHKIN_STATUS",
            data.chkinStatus
        );
        if (
            data.chkinStatus === "RAIN" ||
            data.chkinStatus === "PAYING" ||
            data.chkinStatus === "CHKOUT"
        ) {
          cell.style.color = "#FFFFFF";
        }
        cell.classList.add(this.$t("className.grid.selectedCellBold"));
      } else if (field === "holeDiv") {
        // 홀 column
        cell.style.backgroundColor = commonCodesGetColorValue(
            "HOLE_DIV",
            data.holeDiv
        );
      } else if (field === "resveChannel") {
        // 채널
        cell.style.color = commonCodesGetColorValue(
            "RESVE_CHANNEL",
            data.resveChannel
        );
      } else if (field === "visitConfirmFlag") {
        // 체크인등록 후  내장 미확정
        if (!data.visitConfirmFlag && data.visitCnt > 0) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "COLOR_VISIT_CONFIRM",
              "0"
          );
        }
      } else if (field === "selfFlag") {
        if (data.selfFlag) {
          cell.style.backgroundColor = commonCodesGetColorValue("COLOR_TIME_STATUS", "SELF_OPEN_FLAG");
        }
      }  else if (field === "caddieName") {
        // 내장확정 후 캐디 미배치 갤러리제외
        if (
            data.visitConfirmFlag &&
            !data.caddieFlag &&
            !data.galleyTeamFlag
        ) {
          cell.style.backgroundColor = "#FF1493";
        }
      } else if (field === "teamNoPayAmt") {
        // 미정산액
        if (data.teamNoPayAmt < 0) {
          cell.style.color = "#FF0000";
        }
      } else if (field === "kepFlag") {
        // 보관 수정영역
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }else if (field === "kioskUseFlag") {
        // 키오스크사용여부 수정영역
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }  else if (field === "visitCount") {
        cell.innerText = data.visitCount + "인";
      } else if (field === "startCourse") {
        cell.style.color = commonCodesGetColorValue('COURSE_CODE',data.startCourse);
        cell.style.paddingLeft = "0";
        cell.style.paddingRight = "0";
        cell.style.textAlign = "center"; // Grid Column 설정의 textAlign이 paddingLeft/Right 설정 후 적용되지 않음.
      }else if (field === "grpName") {
        //단체구분색상
        cell.style.backgroundColor = commonCodesGetColorValue('GRP_DIV',data.grpDiv);
      }
      if([
        'resveName',
        'visitName1',
        'visitName2',
        'visitName3',
        'visitName4',
        'visitName5',
        "caddieName"
      ].includes(field)) {
        const idx = field.replace('visitName',"");
        if(
            this.savedSearchValue &&
            this.savedSearchValue !== '' &&
            data[field]?.includes(this.savedSearchValue) &&
            (!this.savedSearchTimeId || this.savedSearchTimeId === data.timeId)
        ) {
            if (data.vipFlag && field === "resveName") {
              cell.classList.add(this.$t("className.grid.vipArea"));
              cell.innerHTML = `<div class="vip">VIP</div><span style='font-weight: bold; background: yellow'>${this.setAbrvName(data[field],data[`resveMemberDiv`])}</span>`;
            } else if(field === "resveName") {
              cell.innerHTML = `<span style='font-weight: bold; background: yellow'>${this.setAbrvName(data[field],data[`resveMemberDiv`])}</span>`;
            } else {
              cell.innerHTML = `<span style='font-weight: bold; background: yellow'>${this.setAbrvName(data[field],data[`memberDiv${idx}`])}</span>`;
            }
        } else if(
            field !== 'resveName' &&
            this.visitFindLockerNo &&
            data["lockerNo".concat(field.slice(-1))] &&
            data["lockerNo".concat(field.slice(-1))] === this.visitFindLockerNo
        ) {
            cell.innerHTML = `<span style='font-weight: bold; background: yellow'>${this.setAbrvName(data[field],data[`memberDiv${idx}`])}</span>`;
        } else {
          if(field === 'resveName') {
            if (data.vipFlag) {
              cell.classList.add(this.$t("className.grid.vipArea"));
              cell.innerHTML = `<div class="vip">VIP</div>${this.setAbrvName(data[field],data[`resveMemberDiv`])}`;
            } else if (data.resveKind === "TRANS") {
              // 위임 아이콘 추가
              let divTag = document.createElement('div');
              divTag.classList.add('mandate');
              cell.innerText = this.setAbrvName(data[field],data[`resveMemberDiv`]);
              cell.appendChild(divTag);
            } else {
              cell.innerText = this.setAbrvName(data[field],data[`resveMemberDiv`]);
            }
          } else {
            cell.innerText = this.setAbrvName(data[field],data[`memberDiv${idx}`]);
          }
        }
      }
      // 동반자 내장 여부 아이콘 표시
      for (let i = 1; i <= 5; i++) {
        const key = `visitName${i}`;
        const autoChkinFlag = data[`autoChkinFlag${i}`];
        const visitCmpnFlag = data[`visitCmpnFlag${i}`];
        if (field === key) {
          const prpayFlag = this.getVisitNameAddClassPrpayFlag(field, data);
          if (autoChkinFlag) {
            prpayFlag
                ? cell.classList.add(this.$t("className.grid.incomeCmpnConfirmArea"))
                : cell.classList.add(this.$t("className.grid.cmpnConfirmArea"));
          } else if (data.resveCmpnFlag && visitCmpnFlag) {
            prpayFlag
                ? cell.classList.add(this.$t("className.grid.incomeCmpnUnconfirmArea"))
                : cell.classList.add(this.$t("className.grid.cmpnUnconfirmArea"));
          } else {
            if (prpayFlag) {
              cell.classList.add(this.$t("className.grid.incomeDeduction"));
            }
          }
        }
      }
      if ([
        "teamSaleAmt",
        "teamPayAmt",
        "teamNoPayAmt",
      ].includes(field) && data[field] === 0) {
        cell.innerText = "-";
      }
    },
    onRowSelected(args) {
      if (!args.isInteracted) {
        if(!this.savedSearchValue && this.failedFindReservation) {
          this.failedFindReservation = false;
          setTimeout(()=>{
            this.$refs.checkinTimeGrid.clearSelection();
          },1);
        }
        if (
            !this.$refs?.checkinLayer?.checkinLayerStatus ||
            (this.$refs?.checkinLayer?.checkinLayerStatus === 1)
        ) {
          this.onRecordClick({
            cellIndex: GRID_COLUMN_RESVE_NAME_IDX,
            rowData: args.data,
          });
        }
      }
    },
    async onRecordClick(args) {
      const cellIndex = args.cellIndex;

      const prevSelectedRowData = JSON.parse(JSON.stringify(this.selectedRowData));
      this.selectedRowData = args.rowData;

      if (
          this.isTeamAndVisitInfoChanged() &&
          !this.$refs.checkinLayer?.isCheckinLayerThumbnail
      ) {
        if (
            !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
        ) {
          return;
        }
      }

      if (
          prevSelectedRowData
      ) {
        await this.payLock({
          chkinId: prevSelectedRowData.chkinId,
          visitDate: prevSelectedRowData.visitDate,
          grpNo: prevSelectedRowData.grpNo
        });
      }

      this.openCheckinLayerBySelectedRowIndex(args.rowData, cellIndex);
    },
    openCheckinLayerBySelectedRowIndex(data, cellIndex = GRID_COLUMN_RESVE_NAME_IDX) {
      const selecteRowIndexes = this.$refs.checkinTimeGrid.getSelectedRowIndexes();
      if (selecteRowIndexes.length === 1) {
        if (!data) {
          data = this.$refs.checkinTimeGrid
              .getBatchCurrentViewRecords()
              .find((_, index) => index === selecteRowIndexes[0]);
        }
        this.openCheckinLayer(data, cellIndex);
      } else {
        this.closeCheckinLayer();
      }
    },
    onCheckinTimeActionComplete(args) {
      this.count = numberWithCommas(
          this.$refs.checkinTimeGrid?.getGridBatchCount() || 0
      );
      if (
          args.requestType === "refresh" &&
          this.$refs.checkinTimeGrid.getSelectedRecords().length === 0
      ) {
        this.closeCheckinLayer();
        this.clearMemo();
        if (
            !this.isPopupOpened &&
            !this.isCheckinLayerOpened &&
            !this.$refs.checkinLayer?.isPopupOpened
        ) {
          this.focusSearchValue();
        }
      } else if (args.requestType === "refresh") {
        if (!this.isCheckinLayerOpened) {
          const selectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();
          if (!selectedRecords[0].memoFlag) {
            this.clearMemo();
          }
        }
        if (
            !this.isPopupOpened &&
            !this.isCheckinLayerOpened &&
            !this.$refs.checkinLayer?.isPopupOpened
        ) {
          this.focusSearchValue();
        }
      }
    },
    onVisitFindNameEntered() {
      if (!this.visitFindName) {
        return;
      }
      this.savedSearchValue = this.visitFindName;
      this.savedSearchTimeId = null;
      this.findVisitList();
    },
    async onVisitFindLockerNoEntered() {
      this.savedSearchValue = this.visitFindName;
      this.savedSearchTimeId = null;

      const teams = await GolfErpAPI.fetchVisitByVisitDateAndLockerNo({
        visitDate: this.bsnDateValueModel,
        lockerNo: this.visitFindLockerNo
      });

      if (!(teams.length > 0)) {
        this.errorToast("찾는 타임이 없습니다");
      } else if (teams.length === 1) {
        this.findVisits(teams[0].reservationId);
      } else {
        let lockerUseVisitors = [];
        teams.forEach(team => {
          team.visitors.forEach(visitor => {
            lockerUseVisitors.push({
              chkinId: team.id,
              resveId: team.reservationId,
              startCourse: team.course,
              startTime: team.time,
              visitId: visitor.id,
              visitName: visitor.name,
              lockerNo: visitor.lockerNumber
            });
          });
        });
        this.lockerUseVisitorPopupOpen({
          bsnDate: this.bsnDateValueModel,
          gridDataSource: lockerUseVisitors
        });
      }
      // this.focusSearchValue();
      this.$refs.checkinTimeGrid.refresh();
    },
    onLockerUseVisitorPopupConfirm(reservationId) {
      if (reservationId) {
        this.findVisits(reservationId);
      }

      this.isLockerUseVisitorPopupOpen = false;
    },
    lockerUseVisitorPopupOpen(args) {
      this.isLockerUseVisitorPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.lockerUseVisitorPopup.showPopup(args);
      });
    },
    async onFindVisitOneRowPopupClosed(event) {
      this.isFindVisitOneRowPopupOpen = false;
      if (event && event.selectRowData) {
        if (this.isTeamAndVisitInfoChanged()) {
          if (
              !(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))
          ) {
            return;
          }
        }
        this.savedSearchValue = event.searchValue;
        this.savedSearchTimeId = event.selectRowData.timeId;
        // 하이라이트 적용을 위한 임시 데이터 소스 복사
        this.checkinTimeList = JSON.parse(JSON.stringify(this.checkinTimeList));

        this.findVisits(event.selectRowData.resveId);
      }
    },
    onGroupSettingPopupClosed(event) {
      this.isGroupSettingPopupOpen = false;
      if (event && event.isReload === true) {
        // 재조회
        this.closeCheckinAndGetCheckinTimeList();
      }
    },
    onRainSettingPopupClosed(event) {
      this.isRainSettingPopupOpen = false;
      if (event && event.isReload === true) {
        // 재조회
        this.closeCheckinAndGetCheckinTimeList();
      }
    },
    onGalleryAddPopupClosed(event) {
      this.isGalleryAddPopupOpen = false;
      if (event && event.isReload === true) {
        // 재조회
        this.closeCheckinAndGetCheckinTimeList();
      }
    },
    onWalkInAddPopupClosed(event) {
      this.isWalkInAddPopupOpen = false;
      if (event && event.isReload === true) {
        // 재조회
        this.closeCheckinAndGetCheckinTimeList();
      }
    },
    onHoleAddPopupClosed(event) {
      this.isHoleAddPopupOpen = false;
      if (event && event.isReload === true) {
        // 재조회
        this.closeCheckinAndGetCheckinTimeList();
      }
    },
    onDailyStatusPopupClosed() {
      this.isDailyStatusPopupOpen = false;
    },
    onReservationTimeDetailPopupClosed() {
      this.isReservationTimeDetailPopupOpen = false;
    },
    onReservationInfoPopupClose() {
      this.isReservationInfoPopupOpen = false;
    },
    onLockerStatusPopupClose() {
      this.isLockerStatusPopupOpen = false;
    },
    onCheckCheckPopupClose() {
      this.isCheckCheckPopupOpen = false;
    },
    onCashReceiptPopupClose() {
      this.isCashReceiptPopupOpen = false;
    },
    onPayLockYesNoCancelPopupClose() {
      this.isPayLockYesNoCancelPopupOpen = false;
    },
    async onCheckinTimeGridgridCheckboxChanged(args) {
      if (args.columnName === "kepFlag" && args.rowData.kepFlag !== args.value) {
        if (args.rowData.chkinId === undefined) {
          this.errorToast("체크인 하신 후에만 보관하실 수 있습니다");
          this.$refs.checkinTimeGrid.batchCancel();
          return;
        }
        if (await this.confirm("보관 상태를 변경하시겠습니까?")) {
          this.updateVisitTimeList(
              args.rowData.chkinId,
              args.value,
              args.rowData.optFlag
          ).then(({ teamOptFlag }) => {
            this.infoToast("보관 상태가 변경되었습니다");

            const modifiedRowIndex = this.$refs.checkinTimeGrid.getRowIndexByPrimaryKey(
                args.rowData.timeId
            );

            // api에서 받은 optFlag 갱신
            this.$refs.checkinTimeGrid.updateCell(
                modifiedRowIndex,
                "optFlag",
                teamOptFlag
            );

            this.$refs.checkinTimeGrid.batchSave();
          });
        } else {
          this.$refs.checkinTimeGrid.batchCancel();
        }
      } else if (args.columnName === "kioskUseFlag" && args.rowData.kioskUseFlag !== args.value) {
        if (await this.confirm("키오스크 사용 여부를 변경하시겠습니까?")) {
          this.updateKioskSet(args.rowData.resveId, args.value, args.rowData.resveConfirmOptFlag
          ).then(({ resveConfirmOptFlag }) => {
            this.infoToast("키오스크 사용 여부가 변경되었습니다");
            const modifiedRowIndex = this.$refs.checkinTimeGrid.getRowIndexByPrimaryKey(
                args.rowData.timeId
            );
            // api에서 받은 optFlag 갱신
            this.$refs.checkinTimeGrid.updateCell(
                modifiedRowIndex,
                "resveConfirmOptFlag",
                resveConfirmOptFlag
            );
            this.$refs.checkinTimeGrid.batchSave();
          });
        } else {
          this.$refs.checkinTimeGrid.batchCancel();
        }
      }
    },
    onFooterMessage(message) {
      this.setFooterMessage(message);
    },
    onCheckinLayerThumbnailOpen() {
      this.bodyClassName = this.$t("className.frontCheckin.thumbnail");
    },
    onCheckinLayerContentOpen() {
      this.bodyClassName = this.$t("className.frontCheckin.content");
    },
    async onFocusBySelectedRow() {
      await this.$nextTick();
      const gridRefs = this.$refs.checkinTimeGrid;
      const selectedRows = gridRefs?.getSelectedRecords();
      const rowData = selectedRows[0];
      const batchCurrentViewRecords = gridRefs?.getBatchCurrentViewRecords();
      const idxToSelect = batchCurrentViewRecords?.findIndex(item => item._rid === rowData._rid);
      gridRefs?.selectRow(idxToSelect);
    },
    onCheckinLayerClose() {
      this.bodyClassName = null;
      this.isCheckinLayerOpened = false;
    },
    onETCShortcutMenuSelected(args) {
      switch (args.item.id) {
        // case 1:
        //   this.openDailyStatusPopup();
        //   break;
        case 2:
          this.openCashReceiptPopup("FRONT");
          break;
        case 3:
          this.openCheckCheckPopup();
          break;
        // case 4:
        //   this.openPartnerCertificationPopup();
        //   break;
      }
    },
    onShortcutMenuSelected(args) {
      switch (args.item.id) {
        case 1:
          // eslint-disable-next-line no-case-declarations
          let lockerNoRegRouteData = this.$router.resolve({
            name: "LockerNumberRegistration",
          });
          openNewWindow(lockerNoRegRouteData);
          break;
        case 2:
          // eslint-disable-next-line no-case-declarations
          let groupConfegRouteData = this.$router.resolve({
            name: "groupConf",
            query: {
              bsnDate: this.bsnDateValueModel,
            },
          });
          openNewWindow(groupConfegRouteData);
          break;
        case 3:
          // eslint-disable-next-line no-case-declarations
          const gridSelectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();
          // eslint-disable-next-line no-case-declarations
          let timeId;

          if (gridSelectedRecords.length >= 1) {
            timeId = gridSelectedRecords[0].timeId;
          }

          // eslint-disable-next-line no-case-declarations
          let reservationRouteData = this.$router.resolve({
            name: "reservationReceiptRegistration",
            query: {
              bsnDate: this.bsnDateValueModel,
              timeId: timeId,
            },
          });
          openNewWindow(reservationRouteData);
          break;
        case 4:
          // eslint-disable-next-line no-case-declarations
          const SelectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();
          // eslint-disable-next-line no-case-declarations,no-unused-vars
          let selectTimeId;
          // eslint-disable-next-line no-case-declarations
          let selectResveName;
          if (SelectedRecords.length >= 1) {
            selectTimeId = SelectedRecords[0].timeId;
            selectResveName = SelectedRecords[0].resveName;
          }
          // eslint-disable-next-line no-case-declarations
          let reservationGstPrtRouteData = this.$router.resolve({
            name: "ReservationGuestBookPrint",
            query: {
              bsnDate: this.bsnDateValueModel,
              timeId: selectTimeId,
              resveName:selectResveName
            },
          });
          openNewWindow(reservationGstPrtRouteData);
          break;
      }
    },
    openCheckCheckPopup() {
      this.isCheckCheckPopupOpen = true;
    },
    async openPayLockYesNoCancelPopup() {
      const data = this.$refs.checkinTimeGrid.getSelectedRecords();
      //Roy - 락 해제 버튼 클릭시 Confirm 팝업 뜨지 않고 바로 전체 락해제되도록 수정
      if (data.length < 1) {
        this.errorToast("조회된 내장팀이 없습니다.");
        return;
      }
      await this.onPayLockYesNoCancelPopupNoButtonClicked();
      //Roy - 현재 락 해제시 타임 목록이 재조회되지 않아 시간 항목의 색상이 변경되지 않음
      this.closeCheckinAndGetCheckinTimeList();
      //Roy - 기존 로직 주석
      /*if (data.length > 1) {
        this.errorToast("하나의 내장팀만 선택해주시기 바랍니다");
        return;
      }

      this.isPayLockYesNoCancelPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.payLockYesNoCancelPopup.showPayLockYesNoCancelPopup(data);
      });*/
    },
    onLockerViewClicked() {
      this.isLockerStatusPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.lockerStatusPopup.showLockerStatusPopup(
            this.bsnDateValueModel
        );
      });
    },
    /** 동반자 가져 오기 버튼 클릭
     * 1. 멀티 선택시 오류 메세지: 한팀만 선택해 주시기 바랍니다
     * 2. confirm 메세지 :선택하신 내장팀의 동반자를 가져오시겠습니까?
     */
    async onClickTakeCompanionMember() {
      const selectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();
      if (selectedRecords.length === 0) {
        this.errorToast("타임을 선택해주세요.");
      } else if (selectedRecords.length === 1) {
        if(this.$refs.checkinLayer.timeData.chkinId){
          this.errorToast("이미 체크인된 팀 입니다.");
          return;
        }
        if (await this.confirm("선택하신 내장팀의 동반자를 가져오시겠습니까?")) {
          // saveData = await this.getCompanionMember( selectedRecords[0].resveId);
          // 내장 정보 등록 저장 API
          await GolfErpAPI.patchCompanionMember(
              {
                resveId : selectedRecords[0].resveId,
                posNo : getDeviceInfo().posNo
              }
          );
          //재조회
          await this.fetchVisitTimeList();
        }
      } else {
        this.errorToast("타임을 하나만 선택해주세요.");
      }
    },

    /** 내장팀 이동 버튼 클릭 */
    async onClickMoveVisitTeam() {
      const selectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();
      //타임선택 체크
      if (selectedRecords.length === 0) {
        this.errorToast("타임을 선택해주세요.");
      }else if (selectedRecords.length > 1) {
        this.errorToast("타임을 하나만 선택해주세요.");
      }else if (selectedRecords.length === 1) {
        //내장여부체크
        /*
        if(!selectedRecords?.chkinId || selectedRecords.visitCnt === 0){
          this.errorToast("내장객이 없는 타임입니다.");
        }
        */
        //체크인 체크
        if(selectedRecords[0].chkinStatus !== "CHKIN"){
          this.errorToast("체크인 된 타임이 아닙니다.");
        }
        //이동할 예약리스트 팝업
        else{
          this.isMoveTeamPopup = true;
          this.$nextTick(() => {
            this.$refs.moveTeamPopup.initShow({
              bsnDate: moment(this.bsnDate).format(DATE_FORMAT_YYYY_MM_DD),
              chkinId:selectedRecords[0].chkinId,
              grpNo:selectedRecords[0].grpNo
            });
          });
        }
      }
    },
    /** 내장팀 이동 팝업 닫기 */
    moveTeamPopupClosed() {
      this.isMoveTeamPopup = false;
    },
    /** 내장팀 이동 확정 */
    async moveTeamPopupConfirmed(chkinId, resveId){
      this. moveTeamPopupClosed();
      //저장
      await putMoveTeam(chkinId, resveId);
      this.infoToast("내장팀이 이동되었습니다. 이동된 팀의 요금을 확인하시기 바랍니다.");
      //재조회
      this.closeCheckinAndGetCheckinTimeList();
    },
    // features
    async fetchVisitTimeList() {
      const { visitTimeLists } = await this.getVisitTimeList(
          this.bsnDateValueModel,
          this.courseCode || undefined,
          this.partDiv || undefined,
          this.isMemoMode
      );

      this.checkinTimeList = _orderBy(visitTimeLists.map(item => ({
        ...item,
        resveName: item.transName ? item.resveName + " (" + item.transName + ")" : item.resveName,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.startCourse),
      })), ["startTime", "courseCodeSortNo"]);
      this.pureCheckinTimeList = this.checkinTimeList;
      this.visit5Flag = this.checkinTimeList.some((d) => {
       return d?.visitNames.length === 5;
      });

      this.onGridFilterCheckChange();

      // 일마감 여부 확인.
      await GolfErpAPI.fetchCloseCheck({
        bsnDateFrom: this.bsnDateValueModel,
        bsnDateTo: this.bsnDateValueModel,
        storeCode: "FRONT",
      });

      this.paymentVisitStatements = await GolfErpAPI.fetchPaymtCodeByVisitStatements({
        visitDateFrom: this.bsnDateValueModel,
        visitDateTo: this.bsnDateValueModel,
        isNot: false,
        isNoSaleSlip: true,
        gcDiv: "G",
      }).then(response => {
        return response.paymtCodeByVisitStatements ? response.paymtCodeByVisitStatements : [];
      });

      if (this.paymentVisitStatements.length > 0) {
        this.openNoFrontAndCartSalesSlipPopup();
      } else {
        this.onNoFrontAndCartSalesSlipPopupClose();
      }
    },
    async findVisitList() {
      const findVisitLists = await GolfErpAPI.fetchReservationVisitorFind({
        resveDate: this.bsnDateValueModel,
        resveVisitName: this.visitFindName
      });

      if (!Array.isArray(findVisitLists) || findVisitLists.length === 0) {
        this.errorToast("찾으시는 정보가 없습니다");
        this.savedSearchValue = '';
        this.failedFindReservation = true;
      // 1건만 조회되도 팝업 뜨도록 수정 요청(라데나)
      // } else if (findVisitLists.length === 1) {
      //   this.findVisits(findVisitLists[0]?.reservationConfirm?.resveId);
      } else {
        this.isFindVisitOneRowPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.findVisitOneRowPopup.setSearchParams({
            bsnDate: this.bsnDateValueModel,
            courseCode: this.courseCode,
            partDiv: this.partDiv,
            resveVisitName: this.visitFindName,
            resveVisitFlag: true,
          });
          this.$refs.findVisitOneRowPopup.setVisitData(findVisitLists);
          this.$refs.findVisitOneRowPopup.show();
        });
      }

      // 하이라이트 적용을 위한 임시 데이터 소스 복사
      this.checkinTimeList = JSON.parse(JSON.stringify(this.checkinTimeList));
    },
    async updateVisitTimeList(chkinId, kepFlag, optFlag) {
      const { teamOptFlag } = await this.putVisitTimeList(
          chkinId,
          kepFlag,
          optFlag
      );

      return { teamOptFlag };
    },

    async updateKioskSet(resveId, kioskUseFlag, optFlag) {
      const { resveConfirmOptFlag } = await this.putKioskSet(
          resveId,
          kioskUseFlag,
          optFlag
      );

      return { resveConfirmOptFlag };
    },
    getVisitNameAddClassPrpayFlag(fieldName, data) {
      let addClassFlag = false;

      switch (fieldName) {
        case "visitName1":
          if (data.prpayFlag1) {
            addClassFlag = true;
          }
          break;
        case "visitName2":
          if (data.prpayFlag2) {
            addClassFlag = true;
          }
          break;
        case "visitName3":
          if (data.prpayFlag3) {
            addClassFlag = true;
          }
          break;
        case "visitName4":
          if (data.prpayFlag4) {
            addClassFlag = true;
          }
          break;
        case "visitName5":
          if (data.prpayFlag5) {
            addClassFlag = true;
          }
          break;
        default:
          break;
      }

      return addClassFlag;
    },
    getVisitNameBackColor(fieldName, data) {
      let backColor = null;

      switch (fieldName) {
        case "visitName1":
          if (data.visitName1 && !data.visitConfirmFlag1) {
            backColor = "#dedede";
          }
          else if(data.visitName1 && data.foodCnt1 > 0 ) {
            backColor = commonCodesGetColorValue("COLOR_COMMON", "SALES_FOOD");
          }
          else if(data.visitName1 && data.holeDiv1) {
            backColor = commonCodesGetColorValue(
                "HOLE_DIV",
                data.holeDiv1
            );
          }
          break;
        case "visitName2":
          if (data.visitName2 && !data.visitConfirmFlag2) {
            backColor = "#dedede";
          }
          else if(data.visitName2 && data.foodCnt2 > 0 ) {
            backColor = commonCodesGetColorValue("COLOR_COMMON", "SALES_FOOD");
          }
          else if(data.visitName2 && data.holeDiv2) {
            backColor = commonCodesGetColorValue(
                "HOLE_DIV",
                data.holeDiv2
            );
          }
          break;
        case "visitName3":
          if (data.visitName3 && !data.visitConfirmFlag3) {
            backColor = "#dedede";
          }
          else if(data.visitName3 && data.foodCnt3 > 0 ) {
            backColor = commonCodesGetColorValue("COLOR_COMMON", "SALES_FOOD");
          }
          else if(data.visitName3 && data.holeDiv3) {
            backColor = commonCodesGetColorValue(
                "HOLE_DIV",
                data.holeDiv3
            );
          }
          break;
        case "visitName4":
          if (data.visitName4 && !data.visitConfirmFlag4) {
            backColor = "#dedede";
          }
          else if(data.visitName4 && data.foodCnt4 > 0 ) {
            backColor = commonCodesGetColorValue("COLOR_COMMON", "SALES_FOOD");
          }
          else if(data.visitName4 && data.holeDiv4) {
            backColor = commonCodesGetColorValue(
                "HOLE_DIV",
                data.holeDiv4
            );
          }
          break;
        case "visitName5":
          if (data.visitName5 && !data.visitConfirmFlag5) {
            backColor = "#dedede";
          }
          else if(data.visitName5 && data.foodCnt5 > 0 ) {
            backColor = commonCodesGetColorValue("COLOR_COMMON", "SALES_FOOD");
          }
          else if(data.visitName5 && data.holeDiv5) {
            backColor = commonCodesGetColorValue(
                "HOLE_DIV",
                data.holeDiv5
            );
          }
          break;
        default:
          break;
      }

      return backColor;
    },
    getVisitNameTextColor(fieldName, data) {
      let textColor = null;

      switch (fieldName) {
        case "visitName1":
          if (data.galleyTeamFlag) {
            textColor = "#FF0000";
          } else {
            textColor = commonCodesGetColorValue("MEMBER_GRADE", data.memberGrade1);
          }
          break;
        case "visitName2":
          if (data.galleyTeamFlag) {
            textColor = "#FF0000";
          } else {
            textColor = commonCodesGetColorValue("MEMBER_GRADE", data.memberGrade2);
          }
          break;
        case "visitName3":
          if (data.galleyTeamFlag) {
            textColor = "#FF0000";
          } else {
            textColor = commonCodesGetColorValue("MEMBER_GRADE", data.memberGrade3);
          }
          break;
        case "visitName4":
          if (data.galleyTeamFlag) {
            textColor = "#FF0000";
          } else {
            textColor = commonCodesGetColorValue("MEMBER_GRADE", data.memberGrade4);
          }
          break;
        case "visitName5":
          if (data.galleyTeamFlag) {
            textColor = "#FF0000";
          } else {
            textColor = commonCodesGetColorValue("MEMBER_GRADE", data.memberGrade5);
          }
          break;
        default:
          break;
      }

      return textColor;
    },
    async openCheckinLayer(timeData, cellIndex, payGridInfoVisibility=false) {
      if (timeData.chkinStatus === "RAIN" && !timeData.chkinId && cellIndex !== GRID_COLUMN_MEMO_ICON_IDX) {
        this.errorToast('타임상태가 "우천취소" 입니다. 체크인 불가합니다');
        this.closeCheckinLayer();
      } else if (timeData.chkinStatus === "EMPTY") {
        this.errorToast('타임상태가 "예약가능" 입니다. 체크인 불가합니다');
        this.closeCheckinLayer();
      } else if (timeData.resveCmpnFlag && timeData.chkinId === undefined) {
        // 동반자 자동 체크인은 물어보지 않고 무조건 자동 체크인되도록 하기로 함.
        // let resveCmpnFlag = await this.confirm(
        //   '선택한 타임은 "예약 동반자" 가 등록되어 있습니다.\n동반자를 자동 체크인 하시겠습니까? '
        // );
        // 환경설정의 무인 체크인 설정 사용 여부가 true이면 동반자 자동 셋팅 안함.
        let resveCmpnFlag = !commonCodesGetStandardInfo("noneChkinUseFlag");

        // CheckinLayer UI Open 또는 상태 변경
        this.setCheckinLayerUI(timeData, cellIndex, resveCmpnFlag);
      } else {
        // CheckinLayer UI Open 또는 상태 변경
        this.setCheckinLayerUI(timeData, cellIndex, false, payGridInfoVisibility);
      }
    },
    closeCheckinLayer() {
      if (this.isCheckinLayerOpened) {
        this.isCheckinLayerOpened = false;
        this.$refs.checkinLayer.closeCheckin();
      }
    },
    setCheckinLayerUI(timeData, cellIndex, resveCmpnFlag = false, isPayInfoGridVisible = false) {
      this.isCheckinLayerOpened = true;
      const firstMemoOpen = true;
      this.$nextTick(() => {
        if (
            cellIndex === GRID_COLUMN_CHECKIN_ICON_IDX ||
            cellIndex === GRID_COLUMN_CHECKOUT_ICON_IDX
        ) {
          this.$refs.checkinLayer.showCheckinPage(
              timeData,
              this.getStepIndex(cellIndex),
              resveCmpnFlag,
              isPayInfoGridVisible,
              firstMemoOpen,
          );
        } else {
          this.$refs.checkinLayer.showCheckinThumbnail(timeData, resveCmpnFlag, cellIndex, firstMemoOpen);
        }
        // if (cellIndex === undefined) {
        //   // UI 상태는 바꾸지 않고 상세 정보만 refresh
        //   this.$refs.checkinLayer.setDetailData(timeData, resveCmpnFlag);
        // } else if (cellIndex === GRID_COLUMN_RESVE_NAME_IDX) {
        //   this.$refs.checkinLayer.showCheckinThumbnail(timeData, resveCmpnFlag);
        // } else {
        //   this.$refs.checkinLayer.showCheckinPage(
        //     timeData,
        //     this.getStepIndex(cellIndex),
        //     resveCmpnFlag
        //   );
        // }
      });
    },
    getStepIndex(gridColumnIdx) {
      switch (gridColumnIdx) {
        case GRID_COLUMN_CHECKIN_ICON_IDX:
          return 0;
        case GRID_COLUMN_CHECKOUT_ICON_IDX:
          return 1 ;
        default:
          return -1;
      }
    },
    visitFindAllClear() {
      // 예약자/내장객명 필드가 비워져 있지 않아야 내장객 등록, 요금정산 탭 이동시 데이터 찾을 수 있게 로직 구현해놔서
      // onFocus -> onClick으로 Event Change
      this.visitFindName = null;
      this.visitFindLockerNo = null;
    },
    findVisits(visitFindResveId) {
      const findIdx = this.checkinTimeList.findIndex(
          (timeInfo) =>
              (visitFindResveId && timeInfo.resveId === visitFindResveId) ||
              (!visitFindResveId && this.visitFindLockerNo &&
                  (timeInfo.lockerNo1 === this.visitFindLockerNo ||
                      timeInfo.lockerNo2 === this.visitFindLockerNo ||
                      timeInfo.lockerNo3 === this.visitFindLockerNo ||
                      timeInfo.lockerNo4 === this.visitFindLockerNo ||
                      timeInfo.lockerNo5 === this.visitFindLockerNo))
      );

      if (findIdx === -1) {
        this.errorToast("찾는 타임이 없습니다");
      } else {
        this.$refs.checkinTimeGrid.clearSelection();
        this.$refs.checkinTimeGrid.selectRow(findIdx);

        this.openCheckinLayer(
            this.checkinTimeList[findIdx],
            GRID_COLUMN_RESVE_NAME_IDX
        ); // thumbnail open을 위해서, cellIndex를 '예약자' 컬럼의 cellindex와 동일하게 함
      }
    },
    async openGroupSettingPopup() {
      const selectedRows = this.$refs.checkinTimeGrid.getSelectedRecords();

      if (selectedRows.length === 1) {
        this.errorToast("2건 이상의 타임을 선택해 주십시오");
        return;
      }

      // 데이터 수정 체크
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      // data 구성
      let popupData = {};
      let grpInfoElement = selectedRows.find((row) => row.grpNo !== undefined);
      if (grpInfoElement !== undefined) {
        popupData.grpInfo = {
          grpNo: grpInfoElement.grpNo,
          grpKind: grpInfoElement.grpKind,
          grpName: grpInfoElement.grpName,
        };
      } else {
        popupData.grpInfo = {
          grpNo: null,
          grpKind: null,
          grpName: null,
        };
      }
      popupData.chkinTimeList = selectedRows.map((row) => {
        return {
          chkinId: row.chkinId,
          resveNo: row.chkinId ? null : row.resveNo,
          optFlag: row.optFlag,
          visitDate: row.visitDate,
        };
      });
      popupData.bsnDate = this.bsnDateValueModel;

      this.isGroupSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.groupSettingPopup.showGroupSettingPopup(popupData);
      });
    },
    async openRainSettingPopup() {
      const selectedRows = this.$refs.checkinTimeGrid.getSelectedRecords();

      if (!selectedRows.length > 0) {
        this.errorToast("선택된 정보가 없습니다");
        return;
      }

      // 데이터 수정 체크
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }

      // 모두 다 예약 상태이거나, 모두 다 우천취소인 경우에만 ㅇㅋ
      const chkinStatusRainLength = selectedRows.filter(
          (row) => row.chkinStatus === "RAIN"
      ).length;

      if (chkinStatusRainLength === selectedRows.length) {
        this.showRainSettingPopup(selectedRows, "NOCANCEL");
        // RAIN -> RESVE
      } else {
        this.showRainSettingPopup(selectedRows, "CANCEL");
        // RESVE -> RAIN
      }
    },
    showRainSettingPopup(selectedRows, cancelStatus) {
      // data 구성
      let popupData = {};
      popupData.visitTimeInfoLists = selectedRows.map((row) => {
        return {
          resveId: row.resveId,
          chkinId: row.chkinId,
          resveConfirmOptFlag: row.resveConfirmOptFlag,
          visitDate: row.visitDate,
        };
      });

      this.isRainSettingPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.rainSettingPopup.showRainSettingPopup(
            popupData,
            cancelStatus
        );
      });
    },
    async openGalleryAddPopup() {
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isGalleryAddPopupOpen = true;
    },
    async openWalkInAddPopup() {
      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isWalkInAddPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.walkInAddPopup.showWalkInAddPopup(this.bsnDateValueModel);
      });
    },
    async openHoleAddPopup() {
      const selectedRows = this.$refs.checkinTimeGrid.getSelectedRecords();

      if (!selectedRows.length > 0) {
        this.errorToast("선택된 정보가 없습니다");
        return;
      } else if (selectedRows.length > 1) {
        this.errorToast("한 팀만 선택하실 수 있습니다");
        return;
      } else if (
          selectedRows[0].chkinId === undefined ||
          selectedRows[0].resveId === undefined
      ) {
        this.errorToast("홀 추가하실 수 없는 타임입니다");
        return;
      }

      if (this.isTeamAndVisitInfoChanged()) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.isHoleAddPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.holeAddPopup.showHoleAddPopup(
            this.bsnDateValueModel,
            selectedRows[0]
        );
      });

      if (this.$refs.checkinLayer) {
        this.$refs.checkinLayer.onLayerCloseBtnClicked();
      }
    },
    openDailyStatusPopup() {
      this.isDailyStatusPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.dailyStatusPopup.showDailyStatusPopup(
            this.bsnDateValueModel
        );
      });
    },
    openReservationTimeDetailPopup() {
      const selectedRows = this.$refs.checkinTimeGrid.getSelectedRecords();
      if (selectedRows.length !== 1) {
        this.errorToast("한 건의 타임을 선택해 주세요");
        return;
      }

      this.isReservationTimeDetailPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationTimeDetailPopup.showReservationTimeDetailPopup(
            selectedRows[0].resveNo,
            selectedRows[0].timeId
        );
      });
    },
    openCashReceiptPopup() {
      this.isCashReceiptPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.cashReceiptPopup.showPopup("FRONT");
      });
    },

    viewReservationInfo() {
      const selectedRows = this.$refs.checkinTimeGrid.getSelectedRecords();
      if (selectedRows.length !== 1) {
        this.errorToast("한 건의 타임을 선택해 주세요");
        return;
      }
      this.isReservationInfoPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationInfoPopup.showReservationInfoPopup(
            this.bsnDateValueModel,
            moment(this.bsnDateValueModel)
                .add(3, "M")
                .subtract(1, "days")
                .format("YYYY-MM-DD"),
            selectedRows[0].resveName,
            selectedRows[0].memberNo
        );
      });
    },
    openCheckinVisitsFindPopup() {
      this.isFindVisitOneRowPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.findVisitOneRowPopup.showFindVisitPopup({
          bsnDate: this.bsnDateValueModel,
          courseCode: this.courseCode,
          partDiv: this.partDiv,
          resveVisitName: this.visitFindName,
          resveVisitFlag: true,
        });
      });
    },

    // API
    async getVisitTimeList(bsnDate, courseCode, partDiv, isMemoMode) {
      const {
        value: { visitTimeLists },
      } = await getVisitTimeList(bsnDate, courseCode, partDiv, isMemoMode);

      return { visitTimeLists };
    },
    async getFindVisitList(bsnDate, courseCode, partDiv, visitFindName) {
      const {
        value: { findVisitLists },
      } = await getFindVisitList(
          bsnDate,
          courseCode,
          partDiv,
          visitFindName,
      );

      return { findVisitLists };
    },
    async putVisitTimeList(chkinId, kepFlag, optFlag) {
      const {
        value: { teamOptFlag },
      } = await putVisitTimeList(chkinId, kepFlag, optFlag);

      return { teamOptFlag };
    },
    async putKioskSet(resveId, kioskUseFlag, optFlag) {
      const {
        value: { resveConfirmOptFlag },
      } = await putKioskSet(resveId, kioskUseFlag, optFlag);
      return { resveConfirmOptFlag };
    },
    async lockerDailySetting(bsnDate) {
      const { value: lockerDailyCount } = await getTsLockerDalyCount(
          bsnDate,
          true
      );

      if (lockerDailyCount > 0) {
        return;
      }

      await createLockerInfoInBatch(bsnDate, bsnDate, true);
    },
    async payLock(args, compulsionFlag = false) {
      const chkinId = args.chkinId;

      let errorMessageViewFlag = false;
      let isPaying = false;

      try {
        await GolfErpAPI.fetchPayLock(chkinId, errorMessageViewFlag);
      } catch (e) {
        console.log("payLock.err.===>", e);

        if (compulsionFlag) {
          const data = {
            id: chkinId,
            visitDate: args.visitDate,
            isPaying: isPaying,
            group: {
              no: args.grpNo,
            },
          };

          await GolfErpAPI.patchPayLock(data);
        }
        return;
      }

      const data = {
        id: chkinId,
        visitDate: args.visitDate,
        isPaying: isPaying,
        group: {
          no: args.grpNo,
        },
      };

      await GolfErpAPI.patchPayLock(data);
    },
    async onPayLockYesNoCancelPopupYesButtonClicked(args) {
      const data = {
        chkinId: args.chkinId,
        visitDate: this.bsnDateValueModel,
        grpNo: args.grpNo,
      };

      await this.payLock(data, true);

      this.onPayLockYesNoCancelPopupClose();
    },
    async onPayLockYesNoCancelPopupNoButtonClicked() {
      const data = {
        chkinId: null,
        visitDate: this.bsnDateValueModel,
        grpNo: null,
      };

      await this.payLock(data, true);

      this.onPayLockYesNoCancelPopupClose();
    },
    // 동반자 저장하기 위한 기본 데이타 갖고 오는 api
    async getCompanionMember(resveId) {
      const data = await GolfErpAPI.fetchCompanionMember(resveId);
      return  data;
    },
    openPartnerCertificationPopup() {
      this.isPartnerCertificationPopupOpen = true;
    },
    onPartnerCertificationPopupClose() {
      this.isPartnerCertificationPopupOpen = false;
    },
    openNoFrontAndCartSalesSlipPopup() {
      this.isNoFrontAndCartSalesSlipPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.noFrontAndCartSalesSlipPopup.showPopup({
          bsnDate: this.bsnDateValueModel,
          gridDataSource: this.paymentVisitStatements.map(item => {
            item.startTime = item.startTime.substring(0, 2) + ':' + item.startTime.substring(2);

            return item;
          }) || []
        });
      });
    },
    onNoFrontAndCartSalesSlipPopupClose() {
      this.isNoFrontAndCartSalesSlipPopupOpen = false;
    },
    onLockerUseVisitorPopupClose() {
      this.isLockerUseVisitorPopupOpen = false;
    },
    onGridFilterCheckChange() {
      let filterValue = [
        {
          flag: "rainCancelFlag",
          value: "RAIN",
        },
        {
          flag: "resveFlag",
          value: "RESVE",
        },
        {
          flag: "checkinFlag",
          value: "CHKIN",
        },
        {
          flag: "payingFlag",
          value: "PAYING",
        },
        {
          flag: "payCompleteFlag",
          value: "CHKOUT",
        },
      ];

      filterValue = filterValue
          .filter((item) => this.gridFilterCheck[item.flag])
          .map((item) => item.value);

      // 조회할 때 깜빡이는 문제(?)와 필터 유지를 위해 grid filter가 아닌 복사된 배열에서 filter 처리
      this.checkinTimeList = this.pureCheckinTimeList.filter((item) =>
          filterValue.includes(item.chkinStatus)
      );
      if(this.visit5Flag) {
        this.$refs.checkinTimeGrid.showColumns(['내장객5']);
      } else {
        this.$refs.checkinTimeGrid.hideColumns(['내장객5']);
      }
    },
    focusSearchValue() {
      setTimeout(() => {
        this.$refs["input-visit-name"].focus();
      }, 500);
    },
    onWindowKeypress(event) {
      if (
          this.activeDialog ||
          this.isPopupOpened ||
          (!!this.$refs.checkinLayer &&
              this.$refs.checkinLayer.getIsPopupOpened())
      ) {
        return;
      }

      if (
          keypressEventEqualsShortcut(event, SHORTCUT_FOCUS_VISIT_NAME.shortcut)
      ) {
        setTimeout(() => {
          this.$refs["input-visit-name"].allFocus();
        }, 100);
        return;
      }

      let cellIndex = null;
      if (keypressEventEqualsShortcut(event, SHORTCUT_CHECKIN.shortcut)) {
        cellIndex = GRID_COLUMN_CHECKIN_ICON_IDX;
      } else if (
          keypressEventEqualsShortcut(event, SHORTCUT_CHECKOUT.shortcut)
      ) {
        cellIndex = GRID_COLUMN_CHECKOUT_ICON_IDX;
      } else {
        return;
      }

      const task = () => {
        const selectedRecords = this.$refs.checkinTimeGrid.getSelectedRecords();
        if (selectedRecords.length === 0) {
          this.errorToast("타임을 선택해주세요.");
        } else if (selectedRecords.length === 1) {
          this.openCheckinLayer(selectedRecords[0], cellIndex);
        } else {
          this.errorToast("타임을 하나만 선택해주세요.");
        }
      };

      if (this.isTeamAndVisitInfoChanged()) {
        this.confirm("수정 내역이 있습니다. 진행하시겠습니까?").then(
            (result) => {
              if (result) {
                task();
              }
            }
        );
      } else {
        task();
      }
    },
    onClickExcel() {
      this.$refs.checkinTimeGrid.excelExport();
    },
    memoConfirm(args) {
      if (this.checkinTimeList.length > 0) {
        for (let i = 0, j = this.checkinTimeList.length; i<j; i++) {
          if (this.checkinTimeList[i].resveId === args.resveId) {
            this.checkinTimeList[i].newMemoFlag = args.newMemoFlag;
            this.checkinTimeList[i].memoFlag = args.memoViews.length >= 1;
            break;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
body .appContent .lookup-condition .field.course .content .item,body .appContent .lookup-condition .field.partDivision .content .item,body .appContent .lookup-condition .field.reservationName .content .item.input{width:90px}
body .appContent .lookup-condition .field.lockerNumber .content .item {width:60px}
body .appContent .lookup-condition .item .button li.search .e-btn {width: 32px;}
body .appContent .body-article .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-01 .header-right{float:left;width:100%}
body .appContent .content-body .body-article .section-header .header-button li >>> .e-btn {height: 29px;}
body .appContent .content-body .body-article .section-header .header-button li >>> .e-btn:not(.dev-unlock-area) {background-position: left -81px;}
body .appContent .content-body .body-article .section-header .header-button li.keyColor >>> .e-btn {background-position: left 4px;}
body .appContent .content-body.dev-front-checkin-thumbnail .body-article{height:calc(100% - 174px)}
body .appContent .content-body.dev-front-checkin-thumbnail >>> .layer .content-body{display:none}
body .appContent .content-body.dev-front-checkin-content >>> .layer{height:calc(100% - 23px)}
body .appContent .content-body.dev-front-checkin-content >>> .layer .content-blind-area{width: 100%;height: 19px;position: absolute;top: -19px;background-color: #f9f9f9;}
body .appContent .content-body.dev-front-checkin-content >>> .layer .content-button .button li.arrow .e-btn{background-position:center -23px}
body .appContent .content-body.dev-front-checkin-content >>> .layer .content-thumbnail{display:none}
body .appContent .article-section.section-01 .body-grid >>> .dev-income-deduction {background: url(../../assets/images/common/income_front_visit.png) no-repeat right bottom;}
body .section-body >>> .mandate {background: url("../../assets/images/common/mandate_icon.png") no-repeat  ; width: 24px; height: 15px; position: absolute; right: 3px; top: 0; bottom: 0; margin: auto}
body .body-article .section-header .header-button .print >>> .e-btn { background-position:left -81px !important; }
body .body-article .section-header .header-button .dev-unlock-area >>> .e-btn {background-image: url('../../assets/images/common/unlock.png'); background-position: 6px !important;}
</style>