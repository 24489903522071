<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="memberVisitStatusPopup"
      header="회원내장현황"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="1510"
      :height="500"
      :isModal="true"
      @close="onMemberVisitStatusPopupClosed"
    >
      <div class="window takeCompanionMember">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">
                      내장기간 : {{ searchOptions.visitDateFrom + "~" + searchOptions.visitDateTo }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      회원명 : {{ searchOptions.memberName }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      연락처 : {{ getFormattedPhoneNumber(this.memberInfoList[0].phoneNumber) }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      주소 : {{ this.memberInfoList[0].homeAddress + " " + this.memberInfoList[0].homeAdditionalAddress }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      회원번호 : {{ memberNoFormatter(searchOptions.memberNo) }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      비고 : {{ this.memberInfoList[0].remarks }}
                    </div>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="searchButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        회원내장현황
                      </div>
                      <div class="header-caption">
                        [{{ numberWithCommas(memberVisitStatusLength) }}건]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="memberVisitStatusGrid"
                        v-bind="memberVisitStatusGridOptions"
                        :dataSource="memberVisitStatusList"
                        @queryCellInfo="memberVisitStatusGridQueryCellInfo"
                        @actionComplete="memberVisitStatusGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import EjsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import {Aggregate, ExcelExport, Filter, ForeignKey, Group, Page, Resize} from "@syncfusion/ej2-vue-grids";

import {numberWithCommas} from '@/utils/number';
import {memberNoFormatter} from "@/utils/formatter";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getFormattedDate} from "@/utils/date";
import {commonCodesGetColorValue} from "@/utils/commonCodes";
import {getFormattedPhoneNumber} from "../../../utils/string";

export default {
  name: 'MemberVisitStatusPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton
  },
  data() {
    return {
      grid: [Resize, ForeignKey],
      memberVisitStatusGridOptions: {
        provides: [
          Aggregate,
          Filter,
          Resize,
          Page,
          Group,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        columns: [
          {
            field: "timeId",
            visible: false,
            isPrimaryKey: true,
          },
          {
            headerText: "예약정보",
            columns: [
              {
                field: "resveDateByMMDD",
                headerText: "일자",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
              },
              {
                field: "dwCode",
                headerText: "요일",
                type: "string",
                minWidth: 16,
                width: 70,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "DW_CODE",
              },
              {
                field: "bsnCode",
                headerText: "영업",
                type: "string",
                minWidth: 16,
                width: 70,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "BSN_CODE",
              },
              {
                field: "resveKind",
                headerText: "예약종류",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "RESVE_KIND",
              },
              {
                field: "resveTime",
                headerText: "시간",
                type: "string",
                minWidth: 16,
                width: 70,
                textAlign: "center",
              },
              {
                field: "resveCourse",
                headerText: "코스",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "COURSE_CODE",
              },
              {
                field: "resveChannel",
                headerText: "예약채널",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "RESVE_CHANNEL",
              },
              {
                field: "grpName",
                headerText: "단체명",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "resveName",
                headerText: "예약자명",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
              },
              {
                field: "memberNo",
                headerText: "회원번호",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                valueAccessor: (field, data) =>
                  gridUtilGetMemberNoColumnAccess(field, data),
              },
              {
                field: "memberDiv",
                headerText: "회원구분",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "MEMBER_DIV",
              },
              {
                field: "memberGrade",
                headerText: "회원등급",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "MEMBER_GRADE",
              },
            ]
          },
          {
            headerText: "내장객",
            columns: [
              {
                field: "visitName1",
                headerText: "1",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName2",
                headerText: "2",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName3",
                headerText: "3",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName4",
                headerText: "4",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName5",
                headerText: "5",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
            ]
          },
        ],
      },
      memberVisitStatusList: [],
      memberInfoList: [],
      memberVisitStatusLength: 0,
      searchOptions: {
        visitDateFrom: null,
        visitDateTo: null,
        memberName: null,
        memberNo: null,
        membershipId: null,
      }
    };
  },
  computed: {},
  methods: {
    getFormattedPhoneNumber,
    numberWithCommas,
    memberNoFormatter,
    showPopup(args) {
      this.searchOptions.visitDateFrom = getFormattedDate(args.visitDate, 'YYYY-MM') + "-01";
      this.searchOptions.visitDateTo = args.visitDate;
      this.searchOptions.memberName = args.visitName;
      this.searchOptions.memberNo = args.memberNo;
      this.searchOptions.membershipId = args.membershipId;

      this.searchButtonClicked();
    },
    onMemberVisitStatusPopupClosed() {
      this.$emit("popupClosed");
    },
    onCloseClick() {
      this.$refs.memberVisitStatusPopup.hide();
    },
    async searchButtonClicked() {
      const data = await GolfErpAPI.fetchMemberVisitStatus({
        membershipId: this.searchOptions.membershipId,
        bsnYear: getFormattedDate(this.searchOptions.visitDateFrom, "YYYY"),
        dateFrom: this.searchOptions.visitDateFrom,
        dateTo: this.searchOptions.visitDateTo,
      });

      this.memberVisitStatusList = data.monthlyStatusList.map(item => ({
        ...item,
        resveDateByMMDD: getFormattedDate(item.resveDate, "MM-DD"),
        resveDateByYYYYMM: getFormattedDate(item.resveDate, "YYYYMM"),
        resveKind: item.reservationConfirm?.reservationMember?.resveKind,
        resveChannel: item.reservationConfirm?.reservationMember?.resveChannel,
        grpName: item.reservationConfirm?.reservationMember?.grpName,
        resveName: item.reservationConfirm?.reservationMember?.resveName,
        memberNo: item.reservationConfirm?.reservationMember?.memberNo,
        memberDiv: item.reservationConfirm?.reservationMember?.memberDiv,
        memberGrade: item.reservationConfirm?.reservationMember?.memberGrade,
        visitName1: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 1)[0]?.name,
        visitName2: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 2)[0]?.name,
        visitName3: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 3)[0]?.name,
        visitName4: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 4)[0]?.name,
        visitName5: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 5)[0]?.name,
        feeSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.feeSalesTotAmt,
        rentSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.rentSalesTotAmt,
        foodSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.foodSalesTotAmt,
        shopSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.shopSalesTotAmt,
        etcSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.etcSalesTotAmt,
        salesTotAmt: item.reservationConfirm?.salesAmountByResveId?.salesTotAmt,
      }));

      this.memberInfoList = data.memberInfos.map(item => ({
        ...item,
        phoneNumber: item.member?.phoneNumber ? item.member?.phoneNumber : "" ,
        homeAddress: item.member?.details?.homeAddress ? item.member?.details?.homeAddress : "",
        homeAdditionalAddress: item.member?.details?.homeAddr2 ? item.member?.details?.homeAddr2 : "",
        remarks: item.member?.details?.remarks ? item.member?.details?.remarks : "",
      }));
    },
    memberVisitStatusGridQueryCellInfo(args) {
      const {
        column: {
          field
        },
        cell,
        data,
      } = args;

      if (field && field.endsWith("TotAmt")) {
        if (data[field] === 0) {
          cell.innerText = "-";
        }
      }

      if (field === "dwCode") {
        cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
      } else if (field === "resveKind") {
        cell.style.color = commonCodesGetColorValue("RESVE_KIND", data.resveKind);
      }
    },
    memberVisitStatusGridActionComplete(args) {
      const {
        requestType
      } = args;

      if (["refresh", "filtering"].includes(requestType)) {
        this.memberVisitStatusLength = args?.rows?.length || 0;
      }
    },
  },
};
</script>
