<template>
  <div>
    <ejs-dialog
      header="카트할인"
      width="1180"
      height="800"
      ref="dialog"
      :animationSettings="{ effect: 'None' }"
      v-bind="dialogProps"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">팀/단체</div>
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="radio"
                                v-model="teamGroupDiv"
                                value="T"
                                @change="onTeamGroupDivChange"
                              />
                              <i></i>
                              <div class="label">팀</div>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input
                                type="radio"
                                v-model="teamGroupDiv"
                                value="G"
                                @change="onTeamGroupDivChange"
                              />
                              <i></i>
                              <div class="label">단체</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        할인대상 ({{ discountTargetText }})
                      </div>
                      <div class="header-caption">
                        <span>시간 : {{ startTime || "" }}</span>
                        <span>/ 코스 : {{ startCourseName || "" }}</span>
                        <span v-if="!!grpName">/ 단체명: {{ grpName }}</span>
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <div class="title">할인율</div>
                          <ul class="content">
                            <li class="item">
                              <input-number
                                v-model="rateValueModel"
                                :min="0"
                                :max="100"
                                :propMaxLength="3"
                                style="width: 80px"
                                autocapitalize="off"
                              />
                            </li>
                            <li class="item text">%</li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <li class="apply keyColor">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onApplyDiscountRateClick"
                          >
                            할인율 적용
                          </erp-button>
                        </li>
                        <li class="reset">
                          <erp-button
                              button-div="GET"
                              :is-icon-custom="true"
                              @click.native="onResetClick"
                          >
                            초기화
                          </erp-button>
                        </li>
                        <li>
                          <ejs-dropdownbutton
                            :items="cartPaymtItems"
                            :select="onCartPaymtDropDownButtonSelect"
                            cssClass="lookup-button-dropdown"
                          >
                            카트비분할
                          </ejs-dropdownbutton>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="section-body"
                    style="border: none; overflow: hidden"
                  >
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        @cellSave="onCellSave"
                        @queryCellInfo="onQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  @click.native="onConfirmClick"
                  :is-icon-custom="true"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { v4 as createUUID } from "uuid";
import {
  getCartDiscountList,
  putCartDiscountList,
} from "@/api/frontManagement";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  Aggregate,
  Edit,
  ForeignKey,
  Group,
  Resize,
} from "@syncfusion/ej2-vue-grids";
import {commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import InputNumber from "@/components/common/InputNumber";

export default {
  name: "CartDiscountPopup",
  mixins: [commonMixin],
  components: { InputNumber, EjsGridWrapper, ErpButton },
  props: {
    startCourse: String,
    startTime: String,
    grpName: String,
  },
  data() {
    return {
      bsnDate: "",
      chkinId: "",
      grpNo: "",
      teamGroupDiv: "T",

      cartDcList: [],

      rate: 0,

      cartPaymtItems: [],
    };
  },
  created() {
    this.cartPaymtItems = commonCodesGetCommonCode("CART_PAYMT")
      .map(code => {
        return {
          text: code.comName,
          value: code.comCode,
        };
      });
  },
  computed: {
    rateValueModel: {
      get() {
        return this.rate * 100;
      },
      set(value) {
        this.rate = value / 100;
      },
    },

    dialogProps() {
      return {
        allowDragging: true,
        isModal: true,
        animationSettings: { effect: "None" },
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };
    },
    startCourseName() {
      return this.startCourse
        ? commonCodesGetComName("COURSE_CODE", this.startCourse)
        : null;
    },
    discountTargetText() {
      switch (this.teamGroupDiv) {
        case "T":
          return "팀";
        case "G":
          return "그룹";
      }

      return "?";
    },
    gridProps() {
      return {
        allowFiltering: false,
        allowGrouping: true,
        allowSorting: false,
        columns: [
          {
            allowEditing: false,
            field: "startTime",
            headerText: "시간",
            minWidth: 16,
            textAlign: "Center",
            width: 70,
          },
          {
            field: "chkinId",
            visible: false,
          },
          {
            field: "visitId",
            visible: false,
          },
          {
            field: "salesId",
            visible: false,
          },
          {
            field: "slipId",
            visible: false,
          },
          {
            field: "grpNo",
            visible: false,
          },
          {
            field: "grpName",
            visible: false,
          },
          {
            field: "visitSno",
            visible: false,
          },
          {
            field: "slipNo",
            visible: false,
          },
          {
            field: "saleAmt",
            visible: false,
          },
          {
            field: "totAmt",
            visible: false,
          },
          {
            field: "payFlag",
            visible: false,
          },
          {
            field: "optFlag",
            visible: false,
          },
          {
            field: "chkoutFlag",
            visible: false,
          },
          {
            field: "teamChkoutFlag",
            visible: false,
          },
          {
            field: "_rid",
            isPrimaryKey: true,
            visible: false,
          },
          {
            allowEditing: false,
            groupCode: "COURSE_CODE",
            field: "startCourse",
            isCommonCodeField: true,
            headerText: "코스",
            minWidth: 16,
            textAlign: "Center",
            width: 80,
          },
          {
            allowEditing: false,
            field: "visitName",
            headerText: "내장객",
            minWidth: 16,
            textAlign: "Center",
            width: 110,
          },
          {
            allowEditing: false,
            field: "lockerNo",
            headerText: "락카",
            minWidth: 16,
            textAlign: "Center",
            width: 70,
          },
          {
            allowEditing: false,
            field: "productName",
            headerText: "상품명",
            minWidth: 16,
            textAlign: "Left",
          },
          {
            allowEditing: false,
            field: "saleQty",
            headerText: "수량",
            minWidth: 16,
            textAlign: "Center",
            width: 70,
          },
          {
            allowEditing: false,
            field: "_cartSaleAmount",
            headerText: "카트금액",
            minWidth: 16,
            textAlign: "Right",
            valueAccessor: (field, data) =>
              data && data[field] ? data[field].toLocaleString() : 0,
            width: 110,
          },
          {
            allowEditing: true,
            field: "dcAmt",
            headerText: "할인금액",
            textAlign: "Right",
            isNumericType: true,
            type: "number",
            width: 110,
          },
          {
            allowEditing: false,
            field: "_totalAmount",
            headerText: "적용금액",
            minWidth: 16,
            textAlign: "Right",
            valueAccessor: (field, data) =>
              data && data[field] ? data[field].toLocaleString() : 0,
            width: 110,
          },
          {
            allowEditing: true,
            field: "remarks",
            headerText: "할인내용",
            minWidth: 16,
            textAlign: "Left",
            type: "string"
          },
          {
            allowEditing: false,
            field: "status",
            headerText: "정산",
            minWidth: 16,
            width: 50,
          },
        ],
        dataSource: !this.cartDcList
          ? []
          : this.cartDcList.map((discount) => ({
              ...discount,
              remarks: discount.remarks || "",
              _rid: createUUID(),
              _cartSaleAmount: discount.saleAmt,
              _totalAmount: discount.saleAmt - discount.dcAmt,
            })),
        groupSettings: {
          columns: ["chkinId"],
          showDropArea: false,
        },
        aggregates: [
          {
            columns: [
              {
                field: "saleQty",
                aggregationType: "GroupCaption",
                customAggregate: "소계",
              },
              {
                field: "_cartSaleAmount",
                aggregationType: "GroupSum",
              },
              {
                field: "dcAmt",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  const gridData = this.$refs.grid.getBatchCurrentViewRecords();
                  const sum = gridData
                    .map((visit) => visit.chkinId === data.key ? visit.dcAmt : 0)
                    .reduce((acc, cur) => cur + acc, 0);

                  return sum;
                }
              },
              {
                field: "_totalAmount",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  const gridData = this.$refs.grid.getBatchCurrentViewRecords();
                  const sum = gridData
                    .map((visit) => visit.chkinId === data.key ? visit._totalAmount : 0)
                    .reduce((acc, cur) => cur + acc, 0);

                  return sum;
                }
              }

            ]
          }
        ],
        provides: [Aggregate, Edit, ForeignKey, Group, Resize],
      };
    },
  },
  methods: {
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hidePopup();
    },
    async onTeamGroupDivChange() {
      await this.fetchCartDiscountList();
    },
    onCellSave(args) {
      const {
        columnName,
        rowData: { _rid, dcAmt, payFlag, remarks, _cartSaleAmount },
        value
      } = args;

      if (columnName === "dcAmt") {
        const valueAmt = parseInt(value.toString().replace(/,/gi, ""));
        const available = !(
          _cartSaleAmount < valueAmt ||
          Boolean(payFlag)
        );
        args.value = available ? valueAmt : dcAmt;
        if (available) {
          const gridRef = this.$refs["grid"];
          const targetRowIndex = gridRef
            .getBatchCurrentViewRecords()
            .findIndex(({ _rid: __rid }) => __rid === _rid);
          if (!(targetRowIndex < 0)) {
            gridRef.updateCell(
              targetRowIndex,
              "_totalAmount",
              _cartSaleAmount - valueAmt
            );
          }
        }
      } else if (columnName === "remarks") {
        args.value = payFlag ? remarks : value;
      }
    },
    onQueryCellInfo(args) {
      const {
        cell,
        column: { field },
        data: { payFlag },
      } = args;

      if (field === "status") {
        if (payFlag) {
          cell.style.backgroundColor = "#263684";
        }
      }
    },
    onApplyDiscountRateClick() {
      const gridRef = this.$refs["grid"];
      const batchCurrentViewRecords = gridRef.getBatchCurrentViewRecords();
      batchCurrentViewRecords
        .filter(({ payFlag }) => !payFlag)
        .forEach((record) => {
          const targetRowIndex = batchCurrentViewRecords.findIndex(
            ({ _rid }) => _rid === record._rid
          );
          if (targetRowIndex < 0) {
            return;
          }

          const cartTotAmt = batchCurrentViewRecords
            .map((record) => record._cartSaleAmount)
            .reduce((acc, cur) => acc + cur);

          const discountAmount = Math.round((cartTotAmt * this.rate) / 100) * 100;

          const dcAmt = Math.trunc((discountAmount / batchCurrentViewRecords.length) / 100) * 100;
          const firstDcAmt = dcAmt + (discountAmount - (dcAmt * batchCurrentViewRecords.length));

          gridRef.updateCell(targetRowIndex, "dcAmt", targetRowIndex === 0 ? firstDcAmt : dcAmt);
          gridRef.updateCell(
            targetRowIndex,
            "_totalAmount",
            record._cartSaleAmount - (targetRowIndex === 0 ? firstDcAmt : dcAmt)
          );
          gridRef.setCellValue(record._rid, "dcAmt", targetRowIndex === 0 ? firstDcAmt : dcAmt);
        });
        // .map((record) => ({
        //   _rid: record._rid,
        //   orgTotalAmount: record._cartSaleAmount,
        //   dcAmt: Math.round(record._cartSaleAmount * this.rate),
        //   _totalAmount: Math.round(
        //     record._cartSaleAmount - record._cartSaleAmount * this.rate
        //   ),
        // }))
        // .forEach((record) => {
        //   const targetRowIndex = batchCurrentViewRecords.findIndex(
        //     ({ _rid }) => _rid === record._rid
        //   );
        //   if (targetRowIndex < 0) {
        //     return;
        //   }
        //
        //   console.log('record.===>', record);
        //
        //   gridRef.updateCell(targetRowIndex, "dcAmt", record.dcAmt);
        //   gridRef.updateCell(
        //     targetRowIndex,
        //     "_totalAmount",
        //     record._totalAmount
        //   );
        //   gridRef.setCellValue(record._rid, "dcAmt", record.dcAmt);
        // });
    },
    async onResetClick() {
      await this.fetchCartDiscountList();
    },
    async onConfirmClick() {
      await this.updateCartDiscountList();
      await this.fetchCartDiscountList();
    },
    onCloseClick() {
      this.hidePopup();
    },

    async showPopup(bsnDate, chkinId, grpNo, startCourse) {
      this.bsnDate = bsnDate;
      this.chkinId = chkinId;
      this.grpNo = grpNo;
      this.startCourse = startCourse;

      try {
        await this.fetchCartDiscountList();
        this.$refs["dialog"].show();
      } catch (e) {
        this.$emit("close");
      }
    },
    hidePopup() {
      this.$refs["dialog"].hide();
    },

    async fetchCartDiscountList() {
      const { cartDcList } = await this.getCartDiscountList(
        this.bsnDate,
        this.chkinId,
        this.teamGroupDiv === "G" ? this.grpNo : undefined,
        this.teamGroupDiv
      );

      this.cartDcList = cartDcList;
    },
    async updateCartDiscountList() {
      const gridRef = this.$refs["grid"];
      const { changedRecords = [] } = gridRef.getBatchChanges() || {
        changedRecords: [],
      };

      if (0 < changedRecords.length) {
        await this.putCartDiscountList(
          this.bsnDate,
          this.chkinId,
          this.teamGroupDiv === "G" ? this.grpNo : undefined,
          gridRef
            .getBatchCurrentViewRecords()
            .map(
              ({ slipId, saleAmt, dcAmt, _totalAmount, remarks, optFlag }) => ({
                slipId,
                saleAmt,
                dcAmt,
                totAmt: _totalAmount,
                remarks,
                optFlag,
              })
            )
        );
        this.$emit("submit");
      }

      this.hidePopup();
    },

    async getCartDiscountList(bsnDate, chkinId, grpNo, teamGroupDiv) {
      const {
        value: { cartDcList },
      } = await getCartDiscountList(bsnDate, chkinId, grpNo, teamGroupDiv);

      return { cartDcList };
    },
    async putCartDiscountList(bsnDate, chkinId, grpNo, cartDcList) {
      await putCartDiscountList(bsnDate, chkinId, grpNo, cartDcList);
    },
    onCartPaymtDropDownButtonSelect(args) {
      const {
        item: {
          value
        }
      } = args;

      const gridRef = this.$refs["grid"];
      const batchCurrentViewRecords = gridRef.getBatchCurrentViewRecords();

      const chkinIds = [];

      batchCurrentViewRecords.forEach(record => {
        if (chkinIds.findIndex(data => data === record.chkinId) === -1) {
          chkinIds.push(record.chkinId);
        }
      });

      chkinIds.forEach(chkinId => {
        const filterRecords = batchCurrentViewRecords.filter(record => record.chkinId === chkinId);
        const cartAmt1 = Math.floor((value / filterRecords.length) / 100) * 100;
        const cartAmt2Length = (value - (cartAmt1 * filterRecords.length)) / 100;
        const cartAmt2 = cartAmt2Length > 0 ? (cartAmt1 + (value - (cartAmt1 * filterRecords.length))) : cartAmt1;

        filterRecords.forEach((record, idx) => {
          const rowIndex = (record._rid - 1);
          const totalAmount = idx === 0 ? cartAmt2 : cartAmt1;
          const dcAmt = record.saleAmt - totalAmount;

          gridRef.updateCell(rowIndex, "dcAmt", dcAmt);
          gridRef.updateCell(rowIndex, "_totalAmount", totalAmount);
          gridRef.setCellValue(record._rid, "dcAmt", dcAmt);
        });
      });
    }
  },
};
</script>

<style scoped></style>
