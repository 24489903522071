<template>
  <div>
    <ejs-dialog
      ref="searchCouponDcPopup"
      :header="`쿠폰 선택 팝업`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1520"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onSearchCouponDcPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">쿠폰종류</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          v-model="searchConditions.dcKind"
                          :dataSource="searchOptions.dcKind"
                          :allowFiltering="false"
                          :fields="commonCodeFields"
                          cssClass="lookup-condition-dropdown"
                          :tabindex="-1"
                        />
                      </li>
                      <li class="item">
                        <input-text
                          id="couponNoTextBox"
                          v-model="searchConditions.couponNo"
                          :tabindex="-1"
                          @keydown.enter="onCouponNoKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="useFlag"
                                v-model="searchConditions.useFlag"
                                value="true"
                                :tabindex="-1"
                              />
                              <i></i>
                              <div class="label">사용</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      :tabindex="-1"
                      @click.native="onViewButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="couponListGrid"
                        id="couponListGrid"
                        :allowResizing="true"
                        :dataSource="couponUseSelectList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="couponListGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :isInPopup="true"
                        @queryCellInfo="couponListGridqueryCellInfo"
                        @rowSelected="onCouponListGridRowSelected"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onCouponListGridDoubleClick
                        "
                        @dataBound="onCouponListGridDataBound"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="confirmSearchCouponDcPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeSearchCouponDcPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Aggregate, ExcelExport, Filter, ForeignKey, Group, Resize } from "@syncfusion/ej2-vue-grids";
import { commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName } from "@/utils/commonCodes";
import { getFixedPaymentPrice } from "@/api/common";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import {numberWithCommas} from "@/utils/number";

export default {
  name: "searchCouponDcPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton
  },
  created() {
    this.searchOptions.dcKind = commonCodesGetCommonCode("DC_KIND");
    if (
      this.searchOptions.dcKind.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchOptions.dcKind.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey],
      commonCodeFields: { text: "comName", value: "comCode" },
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      searchConditions: {
        bsnDate: null,
        useFlag: true,
        membershipId: null,
        couponNo: null,
        dcKind: "%",
      },
      searchOptions: {
        dcKind: null,
      },
      visitInfo: {},
      couponUseSelectList: [],
      rowSelectedData: null,
      changeIdx: undefined,
      dcList: [],
      storeCode: null,
      couponListGridColumn: [
        {
          field: "couponName",
          headerText: "쿠폰명",
          width: 150,
        },
        {
          field: "couponNo",
          headerText: "쿠폰번호",
          width: 90,
        },
        {
          field: "storeCode",
          headerText: "사용매장",
          width: 90,
          textAlign: "center",
          comCode: "STORE_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "dcKind",
          headerText: "할인종류",
          width: 90,
          textAlign: "center",
          comCode: "DC_KIND",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "giveFlag",
          headerText: "양도여부",
          width: 90,
          type: "boolean",
          textAlign: "center",
          valueAccessor: (field, data) => {
            if (data[field]) {
              return "가능";
            } else {
              return "불가능";
            }
          },
        },
        {
          field: "dplctDcFlag",
          headerText: "중복할인",
          width: 90,
          type: "boolean",
          textAlign: "center",
          valueAccessor: (field, data) => {
            if (data[field]) {
              return "가능";
            } else {
              return "불가능";
            }
          },
        },
        {
          field: "dcMethod",
          headerText: "할인방법",
          width: 90,
          comCode: "DC_METHOD",
          textAlign: "center",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "dcAmt",
          headerText: "할인금액(율)",
          width: 110,
          textAlign: "right",
          isNumericType: true,
        },
        {
          field: "paymtCode",
          headerText: "요금코드",
          width: 90,
          comCode: "PAYMT_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          width: 90,
          textAlign: "center",
          comCode: "BSN_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "startDate",
          headerText: "시작일자",
          width: 90,
          textAlign: "center",
        },
        {
          field: "expirDate",
          headerText: "만료일자",
          width: 90,
          textAlign: "center",
        },
        {
          field: "useStartTime",
          headerText: "시작시간",
          width: 90,
          textAlign: "center",
        },
        {
          field: "useEndTime",
          headerText: "종료시간",
          width: 90,
          textAlign: "center",
        },
        {
          field: "couponStatus",
          headerText: "쿠폰상태",
          width: 90,
          textAlign: "center",
        },
        {
          field: "memberName",
          headerText: "회원명",
          width: 90,
        },
        {
          field: "membershipId",
          headerText: "회원권ID",
          width: 100,
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          width: 90,
        },
        {
          field: "memberDiv",
          headerText: "회원구분",
          width: 120,
          comCode: "MEMBER_DIV",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "remarks",
          headerText: "비고",
          width: 300,
        },
      ],
    };
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
        if (
          column.comCode === "BSN_CODE" &&
          returnCode === null &&
          code === "%"
        ) {
          returnCode = "전체";
        }
      }
      return returnCode;
    },
    async showSearchCouponDcPopup(popupData) {
      this.changeIdx = popupData.dcIdx;
      this.visitInfo = popupData.visitInfo;
      this.dcList = popupData.dcList;
      this.storeCode = popupData.storeCode;
      this.searchConditions.bsnDate = JSON.parse(
        JSON.stringify(popupData.visitInfo.bsnDate)
      );
      this.searchConditions.membershipId = JSON.parse(
        JSON.stringify(popupData.visitInfo.membershipId)
      );

      await this.onViewButtonClicked();

      this.$refs.searchCouponDcPopup.show();
    },
    onSearchCouponDcPopupClosed() {
      this.$emit("popupClosed");
    },
    closeSearchCouponDcPopup() {
      this.$refs.searchCouponDcPopup.hide();
    },
    async onViewButtonClicked() {
      await this.fetchCouponUseSelect(this.searchConditions);
    },
    async fetchCouponUseSelect(args) {
      const argsCopy = JSON.parse(JSON.stringify(args));
      argsCopy.couponNo =
        !argsCopy.couponNo || argsCopy.couponNo.trim() === "" ? null : argsCopy.couponNo;
      argsCopy.dcKind = argsCopy.dcKind === "%" ? null : argsCopy.dcKind;

      const fetchedCouponUseSelectList = await GolfErpAPI.fetchCouponUseSelect(
        argsCopy
      );
      const couponNoList = this.dcList
        .filter((dcInfo) => dcInfo.dcApplyDiv === "COUPON")
        .map((couponDcInfo) => couponDcInfo.couponNo);

      // 기존에 추가되어 있는 쿠폰은 제외함
      if (couponNoList.length > 0) {
        this.couponUseSelectList = fetchedCouponUseSelectList.filter(
          (couponInfo) => {
            return !couponNoList.includes(couponInfo.couponNo);
          }
        );
      } else {
        this.couponUseSelectList = fetchedCouponUseSelectList;
      }
    },
    couponListGridqueryCellInfo(args) {
      const column = args.column.field;
      const cellData = args.data;

      if (column === "dcAmt") {
        if (cellData.dcMethod === "AMT") {
          args.cell.innerHTML = args.cell.innerHTML + "원";
        } else if (cellData.dcMethod === "RATE") {
          args.cell.innerHTML = args.cell.innerHTML + "%";
        } else if (cellData.dcMethod === "PAYMT") {
          args.cell.innerHTML = "";
        }
      } else if (column === "couponStatus") {
        if (cellData.couponStatus === "정상") {
          args.cell.style.color = "#0000ff";
        } else {
          args.cell.style.color = "#ff0000";
        }
      } else if (column === "bsnCode") {
        args.cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          cellData.bsnCode
        );
      } else if (column === "couponNo") {
        if (cellData.couponStatus === "정상") {
          args.cell.style.color = "#000000";
        } else {
          args.cell.style.color = "#ff0000";
        }
      }
    },
    async onCouponListGridRowSelected(args) {
      let saleAmt = 0;

      if (args.data.dcMethod === "PAYMT") {
        if (args.data.paymtCode !== "NOT") {
          const response = await getFixedPaymentPrice(
            this.visitInfo.bsnDate,
            this.visitInfo.holeDiv,
            args.data.paymtCode,
            null,
            true
          );
          saleAmt = response.value.price;
        }
      }

      this.rowSelectedData = {
        _rid: 0, // v-for 로 사용할 key 값임
        couponId: args.data.couponId,
        applyAmt: args.data.dcMethod === "PAYMT" ? saleAmt : 0, // 요금 코드의 경우, 조회한 요금 값. 그 외의 경우 0(요금 계산 필요)
        dcAmt: args.data.dcAmt,
        dcContents: args.data.couponName + (
          args.data.dcMethod === "RATE" ? "(" + numberWithCommas(args.data.dcAmt) + "%)" :
            args.data.dcMethod === "AMT" ? "(" + numberWithCommas(args.data.dcAmt) + ")" : ""
        ),
        dcUseNo: args.data.couponId,
        dcMethod: args.data.dcMethod,
        paymtCode: args.data.paymtCode,
        dplctDcFlag: args.data.dplctDcFlag,
        couponNo: args.data.couponNo,
      };
    },
    async confirmSearchCouponDcPopup() {
      if (this.rowSelectedData !== null) {
        const args = {
          bsnDate: this.visitInfo.bsnDate,
          couponId: this.rowSelectedData.couponId,
          membershipId: this.visitInfo.membershipId,
          startTime: this.visitInfo.startTime,
          storeCode: this.storeCode,
        };

        await GolfErpAPI.fetchCouponUseCheck(args);

        let useCouponCnt = 0;
        let dplctDcCnt = 0;

        this.dcList.forEach((data) => {
          if (
            data.dcApplyDiv === "COUPON" &&
            data.couponNo === this.rowSelectedData.couponNo
          ) {
            useCouponCnt++;
          }
          if (data.dcApplyDiv === "COUPON" && !data.dplctDcFlag) {
            dplctDcCnt++;
          }
        });

        if (useCouponCnt > 0) {
          this.errorToast("같은 쿠폰을 적용할 수 없습니다");
          return;
        }

        if (!this.rowSelectedData.dplctDcFlag) {
          // "중복확인 불가능" 쿠폰을 선택한 경우
          if (
            this.dcList.length > 1 ||
            (this.dcList.length === 1 && this.changeIdx === undefined)
          ) {
            // 중복확인 불가능 쿠폰을 추가할 수는 없다. 단 기존에 1건이 있을 때 교체는 가능
            this.errorToast("중복하여 할인할 수 없습니다");
            return;
          }
        }

        if (
          dplctDcCnt > 1 ||
          (dplctDcCnt === 1 && this.changeIdx === undefined)
        ) {
          // 기존 할인 목록 중 "중복확인 불가능" 쿠폰이 1건 이상 있는 경우에는 중복확인 불가능 쿠폰을 추가할 수는 없다. 단 기존에 1건이 있을 때 교체는 가능
          this.errorToast("중복하여 할인할 수 없습니다");
          return;
        }

        this.$emit("popupClosed", {
          changeIdx: this.changeIdx,
          rowSelectedData: this.rowSelectedData,
        });
      } else {
        this.$emit("popupClosed");
      }
    },
    async onCouponListGridDoubleClick() {
      await this.confirmSearchCouponDcPopup();
    },
    async onCouponNoKeyDownEnter() {
      await this.onViewButtonClicked();
    },
    onCouponListGridDataBound() {
      if (this.couponUseSelectList.length > 0) {
        this.$refs.couponListGrid.selectRow(0);
      } else {
        document.getElementById("couponNoTextBox").select();
      }
    },
  },
};
</script>
