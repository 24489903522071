<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="lockerUseVisitorPopup"
      :header="'락카 사용 내장객 [' + bsnDate + ']'"
      :allowDragging="true"
      :showCloseIcon="true"
      width="500"
      height="250"
      :position="{ X: 'center', Y: 'center' }"
      :isModal="true"
      :close="onLockerUseVisitorPopupClosed"
    >
      <div class="window rainSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="grid"
                      :provides="gridProvides"
                      :columns="gridColumns"
                      :dataSource="gridDataSource"
                      :isInPopup="true"
                      :selectionSettings="gridSelectionSettings"
                      @onGridDialogDoubleClickedOrEnterKeyed="confirmButtonClicked"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button button-div="GET" @click.native="confirmButtonClicked">
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeLockerUseVisitorPopupPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "LockerUseVisitorPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      gridSelectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
      },
      gridProvides: [Resize, ForeignKey],
      gridColumns: [
        {
          field: 'chkinId',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'startTime',
          headerText: '시간',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: '코스',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'visitName',
          headerText: '내장객',
          width: 120,
          type: 'string',
        },
        {
          field: 'lockerNo',
          headerText: '락카',
          textAlign: 'center',
          width: 70,
          type: 'string',
        }
      ],
      gridDataSource: [],
      bsnDate: null
    };
  },
  methods: {
    showPopup(args) {
      console.log('args.===>', args);
      this.bsnDate = args.bsnDate;
      this.gridDataSource = args.gridDataSource;
    },
    onLockerUseVisitorPopupClosed() {
      this.$emit("popupClosed");
    },
    closeLockerUseVisitorPopupPopup() {
      this.$refs.lockerUseVisitorPopup.hide();
    },
    confirmButtonClicked() {
      const selectRecord = this.$refs.grid.getSelectedRecords();

      this.$emit("popupConfirm", selectRecord[0]?.resveId);
    }
  },
};
</script>
