<template>
  <div>
    <ejs-dialog
      ref="paymentStatementPopup"
      header="지불 내역서"
      :allowDragging="true"
      :showCloseIcon="true"
      width="360"
      height="600"
      :isModal="true"
      :close="onPaymentStatementPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">미리보기</div>
                    </div>
                  </div>
                  <div class="section-body" style="border-top-color: #000">
                    <div class="body-box" style="padding: 18px; color: #000">
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 0 0 8px 0;
                        "
                      >
                        <div
                          style="
                            font-size: 18px;
                            font-weight: bold;
                            text-align: center;
                          "
                        >
                          지 불 내 역
                        </div>
                        <div style="padding-top: 11px">
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: 50%;
                              padding-right: 6px;
                            "
                          >
                            {{ printContents.printDateTime }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(15%);
                              padding-right: 6px;
                            "
                          >
                            락카
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(25%);
                              padding-right: 6px;
                            "
                          >
                            성명
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(36%);
                              padding-right: 6px;
                            "
                          >
                            구분
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(24%);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            금액
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div
                          v-for="data in printContents.paymentStatements"
                          :key="data.idx"
                        >
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(15%);
                              padding-right: 6px;
                            "
                          >
                            {{ data.lockerNo }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(25%);
                              padding-right: 6px;
                            "
                          >
                            {{ data.visitName }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(36%);
                              padding-right: 6px;
                            "
                          >
                            {{ data.division }}
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(24%);
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ data.payAmount }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div
                        style="
                          position: relative;
                          border-bottom: 1px dashed #000;
                          padding: 9px 0 8px 0;
                        "
                      >
                        <div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: left;
                              width: calc(100% - 110px);
                              padding-right: 6px;
                            "
                          >
                            합계
                          </div>
                          <div
                            style="
                              box-sizing: border-box;
                              float: right;
                              width: 75px;
                              padding-left: 6px;
                              text-align: right;
                            "
                          >
                            {{ printContents.totalAmount }}
                          </div>
                          <div style="clear: both; height: 0"></div>
                        </div>
                      </div>
                      <div style="position: relative; padding: 15px 0 0 0">
                        <div>
                          TEL : {{ printContents.telNo }} / FAX :
                          {{ printContents.faxNo }}
                        </div>
                        <div style="padding-top: 11px">
                          계산상의 착오나 의문사항이 있으시면<br />
                          연락주십시오.
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  button-div="PRINT"
                  :is-icon-custom="true"
                  @click.native="print"
              >
                출력
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closePayReceiptPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { commonCodesGetComName } from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import { commonCodesGetBizMstInfo } from "@/utils/commonCodes";
import { getTodayDateTime } from "@/utils/date";
import { getFormattedTelNumber } from "@/utils/string";
import { numberWithCommas } from "@/utils/number";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "paymentStatementPopup",
  mixins: [confirmDialogMixin],
  components:{
    ErpButton
  },
  data() {
    return {
      searchOptions: {
        bsnDate: null,
        chkinId: null,
        grpNo: null,
      },
      paymentStatementData: [],
      printContents: {
        printDateTime: null,
        paymentStatements: [],
        totalAmount: 0,
        telNo: null,
        faxNo: null,
      },
      bizMstInfo: commonCodesGetBizMstInfo(),
    };
  },
  methods: {
    async showPopup(popupData) {
      this.searchOptions.bsnDate = popupData.bsnDate;
      this.searchOptions.chkinId = popupData.chkinId;
      this.searchOptions.grpNo = popupData.grpNo;

      await this.getPaymentStatement();
    },
    onPaymentStatementPopupClosed() {
      this.$emit("popupClosed");
    },
    closePayReceiptPopup() {
      this.$refs.paymentStatementPopup.hide();
    },
    async getPaymentStatement() {
      this.paymentStatementData = await GolfErpAPI.fetchPaymentStatement(
        this.searchOptions
      );

      let paymentStatements = [];
      let totalAmount = 0;

      this.paymentStatementData.forEach((data, idx) => {
        paymentStatements.push({
          idx: idx,
          visitId: data.visitId,
          lockerNo: data.lockerPrtNo ? data.lockerPrtNo : data.lockerNo,
          visitName: data.visitName,
          division:
            data.payDiv === "CARD"
              ? data.purcName
              : commonCodesGetComName("PAY_DIV", data.payDiv),
          payAmount: numberWithCommas(data.payAmt),
        });

        totalAmount += data.payAmt;
      });

      this.printContents.printDateTime = getTodayDateTime();
      this.printContents.paymentStatements = paymentStatements;
      this.printContents.totalAmount = numberWithCommas(totalAmount);
      this.printContents.telNo = getFormattedTelNumber(this.bizMstInfo.telNo);
      this.printContents.faxNo = getFormattedTelNumber(this.bizMstInfo.faxNo);
    },
    async print() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "PAYMENT_STATEMENT", true, this.printContents, config);
    },
  },
};
</script>
