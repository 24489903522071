<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="partnerCertificationPopup"
      header="회원인증[공제조합/협회]"
      :allowDragging="true"
      :showCloseIcon="true"
      width="400"
      height="270"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onPartnerCertificationPopupClosed"
    >
      <div class="window partnerCertificationPopup">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">회원인증[공제조합/협회]</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">인증기관</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <!-- TODO : ejs-dropdownlist는 keydown 이벤트가 발생하지 않아 select 태그 사용. -->
                                      <select
                                        ref="mmbsCrtfcDiv"
                                        v-model="searchConditions.mmbsCrtfcDiv"
                                        @keydown.enter.prevent.self="
                                          onCertificationButtonClicked
                                        "
                                      >
                                        <option
                                          v-for="data in searchOptions.mmbsCrtfcDiv"
                                          :key="data.comCode"
                                          :value="data.comCode"
                                        >
                                          {{ data.comName }}
                                        </option>
                                      </select>
                                      <!--                                      <ejs-dropdownlist-->
                                      <!--                                        ref="mmbsCrtfcDiv"-->
                                      <!--                                        v-model="searchConditions.mmbsCrtfcDiv"-->
                                      <!--                                        :dataSource="searchOptions.mmbsCrtfcDiv"-->
                                      <!--                                        :allowFiltering="false"-->
                                      <!--                                        :fields="commonCodeFields"-->
                                      <!--                                        cssClass="lookup-condition-dropdown"-->
                                      <!--                                        @keydown.enter="onCertificationButtonClicked"-->
                                      <!--                                      />-->
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">성명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="memberName"
                                        v-model="searchConditions.memberName"
                                        @keydown.enter="
                                          onCertificationButtonClicked
                                        "
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field birthDay">
                              <!-- 필수 : required -->
                              <div class="title required">생년월일</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="birthDay"
                                        v-model="searchConditions.birthDay"
                                        :maxlength="6"
                                        @keydown.enter="
                                          onCertificationButtonClicked
                                        "
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    1987.11.15 => 871115
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field bizNo">
                              <!-- 필수 : required -->
                              <div class="title">사업자번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="bizNo"
                                        v-model="searchConditions.bizNo"
                                        :maxlength="10"
                                        @keydown.enter="
                                          onCertificationButtonClicked
                                        "
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    '-' 제외 10자리
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">인증결과</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group text"
                                    :style="{
                                      color:
                                        partnerCertificationCode === null
                                          ? 'rgb(255,0,0)'
                                          : 'rgb(0,0,0)',
                                    }"
                                  >
                                    {{ partnerCertificationMessage }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onCertificationButtonClicked"
              >
                인증
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closePartnerCertificationPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window.partnerCertificationPopup .windowContent .body-article .section-body {overflow: hidden; border: none;}

body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field {width: calc(100% + 1px);}

body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field.birthDay .content .item {border-right: 1px solid #e0e0e0;}
body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field.birthDay .content .item.input {width: calc(100% - 130px);}
body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field.birthDay .content .item.text {width: 130px; border-right: 0px;}

body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field.bizNo .content .item {border-right: 1px solid #e0e0e0;}
body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field.bizNo .content .item.input {width: calc(100% - 130px);}
body .window.partnerCertificationPopup .windowContent .article-section.section-01 .body-data .field.bizNo .content .item.text {width: 130px; border-right: 0px;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { validateFormRefs, ValidType } from "@/utils/formUtil";

import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputText from "@/components/common/text/InputText";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "partnerCertificationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    ErpButton,
  },
  data() {
    return {
      commonCodeFields: { text: "comName", value: "comCode" },
      searchConditions: {
        mmbsCrtfcDiv: commonCodeGetComCodeDefaultValue("MMBS_CRTFC_DIV"),
        memberName: null,
        birthDay: null,
        bizNo: null,
      },
      searchOptions: {
        mmbsCrtfcDiv: commonCodesGetCommonCode("MMBS_CRTFC_DIV"),
      },
      partnerCertificationCode: null,
      partnerCertificationMessage: null,
      partnerCertificationValidationRules: {
        mmbsCrtfcDiv: {
          required: true,
        },
        memberName: {
          required: true,
        },
        birthDay: {
          required: true,
          minLength: 6,
          type: ValidType.NUMBER,
        },
        bizNo: {
          type: ValidType.NUMBER,
          custom: {
            method: (args) => {
              if (
                args !== null &&
                args !== undefined &&
                args.length > 0 &&
                args.length < 10
              ) {
                return false;
              }

              return true;
            },
            message: "main.validationMessage.minLengthMessage",
            messageParams: ["10"],
          },
        },
      },
      popupCloseFlag: false,
      rowIndex: -1,
    };
  },
  methods: {
    validateFormRefs,
    showPartnerCertificationPopup(popupData) {
      this.searchConditions.memberName = popupData.memberName;
      this.searchConditions.birthDay = popupData.birthDay
        ? popupData.birthDay.replace(/-/gi, "").substring(2)
        : null;
      this.popupCloseFlag = popupData.popupCloseFlag;
      this.rowIndex = popupData.rowIndex;
    },
    onPartnerCertificationPopupClosed() {
      this.$emit("popupClosed", { rowIndex: this.rowIndex, code: null });
    },
    closePartnerCertificationPopup() {
      this.$refs.partnerCertificationPopup.hide();
    },
    async onCertificationButtonClicked() {
      if (!this.validateFormRefs(this.partnerCertificationValidationRules)) {
        return;
      }

      const args = {
        div: this.searchConditions.mmbsCrtfcDiv,
        memberName: this.searchConditions.memberName,
        birthDay: this.searchConditions.birthDay,
        businessNumber: this.searchConditions.bizNo,
      };

      await this.fetchPartnerCertification(args);
    },
    async fetchPartnerCertification(args) {
      const data = await GolfErpAPI.fetchPartnerCertification(args);

      this.partnerCertificationMessage = data.message;
      this.partnerCertificationCode = data.code;

      if (this.popupCloseFlag) {
        if (data.code !== null) {
          await this.alert(
            "◈" +
              commonCodesGetComName(
                "MMBS_CRTFC_DIV",
                this.searchConditions.mmbsCrtfcDiv
              ) +
              "◈<br><br>☞" +
              this.partnerCertificationMessage +
              "<br><br>인증 화면을 정상 종료합니다."
          );
          this.$emit("popupClosed", {
            rowIndex: this.rowIndex,
            code: this.partnerCertificationCode,
          });
        }
      }
    },
  },
};
</script>
