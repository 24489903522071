<template>
  <div>
    <ejs-dialog
      ref="openReceiptPopup"
      :header="`오픈 영수증 발행`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      width="563"
      :animationSettings="animationSettings"
      :close="onOpenReceiptPopupClosed"
    >
      <div class="window openReceipt">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">오픈 영수증 발행 목록</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="openReceiptGrid"
                      :dataSource="openReceiptList"
                      v-bind="openReceiptGridOptions"
                      @queryCellInfo="queryCellInfo"
                      @headerCellInfo="headerCellInfo"
                      @cellSave="cellSave"
                    />
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">발행일자/성명입력</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">발행일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date
                                        id="issueDate"
                                        name="issueDate"
                                        ref="issueDate"
                                        format="YYYY-MM-DD"
                                        v-model="openReceiptInfo.issueDate"
                                        type="body-data"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">성명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        id="visitName"
                                        name="visitName"
                                        ref="visitName"
                                        v-model="openReceiptInfo.visitName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="print keyColor">
              <erp-button
                  button-div="PRINT"
                  :is-icon-custom="true"
                  @click.native="print"
              >
                출력
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeOpenReceiptPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import {
  commonCodesGetCommonCode,
  commonCodesGetBizMstInfo,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { formPreventEnterEvent } from "@/utils/formUtil";
import { Edit, Selection } from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDate from "@/components/common/datetime/InputDate";
import ipBillPrinterMixin from "../../../mixins/ipBillPrinterMixin";
import BillPrintUtil from "@/utils/billPrintUtil";
import { validateFormRefs } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "OpenReceiptPopup",
  components: { InputText, InputDate, EjsGridWrapper,ErpButton },
  mixins: [confirmDialogMixin,ipBillPrinterMixin],
  data() {
    return {
      openReceiptList: commonCodesGetCommonCode("STORE_GROUP").map(
        (store, idx) => {
          return { no: idx + 1, comName: store.comName, amount: 0 };
        }
      ),
      openReceiptInfo: {
        visitId: null,
        issueDate: getFormattedDate(new Date()),
        visitName: null,
      },
      animationSettings: { effect: "None" },
      bizMstInfo: commonCodesGetBizMstInfo(),
      validateRefList: {
        issueDate: { required: true },
        visitName: { required: true },
      },
    };
  },
  computed: {
    openReceiptGridOptions() {
      return {
        provides: [Edit, Selection],
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
        },
        selectionSettings: { type: "Multiple" },
        columns: [
          {
            field: "comName",
            headerText: "영업장",
            allowEditing: false,
            type: "string",
            isPrimaryKey: true,
            minWidth: 16,
            textAlign: "center",
          },
          {
            field: "amount",
            headerText: "금액",
            allowEditing: true,
            type: "string",
            minWidth: 16,
            textAlign: "right",
            isNumericType: true,
          },
        ],
      };
    },
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    queryCellInfo(args) {
      if (args.column.field === "amount") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column.field === "amount" && !args.data.amount) {
        args.cell.innerText = 0;
      }
    },
    headerCellInfo(args) {
      if (args.cell.column.field === "amount") {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    cellSave(args) {
      if (args.columnName === "amount" && !args.value) {
        args.value = 0;
      }
    },
    showOpenReceiptPopup(popupData) {
      this.openReceiptInfo.visitId = popupData.visitId;
      this.openReceiptInfo.visitName = popupData.visitName;
    },
    onOpenReceiptPopupClosed() {
      this.$emit("popupClosed");
    },
    closeOpenReceiptPopup() {
      this.$refs.openReceiptPopup.hide();
    },
    async print() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      if (this.validate()) {
        const currentViewRecords = this.$refs.openReceiptGrid.getBatchCurrentViewRecords();

        let returnRecords = [];
        let totPayAmt = 0;

        for (let i = 0; i < currentViewRecords.length; i++) {
          let returnData = null;
          if (currentViewRecords[i].amount !== 0) {
            returnData = {
              storeName: currentViewRecords[i].comName,
              payAmt: currentViewRecords[i].amount,
            };

            totPayAmt += currentViewRecords[i].amount;

            returnRecords.push(returnData);
          }
        }

        if (returnRecords.length === 0) {
          this.errorToast(
            this.$t("billPrinter.validationMessage.printContentAmountNotExist")
          );
          return;
        }


        const printSetData = {
          visitDate: this.openReceiptInfo.issueDate,
          visitName: this.openReceiptInfo.visitName,
          storeAmtList: returnRecords,
          totPayAmt: totPayAmt,
          bizName: this.bizMstInfo.bizName,
          bizNo: this.bizMstInfo.bizNo,
          rprsntvName: this.bizMstInfo.rprsntvName,
          telNo: this.bizMstInfo.telNo,
          faxNo: this.bizMstInfo.faxNo,
        };
        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, "FREE_BILL" , true , printSetData , config);

      }
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>

<style scoped></style>
