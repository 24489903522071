<template>
  <div>
    <ejs-dialog
      ref="visitsCopyPopup"
      :header="`내장객 복사`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1247"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onVisitsCopyPopupClosed"
    >
      <div class="window visitorCopy">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">영업일자</div>
                    <ul class="content">
                      <li class="item date">
                        <input-date
                          v-model="bsnDate"
                          type="lookup-condition"
                          :notClear="true"
                          @change="onBsnDateChanged"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field reservationName">
                    <div class="title">예약자/내장객명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          v-model="resveName"
                          @keydown.enter="getVisitTeamList"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="getVisitTeamList"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="visitTeamListGrid"
                        :allowResizing="true"
                        :dataSource="visitTeamList"
                        :selectionSettings="selectionSettings"
                        :columns="visitTeamListGridColumn"
                        :provides="grid"
                        :isInPopup="true"
                        :aggregates="visitTeamListGridAggregates"
                        @headerCellInfo="visitTeamListGridHeaderCellInfo"
                        @queryCellInfo="visitTeamListGridQueryCellInfo"
                        @gridCheckboxChanged="onVisitTeamListGridCheckboxChanged"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="confirmVisitsCopyPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeVisitsCopyPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  Edit,
  Resize,
  ForeignKey,
  Aggregate,
  Filter,
  Group,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";

import { commonCodesGetComName } from "@/utils/commonCodes";

import { getVisitCopy } from "@/api/frontManagementCheckin";
import InputText from "@/components/common/text/InputText";
import InputDate from "@/components/common/datetime/InputDate";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {gridUtilGetMemberNoColumnAccess} from "@/utils/gridUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "visitsCopyPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputDate,
    EjsGridWrapper,
    ErpButton
  },
  data() {
    return {
      grid: [Edit, Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
      },
      bsnDate: null,
      resveName: null,
      visitTeamList: [],
      visitTeamListGridColumn: [
        {
          field: "chkinId",
          allowEditing: false,
          type: "string",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "startTime",
          headerText: "시간",
          allowEditing: false,
          minWidth: 16,
          width: 60,
          textAlign: "center",
        },
        {
          field: "startCourse",
          headerText: "코스",
          allowEditing: false,
          minWidth: 16,
          width: 80,
          textAlign: "center",
          comCode: "COURSE_CODE",
          valueAccessor: this.valueAccessor,
        },
        {
          field: "grpName",
          headerText: "단체명",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "resveName",
          headerText: "예약자명",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          textAlign: "left",
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          allowEditing: false,
          minWidth: 16,
          width: 80,
          textAlign: "center",
          valueAccessor: gridUtilGetMemberNoColumnAccess,
        },
        {
          field: "visitInfo",
          headerText: "내장객 정보",
          columns: [
            {
              field: "visitorAllCheck",
              headerText: "ALL",
              allowFiltering: false,
              allowSorting: false,
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: "true",
              minWidth: 16,
              width: 40,
              textAlign: "center",
            },
            {
              field: "visitName1",
              headerText: "1",
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              minWidth: 16,
              width: 70,
              textAlign: "left",
            },
            {
              field: "visitorCheck1",
              headerText: "",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: "true",
              minWidth: 16,
              width: 30,
              textAlign: "center",
            },
            {
              field: "visitLockerNo1",
              headerText: "",
              allowEditing: false,
              minWidth: 16,
              width: 40,
              textAlign: "center",
            },
            {
              field: "visitName2",
              headerText: "2",
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              minWidth: 16,
              width: 70,
              textAlign: "left",
            },
            {
              field: "visitorCheck2",
              headerText: "",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: "true",
              minWidth: 16,
              width: 30,
              textAlign: "center",
            },
            {
              field: "visitLockerNo2",
              headerText: "",
              allowEditing: false,
              minWidth: 16,
              width: 40,
              textAlign: "center",
            },
            {
              field: "visitName3",
              headerText: "3",
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              minWidth: 16,
              width: 70,
              textAlign: "left",
            },
            {
              field: "visitorCheck3",
              headerText: "",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: "true",
              minWidth: 16,
              width: 30,
              textAlign: "center",
            },
            {
              field: "visitLockerNo3",
              headerText: "",
              allowEditing: false,
              minWidth: 16,
              width: 40,
              textAlign: "center",
            },
            {
              field: "visitName4",
              headerText: "4",
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              minWidth: 16,
              width: 70,
              textAlign: "left",
            },
            {
              field: "visitorCheck4",
              headerText: "",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: "true",
              minWidth: 16,
              width: 30,
              textAlign: "center",
            },
            {
              field: "visitLockerNo4",
              headerText: "",
              allowEditing: false,
              minWidth: 16,
              width: 40,
              textAlign: "center",
            },
            {
              field: "visitName5",
              headerText: "5",
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              minWidth: 16,
              width: 70,
              textAlign: "left",
            },
            {
              field: "visitorCheck5",
              headerText: "",
              type: "boolean",
              editType: "booleanedit",
              displayAsCheckBox: "true",
              minWidth: 16,
              width: 30,
              textAlign: "center",
            },
            {
              field: "visitLockerNo5",
              headerText: "",
              allowEditing: false,
              minWidth: 16,
              width: 40,
              textAlign: "center",
            },
          ]
        },
      ],
      visitTeamListGridAggregates: [
        {
          columns: [
            {
              field: "startTime",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: "합계",
            },
            {
              field: "startCourse",
              aggregationType: "TotalCaption",
              textAlign: "right",
              customAggregate: (args) => {
                return args.count + "팀";
              }
            },
            {
              field: "grpName",
              aggregationType: "TotalCaption",
              textAlign: "left",
              customAggregate: (args) => {
                let visitorCnt = 0;
                args.result.map(item => {
                  visitorCnt += item.visitInfoList.length;
                });

                return "/ " + visitorCnt + "명";
              }
            }
          ]
        }
      ],
      rowSelectedData: null,
    };
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    closeVisitsCopyPopup() {
      this.$refs.visitsCopyPopup.hide();
    },
    confirmVisitsCopyPopup() {
      const checkData = this.visitTeamList.filter(item => item.visitorCheck1 || item.visitorCheck2 || item.visitorCheck3 || item.visitorCheck4 || item.visitorCheck5);
      const visitSnoList = [];

      checkData.forEach(item => {
        if (item.visitorCheck1) {
          visitSnoList.push(1);
        }
        if (item.visitorCheck2) {
          visitSnoList.push(2);
        }
        if (item.visitorCheck3) {
          visitSnoList.push(3);
        }
        if (item.visitorCheck4) {
          visitSnoList.push(4);
        }
        if (item.visitorCheck5) {
          visitSnoList.push(5);
        }
      });

      const visitInfoList = checkData.length > 0 ? checkData[0].visitInfoList.filter(item => visitSnoList.includes(item.visitSno)) : [];

      if (!(visitInfoList.length > 0)) {
        this.infoToast("선택된 내장객이 없습니다");
        return;
      }

      this.$emit("popupClosed", {
        visitInfoList: visitInfoList,
      });
    },
    onVisitsCopyPopupClosed() {
      this.$emit("popupClosed");
    },
    async getVisitTeamList() {
      // 내장객 복사 List API 호출
      const response = await getVisitCopy(this.bsnDate, this.resveName);
      this.visitTeamList = response.value.map(item => ({
        ...item,
        visitorAllCheck: false,
        visitorCheck1: false,
        visitorCheck2: false,
        visitorCheck3: false,
        visitorCheck4: false,
        visitorCheck5: false,
        visitName1:
          item?.visitInfoList.filter(data => data.visitSno === 1).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 1)[0].visitName : null,
        visitName2:
          item?.visitInfoList.filter(data => data.visitSno === 2).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 2)[0].visitName : null,
        visitName3:
          item?.visitInfoList.filter(data => data.visitSno === 3).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 3)[0].visitName : null,
        visitName4:
          item?.visitInfoList.filter(data => data.visitSno === 4).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 4)[0].visitName : null,
        visitName5:
          item?.visitInfoList.filter(data => data.visitSno === 5).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 5)[0].visitName : null,
        visitLockerNo1:
          item?.visitInfoList.filter(data => data.visitSno === 1).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 1)[0].lockerNo : null,
        visitLockerNo2:
          item?.visitInfoList.filter(data => data.visitSno === 2).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 2)[0].lockerNo : null,
        visitLockerNo3:
          item?.visitInfoList.filter(data => data.visitSno === 3).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 3)[0].lockerNo : null,
        visitLockerNo4:
          item?.visitInfoList.filter(data => data.visitSno === 4).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 4)[0].lockerNo : null,
        visitLockerNo5:
          item?.visitInfoList.filter(data => data.visitSno === 5).length > 0 ?
            item?.visitInfoList.filter(data => data.visitSno === 5)[0].lockerNo : null,
      }));

      /*
      setTimeout(() => {
        this.visitTeamList = [
          {
            'chkinId': 1, // 체크인ID
            'startCourse': '1', // 출발코스
            'startTime': '11:40', // 출발시간
            'teamHoleDiv': '18', // 팀홀구분
            'resveName': '강성무', // 예약자명
            'grpName': '1단체', // 단체명
            'visitInfoList': [
              {
                'visitId': 1, // 내장객ID
                'visitSno': 1, // 내장순번
                'visitName': '조봉현', // 내장객명
                'lockerNo': '0015', // 락카번호
                'sexCode': 'M', // 성별코드
                'membershipId': '', // 회원권ID
                'memberNo': '', // 회원번호
                'memberDiv': '10', // 회원구분
                'memberGrade': '', // 회원등급
                'paymtCode': '100', // 요금코드
                'holeDiv': '', // 홀구분
                'joinGroupNo': '1111', // 조인그룹번호
                'nativeDiv': 'DMSTC' // 내국인구분
              },
              {
                'visitId': 2, // 내장객ID
                'visitSno': 2, // 내장순번
                'visitName': '강성무', // 내장객명
                'lockerNo': '0016', // 락카번호
                'sexCode': 'F', // 성별코드
                'membershipId': '', // 회원권ID
                'memberNo': '', // 회원번호
                'memberDiv': '11', // 회원구분
                'memberGrade': '', // 회원등급
                'paymtCode': '101', // 요금코드
                'holeDiv': '', // 홀구분
                'joinGroupNo': '', // 조인그룹번호
                'nativeDiv': 'OUTNATN' // 내국인구분
              }
            ]
          },
          {
            'chkinId': 2, // 체크인ID
            'startCourse': '2', // 출발코스
            'startTime': '11:50', // 출발시간
            'teamHoleDiv': '9', // 팀홀구분
            'resveName': '이충희', // 예약자명
            'grpName': '2단체', // 단체명
            'visitInfoList': [
              {
                'visitId': 3, // 내장객ID
                'visitSno': 1, // 내장순번
                'visitName': '박건우', // 내장객명
                'lockerNo': '0017', // 락카번호
                'sexCode': 'M', // 성별코드
                'membershipId': '', // 회원권ID
                'memberNo': '', // 회원번호
                'memberDiv': '12', // 회원구분
                'memberGrade': '', // 회원등급
                'paymtCode': '102', // 요금코드
                'holeDiv': '', // 홀구분
                'joinGroupNo': '3333', // 조인그룹번호
                'nativeDiv': 'DMSTC' // 내국인구분
              }
            ]
          }
        ]
      }, 10)
      */
    },
    async showVisitsCopyPopup(bsnDate, resveName) {
      this.bsnDate = bsnDate;
      this.resveName = resveName;

      if (!bsnDate && resveName) {
        // bsnDate 값이 있는 경우에는 onChange 이벤트를 통해 조회를 하게 된다. 그러므로 bsnDate 값이 없고 resveName 값이 있을 경우에만 조회하면 된다
        await this.getVisitTeamList();
      }
    },
    async onBsnDateChanged(args) {
      this.bsnDate = args.data;
      await this.getVisitTeamList();
    },
    visitTeamListGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field,
          },
        },
        node,
      } = args;

      if (
        field === "visitorAllCheck" ||
        field === "visitInfo"
      ) {
        node.style.borderLeftColor = "#000";
      } else if (
        field === "visitName1" ||
        field === "visitName2" ||
        field === "visitName3" ||
        field === "visitName4" ||
        field === "visitName5"
      ) {
        node.colSpan = 3;
        node.style.borderLeftColor = "#000";
      } else if (
        field === "visitorCheck1" || field === "visitLockerNo1" ||
        field === "visitorCheck2" || field === "visitLockerNo2" ||
        field === "visitorCheck3" || field === "visitLockerNo3" ||
        field === "visitorCheck4" || field === "visitLockerNo4" ||
        field === "visitorCheck5" || field === "visitLockerNo5"
      ) {
        args.cell.isSpanned = true;
      }
    },
    visitTeamListGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
      } = args;

      if (field === "visitorAllCheck") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
        cell.style.borderLeftColor = "#000";
      } else if (
        field === "visitorCheck1" ||
        field === "visitorCheck2" ||
        field === "visitorCheck3" ||
        field === "visitorCheck4" ||
        field === "visitorCheck5"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (
        field === "visitName1" ||
        field === "visitName2" ||
        field === "visitName3" ||
        field === "visitName4" ||
        field === "visitName5"
      ) {
        cell.style.borderLeftColor = "#000";
      }
    },
    onVisitTeamListGridCheckboxChanged(args) {
      const {
        columnName,
        rowData,
        value,
      } = args;

      const chkinId = rowData.chkinId;

      if (columnName === "visitorAllCheck") {
        this.visitTeamList.forEach(item => {
          if (item.chkinId === chkinId) {
            item.visitorAllCheck = value;
            item.visitorCheck1 = value;
            item.visitorCheck2 = value;
            item.visitorCheck3 = value;
            item.visitorCheck4 = value;
            item.visitorCheck5 = value;
          } else {
            if (value) {
              item.visitorAllCheck = !value;
              item.visitorCheck1 = !value;
              item.visitorCheck2 = !value;
              item.visitorCheck3 = !value;
              item.visitorCheck4 = !value;
              item.visitorCheck5 = !value;
            }
          }
        });
      } else if (
        columnName === "visitorCheck1" ||
        columnName === "visitorCheck2" ||
        columnName === "visitorCheck3" ||
        columnName === "visitorCheck4" ||
        columnName === "visitorCheck5"
      ) {
        this.visitTeamList.forEach(item => {
          if (item.chkinId === chkinId) {
            item[columnName] = value;
            if (
              item.visitorCheck1 &&
              item.visitorCheck2 &&
              item.visitorCheck3 &&
              item.visitorCheck4 &&
              item.visitorCheck5
            ) {
              item.visitorAllCheck = true;
            } else {
              item.visitorAllCheck = false;
            }
          } else {
            if (value) {
              item.visitorAllCheck = !value;
              item.visitorCheck1 = !value;
              item.visitorCheck2 = !value;
              item.visitorCheck3 = !value;
              item.visitorCheck4 = !value;
              item.visitorCheck5 = !value;
            }
          }
        });
      }
    }
  },
};
</script>
