<template>
  <div>
    <ejs-dialog
      ref="findVisitPopup"
      :header="`내장객 찾기 [${findVIsitSearch.bsnDate}]`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1530"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onFindVisitPopupClosed"
    >
      <div class="window findVisitor">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field reservationName">
                    <div class="title">예약자/내장객/캐디명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          id="visitFindNameTextBox"
                          v-model="findVIsitSearch.resveVisitName"
                          :tabindex="visitFindNameTextBoxTabIndex"
                          @keydown.enter="onVisitFindNameTextBoxKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      :tabindex="-1"
                      @click.native="getFindVisitList"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">타임 목록</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(findVisitListNumber) }} 건]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="findVisitGrid"
                        id="findVisitGrid"
                        :allowResizing="true"
                        :dataSource="findVisitList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="findVisitGridColumn"
                        :provides="grid"
                        :isSelectedRowRetain="false"
                        :isInPopup="true"
                        @queryCellInfo="onQueryCellInfo"
                        @actionComplete="onFindVisitGridActionComplete"
                        @rowSelected="findVisitGridRecordClicked"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onFindVisitGridDoubleClick
                        "
                        @dataBound="onFindVisitGridDataBound"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="confirmFindVisitPopup()"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeFindVisitPopup()"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Resize, ForeignKey, Sort, Filter } from "@syncfusion/ej2-vue-grids";

import {commonCodesGetComName, commonCodesGetSortNo} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { orderBy as _orderBy } from "lodash";

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "findVisitPopup",
  mixins: [commonMixin],
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton
  },
  created() {
    this.resveDate = getFormattedDate(new Date());
  },
  mounted() {
    this.$refs.findVisitGrid.selectRow(0);
  },
  data() {
    return {
      grid: [Resize, ForeignKey, Sort, Filter],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      visitFindNameTextBoxTabIndex: -1,
      findVisitList: [],
      findVisitListNumber: 0,
      findVIsitSearch: {
        bsnDate: null, // 영업일자
        courseCode: null, // 코스
        partDiv: null, // 부구분
        resveVisitName: null, // 예약자/내장객명
        resveVisitFlag: null, // 내장하지 않은 예약자만 출력할 시 false, 내장하지 않은 예약자와 예약한 내장객 다 출력할 시 true
      },
      selectedRowData: null,
      findVisitGridColumn: [
        {
          field: "timeId",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "partDiv",
          headerText: "부",
          width: 50,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "PART_DIV",
          type: "string",
        },
        {
          field: "startTime",
          headerText: "시간",
          width: 60,
          textAlign: "center",
          type: "string",
        },
        {
          field: "startCourse",
          headerText: "코스",
          width: 60,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "COURSE_CODE",
          type: "string",
        },
        {
          field: "holeDiv",
          headerText: "홀",
          width: 50,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "HOLE_DIV",
          type: "string",
        },
        {
          field: "resveName",
          headerText: "예약자",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: 90,
          textAlign: "left",
          type: "string",
        },
        {
          field: "visitName1",
          headerText: "내장객1",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "lockerNumber1",
          headerText: "락카번호1",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "visitName2",
          headerText: "내장객2",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "lockerNumber2",
          headerText: "락카번호2",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "visitName3",
          headerText: "내장객3",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "lockerNumber3",
          headerText: "락카번호3",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "visitName4",
          headerText: "내장객4",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "lockerNumber4",
          headerText: "락카번호4",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        {
          field: "visitName5",
          headerText: "내장객5",
          width: 80,
          textAlign: "left",
          type: "string",
        },
        {
          field: "lockerNumber5",
          headerText: "락카번호5",
          width: 80,
          textAlign: "center",
          type: "string",
        },
        // {
        //   field: "lockerNo",
        //   headerText: "락카",
        //   width: 70,
        //   textAlign: "left",
        //   type: "string",
        // },
        {
          field: "caddieName",
          headerText: "캐디명",
          width: 90,
          textAlign: "left",
          type: "string",
        },
        {
          field: "chkinTime",
          headerText: "C/I",
          width: 50,
          textAlign: "center",
          type: "string",
        },
        {
          field: "chkoutTime",
          headerText: "C/O",
          width: 50,
          textAlign: "center",
          type: "string",
        },
      ],
    };
  },
  methods: {
    numberWithCommas,
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    onFindVisitPopupClosed() {
      this.$emit("popupClosed");
    },
    show() {
      this.$refs.findVisitPopup.show();
    },
    setVisitData(visits) {
      this.findVisitList = this.makeVisitColumns(visits);
      this.findVisitListNumber = this.findVisitList.length;
    },
    setSearchParams(params) {
      this.findVIsitSearch.bsnDate = params.bsnDate;
      this.findVIsitSearch.courseCode = params.courseCode;
      this.findVIsitSearch.partDiv = params.partDiv;
      this.findVIsitSearch.resveVisitName = params.resveVisitName;
      this.findVIsitSearch.resveVisitFlag = params.resveVisitFlag;
      Object.keys(this.findVIsitSearch).forEach((data) => {
        this.findVIsitSearch[data] =
            this.findVIsitSearch[data] === "" ? null : this.findVIsitSearch[data];
      });
    },
    async showFindVisitPopup(findVisitObject) {
      this.show();
      this.setSearchParams(findVisitObject);

      if (findVisitObject.resveVisitName) {
        await this.getFindVisitList();
      }
    },
    closeFindVisitPopup() {
      this.$refs.findVisitPopup.hide();
    },
    confirmFindVisitPopup() {
      if (this.selectedRowData !== null) {
        this.$emit("popupClosed", {
          selectRowData: this.selectedRowData,
          searchValue: this.findVIsitSearch.resveVisitName
        });
        this.$refs.findVisitPopup.hide();
      }
    },
    findVisitGridRecordClicked(args) {
      this.selectedRowData = args.data;
    },
    async getFindVisitList() {
      const args = {
        resveDate: this.findVIsitSearch.bsnDate,
        courseCode: this.findVIsitSearch.courseCode,
        partDiv: this.findVIsitSearch.partDiv,
        resveVisitName: this.findVIsitSearch.resveVisitName
      };
      const findData = await GolfErpAPI.fetchReservationVisitorFind(args);

      this.findVisitList = this.makeVisitColumns(findData);
      this.findVisitListNumber = this.findVisitList.length;

      // // 예약자명, 내장객명 조회 API 호출
      // const response = await getFindVisitList(
      //   this.findVIsitSearch.bsnDate,
      //   this.findVIsitSearch.courseCode,
      //   this.findVIsitSearch.partDiv,
      //   this.findVIsitSearch.resveVisitName,
      //   this.findVIsitSearch.resveVisitFlag
      // );
      // this.findVisitList = response.value.findVisitLists;
      // this.findVisitListNumber = this.findVisitList.length;
    },
    makeVisitColumns(findData) {
      return _orderBy(findData.map(data => {
        return {
          timeId: data.timeId,
          startTime: data.reservationConfirm.team?.time ? data.reservationConfirm.team?.time : data.resveTime,
          startCourse: data.reservationConfirm.team?.course ? data.reservationConfirm.team?.course : data.resveCourse,
          holeDiv: data.reservationConfirm.team?.holeDivision ? data.reservationConfirm.team?.holeDivision : data.holeDiv,
          partDiv: data.reservationConfirm.team?.partDivision ? data.reservationConfirm.team?.partDivision : data.partDiv,
          grpKind: data.reservationConfirm.team?.groupKind ? data.reservationConfirm.team?.groupKind : data.reservationConfirm.reservationMember.grpKind,
          grpNo: data.reservationConfirm.team?.groupNumber ? data.reservationConfirm.team?.groupNumber : data.reservationConfirm.reservationMember.grpNo,
          grpName: data.reservationConfirm.team?.groupName ? data.reservationConfirm.team?.groupName : data.reservationConfirm.reservationMember.grpName,
          resveId: data.reservationConfirm.resveId,
          chkinId: data.reservationConfirm.team?.id,
          resveName: data.reservationConfirm.reservationMember.resveName,
          chkinTime: data.reservationConfirm.team?.insertDateTime ? getFormattedDate(data.reservationConfirm.team.insertDateTime, "HH:mm") : null,
          chkoutTime: data.reservationConfirm.team?.checkOutDateTime ? getFormattedDate(data.reservationConfirm.team?.checkOutDateTime, "HH:mm") : null,
          visitName1: data.reservationConfirm.team?.visitors[0]?.name,
          lockerNumber1: data.reservationConfirm.team?.visitors[0]?.lockerNumber,
          visitName2: data.reservationConfirm.team?.visitors[1]?.name,
          lockerNumber2: data.reservationConfirm.team?.visitors[1]?.lockerNumber,
          visitName3: data.reservationConfirm.team?.visitors[2]?.name,
          lockerNumber3: data.reservationConfirm.team?.visitors[2]?.lockerNumber,
          visitName4: data.reservationConfirm.team?.visitors[3]?.name,
          lockerNumber4: data.reservationConfirm.team?.visitors[3]?.lockerNumber,
          visitName5: data.reservationConfirm.team?.visitors[4]?.name,
          lockerNumber5: data.reservationConfirm.team?.visitors[4]?.lockerNumber,
          caddieName: data.reservationConfirm.team?.teamCaddies.map(teamCaddie => teamCaddie.caddie.name)?.join(","),
          courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", data.reservationConfirm.team?.course ? data.reservationConfirm.team?.course : data.resveCourse),
        };
      }), ["startTime", "courseCodeSortNo", "partDiv"]);
    },
    onQueryCellInfo(args) {
      const {column:{field},data,cell } = args;
        const searchName = this.findVIsitSearch?.resveVisitName;
        if(!!searchName && data[field]?.includes(searchName) && /(visitName|caddieName|resveName)/.test(field)) {
          cell.style.fontWeight = 'bold';
        }
    },
    onFindVisitGridActionComplete(args) {
      if (args.requestType === "filtering") {
        this.findVisitListNumber = args.rows.length;
      }
    },
    onFindVisitGridDoubleClick() {
      this.confirmFindVisitPopup();
    },
    async onVisitFindNameTextBoxKeyDownEnter() {
      await this.getFindVisitList();
    },
    onFindVisitGridDataBound() {
      if (this.findVisitList.length > 0) {
        this.visitFindNameTextBoxTabIndex = -1;
        this.$refs.findVisitGrid.selectRow(0);
      } else {
        this.visitFindNameTextBoxTabIndex = 0;
        document.getElementById("visitFindNameTextBox").select();
      }
    },
  },
};
</script>
