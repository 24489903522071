<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="takeCompanionMemberPopup"
      header="동반자 가져오기"
      :allowDragging="true"
      :showCloseIcon="true"
      :width="950"
      :height="324"
      :isModal="true"
      @close="onTakeCompanionMemberPopupClosed"
    >
      <div class="window takeCompanionMember">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">
                      내장일자 : {{ teamInfo.visitDate }}
                      (<span :style="{color: dayOfWeekCaptionColorByVisitDate}">{{ dayOfWeekCaptionByVisitDate }}</span>)
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      출발시간 : {{ teamInfo.startTime }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      출발코스 : {{ startCourseLabel }}
                    </div>
                  </li>
                  <li class="field">
                    <div class="title">
                      예약자명 : {{ reservationName }}
                    </div>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="onTakeCompanionMemberViewClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        동반자 등록 이후 회원가입
                      </div>
                      <div class="header-caption">
                        [{{ takeCompanionMemberListLength | numberWithCommas }}건]
                      </div>
                      <ul class="header-label">
                        <li class="field">
                        <ul class="content">
                        <li class="item">
                          <i
                              style="background: blue"
                          ></i>
                          <div class="label">동반자 등록 이후 회원가입</div>
                        </li>
                      </ul>
                      </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="takeCompanionMemberGrid"
                        :provides="grid"
                        :columns="takeCompanionMemberGridColumn"
                        :dataSource="takeCompanionMemberList"
                        :allowResizing="true"
                        :allowSelection="true"
                        :allowFiltering="false"
                        :allowSorting="false"
                        :isAutoSelectRow="false"
                        :isAutoSelectCell="false"
                        :selectionSettings="selectionSettings"
                        :editSettings="editSettings"
                        @queryCellInfo="takeCompanionMemberGridQueryCellInfo"
                        @actionComplete="takeCompanionMemberGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onSubmitClick"
              >
                선택
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import { orderBy as _orderBy } from "lodash";
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import {commonCodesGetComName} from '@/utils/commonCodes';
import {numberWithCommas} from '@/utils/number';
import {
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
} from '@/utils/date';
import {
  gridUtilGetMemberNoColumnAccess,
  gridUtilGetTelColumnAccess,
} from "@/utils/gridUtil";
import {
  Edit,
  Resize,
  ForeignKey,
} from '@syncfusion/ej2-vue-grids';
import EjsGridWrapper from '@/components/common/EjsGridWrapper.vue';
import GolfErpAPI from '@/api/v2/GolfErpAPI';

export default {
  name: 'TakeCompanionMemberPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton
  },
  filters: {numberWithCommas},
  data() {
    return {
      reservationId: null,
      reservationName: null,
      teamInfo: {
        visitDate: null,
        startTime: null,
        startCourse: null,
        visitCnt: null,
      },
      batchCurrentViewRecords: [],
      takeCompanionMemberList: [],
      takeCompanionMemberListLength: 0,
      takeCompanionMemberGridColumn: [
        {
          field: 'sno',
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: 'cmpnName',
          headerText: '동반자명',
          type: 'string',
        },
        {
          field: 'memberNo',
          headerText: '회원번호',
          width: 90,
          type: 'string',
          textAlign: 'center',
          valueAccessor: gridUtilGetMemberNoColumnAccess,
        },
        {
          field: 'memberGrade',
          headerText: '회원등급',
          type: 'string',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'MEMBER_GRADE',
        },
        {
          field: 'contactTel',
          headerText: '연락처',
          type: 'string',
          textAlign: 'center',
          valueAccessor: (field, data) => gridUtilGetTelColumnAccess(field, data),
        },
        {
          field: 'sexCode',
          headerText: '성별',
          width: 60,
          type: 'string',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'SEX_CODE',
        },
        {
          field: 'insertDt',
          headerText: '동반자 등록일시',
          width: 150,
          type: 'string',
          textAlign: 'center',
        },
        {
          field: 'memberInsertDt',
          headerText: '회원가입 일시',
          width: 150,
          type: 'string',
          textAlign: 'center',
        },
        {
          field: 'checkbox',
          width: 50,
          type: 'checkbox',
          textAlign: 'center',
          allowEditing: true,
        },
      ],
      grid: [Edit, Resize, ForeignKey],
      selectionSettings: {
        type: 'Single',
        checkboxMode: 'Default',
        persistSelection: true,
      },
      editSettings: {
        mode: 'Batch',
        allowDeleting: true,
      },
    };
  },
  computed: {
    dayOfWeekCaptionColorByVisitDate() {
      return getDayOfWeekCaptionColor(new Date(this.teamInfo.visitDate));
    },
    dayOfWeekCaptionByVisitDate() {
      return getDayOfWeekCaption(new Date(this.teamInfo.visitDate));
    },
    startCourseLabel() {
      return commonCodesGetComName(
        'COURSE_CODE',
        this.teamInfo.startCourse
      );
    }
  },
  methods: {
    async showPopup(resveInfo, teamInfo, batchCurrentViewRecords) {
      this.reservationId = resveInfo?.resveId;
      this.reservationName = resveInfo?.resveName;
      this.teamInfo = teamInfo;
      this.batchCurrentViewRecords = batchCurrentViewRecords
        ?.filter(item => item.visitName !== '')
        ?.map(item => {
          item.cmpnName = item.visitName;
          item.contactTel = item.contactTel || '';
          return item;
        });
      await this.fetch();
    },
    takeCompanionMemberGridQueryCellInfo(args) {
      const fieldCheck = ['memberNo','memberGrade','memberInsertDt'];
      const {
        cell,
        column: { field },
      } = args;
      if(field === 'checkbox') {
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if(fieldCheck.includes(field) && args.data.diffDate === false) {
        cell.style.color = 'blue';
      }
      if(field === 'memberInsertDt' && args.data.diffDate === null) {
        cell.style.color = 'red';
      }
    },
    takeCompanionMemberGridActionComplete(args) {
      const {
        action,
        requestType
      } = args;
      if (requestType === 'filtering') {
        if (action === 'clearFilter') {
          this.takeCompanionMemberListLength = this.takeCompanionMemberList.length;
        }
        this.takeCompanionMemberListLength = this.$refs.takeCompanionMemberGrid.getFilteredRecords().length;
      }
    },
    async onTakeCompanionMemberViewClicked() {
      await this.fetch();
    },
    onSubmitClick() {
      const selectedRowData = this.$refs.takeCompanionMemberGrid.getSelectedRecords();
      if (selectedRowData.length < 1) {
        this.errorToast('가져올 동반자를 선택해주세요');
        return;
      }
      this.confirm(selectedRowData);
    },
    onCloseClick() {
      this.$refs.takeCompanionMemberPopup.hide();
    },
    onTakeCompanionMemberPopupClosed() {
      this.$emit('popupClosed');
    },
    confirm(data) {
      const result = data
        ?.map(item => {
          item.visitName = item.cmpnName;
          return item;
        });
      this.$emit('popupConfirm', _orderBy(result, 'sno'));
      this.onTakeCompanionMemberPopupClosed();
    },
    // API
    async fetch() {
      this.$refs.takeCompanionMemberGrid.clearFiltering();
      const data = (await GolfErpAPI.fetchResveCmpns(this.reservationId))
        ?.map(item => {
          item.contactTel = item.contactTel || '';
          return item;
        })
        ?.filter(item =>
          !(
            (this.batchCurrentViewRecords
              ?.filter(item => item.lockerNo) // 예약자도 동반자 가져오기에 보이길 원해서 락카번호가 없으면 동반자로 보여주도록 수정(라데나)
              ?.map(i => i.cmpnName)
              ?.includes(item.cmpnName)
            ) &&
            (this.batchCurrentViewRecords
              ?.filter(item => item.lockerNo)
              ?.map(i => i.contactTel)
              ?.includes(item.contactTel)
            )
          )
        )?.map((data)=> {
            data.diffDate = data.memberInsertDt? data.insertDt > data.memberInsertDt : null;
            (!data.memberInsertDt) ? data.memberInsertDt = '미가입' : data.memberInsertDt;
            return data;
          });
      this.takeCompanionMemberList = data;
      this.takeCompanionMemberListLength = data?.length;
    },
  },
};
</script>
