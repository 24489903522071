<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="discountPopup"
      :header="`입장료 할인`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1046"
      height="465"
      :isModal="true"
      :close="onDiscountPopupClosed"
    >
      <div class="window admissionFeeDiscount">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-box">
                      <ul>
                        <li>시간: {{ visitInfo.startTime }}</li>
                        <li>
                          코스:
                          {{
                            commonCodesGetComName(
                              "COURSE_CODE",
                              visitInfo.startCourse
                            )
                          }}
                        </li>
                        <li>
                          홀 구분:
                          {{
                            commonCodesGetComName("HOLE_DIV", visitInfo.holeDiv)
                          }}
                        </li>
                        <li>내장객: {{ visitInfo.visitName }}</li>
                        <li>락카번호: {{ visitInfo.lockerNo }}</li>
                        <li>
                          회원등급:
                          {{
                            commonCodesGetComName(
                              "MEMBER_GRADE",
                              visitInfo.memberGrade
                            )
                          }}
                        </li>
                        <li>
                          요금구분:
                          {{
                            commonCodesGetComName(
                              "PAYMT_CODE",
                              visitInfo.paymtCode
                            )
                          }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">입장료</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0201">
                        <div class="section-body">
                          <div class="body-data header">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field productClassification">
                                    <!-- 필수 : required -->
                                    <div class="title">상품분류</div>
                                  </li>
                                  <li class="field salesChitId">
                                    <!-- 필수 : required -->
                                    <div class="title">매출전표 ID</div>
                                  </li>
                                  <li class="field productName">
                                    <!-- 필수 : required -->
                                    <div class="title">상품명</div>
                                  </li>
                                  <li class="field salePrice">
                                    <!-- 필수 : required -->
                                    <div class="title">판매금액</div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                          <div class="body-data body">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field productClassification">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text">
                                          {{ greenFeeInfo.className }}
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field salesChitId">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text">
                                          {{ greenFeeInfo.slipId }}
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field productName">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text">
                                          {{ greenFeeInfo.productName }}
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field salePrice">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text">
                                          {{
                                            numberWithCommas(
                                              greenFeeInfo.saleAmt
                                            )
                                          }}
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0202">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">할인내역</div>
                          </div>
                          <div class="header-right">
                            <ul class="header-button">
                              <li class="add">
                                <erp-button
                                    button-div="GET"
                                    :is-icon-custom="true"
                                    :disabled="teamChkoutFlag"
                                    @click.native="addDcInfo()"
                                >
                                  일반할인
                                </erp-button>
                              </li>
                              <li class="add">
                                <erp-button
                                    button-div="GET"
                                    :is-icon-custom="true"
                                    :disabled="teamChkoutFlag"
                                    @click.native="addCouponDcInfo"
                                >
                                  쿠폰할인
                                </erp-button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-data header">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul class="data-content">
                                  <li class="field discountApplyDivision">
                                    <!-- 필수 : required -->
                                    <div class="title required">
                                      할인적용 구분
                                    </div>
                                  </li>
                                  <li class="field button">
                                    <!-- 필수 : required -->
                                    <div class="title"></div>
                                  </li>
                                  <li class="field discountUseNumber">
                                    <!-- 필수 : required -->
                                    <div class="title">할인사용번호</div>
                                  </li>
                                  <li class="field discountMethod">
                                    <!-- 필수 : required -->
                                    <div class="title required">할인방법</div>
                                  </li>
                                  <li class="field discountPrice">
                                    <!-- 필수 : required -->
                                    <div class="title">
                                      할인금액/할인율/요금구분
                                    </div>
                                  </li>
                                  <li class="field applyPrice">
                                    <!-- 필수 : required -->
                                    <div class="title">적용금액</div>
                                  </li>
                                  <li class="field discountContents">
                                    <!-- 필수 : required -->
                                    <div class="title required">할인내용</div>
                                  </li>
                                  <li class="field delete">
                                    <!-- 필수 : required -->
                                    <div class="title"></div>
                                  </li>
                                  <li class="field scroll">
                                    <!-- 필수 : required -->
                                    <div class="title">스크롤</div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                          <div class="body-data body">
                            <div class="data-outer">
                              <div class="data-inner">
                                <ul
                                  class="data-content"
                                  v-for="(dcItem, dcIdx) in dcList"
                                  :key="dcItem._rid"
                                >
                                  <li class="field discountApplyDivision">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div>
                                            {{
                                              commonCodesGetComName(
                                                "DC_APPLY_DIV",
                                                dcItem.dcApplyDiv
                                              )
                                            }}
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field button">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="search">
                                              <erp-button
                                                  button-div="GET"
                                                  :is-icon-custom="true"
                                                  @click.native="
                                                  changeDcApplyDiv(dcIdx)
                                                "
                                              >
                                                검색
                                              </erp-button>
                                            </li>
                                          </ul>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field discountUseNumber">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text">
                                          {{ dcItem.dcUseNo }}
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field discountMethod">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <ejs-dropdownlist
                                              v-model="dcItem.dcMethod"
                                              :dataSource="
                                                greenFeeGridColumnOptions.dcMethod
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                greenFeeGridColumnOptions.dcMethodFields
                                              "
                                              cssClass="body-data-dropdown"
                                              @change="
                                                changeDcMethod(
                                                  $event,
                                                  dcIdx,
                                                  dcItem.paymtCode
                                                )
                                              "
                                            ></ejs-dropdownlist>
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field discountPrice">
                                    <div
                                      class="content"
                                      v-if="dcItem.dcMethod === 'AMT'"
                                    >
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-number
                                              width="80"
                                              v-model="dcItem.dcAmt"
                                              :min="0"
                                              format="n0"
                                              @change="changeDcAmt(
                                                $event,
                                                dcIdx
                                              )"
                                            ></input-number>
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                        <li class="item form-group text">
                                          원
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="content"
                                      v-if="dcItem.dcMethod === 'RATE'"
                                    >
                                      <ul class="row">
                                        <li class="item form-group input">
                                          <div class="form">
                                            <input-number
                                              width="80"
                                              v-model="dcItem.dcAmt"
                                              :min="0"
                                              :max="100"
                                              format="n0"
                                              @change="changeDcAmt(
                                                $event,
                                                dcIdx
                                              )"
                                            ></input-number>
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                        <li class="item form-group text">
                                          %
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div
                                      class="content"
                                      v-show="dcItem.dcMethod === 'PAYMT'"
                                    >
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div
                                            class="form"
                                            @keydown.capture="
                                              onPaymtCodeKeydownCapture
                                            "
                                          >
                                            <ejs-dropdownlist
                                              v-model="dcItem.paymtCode"
                                              :dataSource="
                                                greenFeeGridColumnOptions.paymtCode
                                              "
                                              :allowFiltering="false"
                                              :fields="
                                                greenFeeGridColumnOptions.paymtCodeFields
                                              "
                                              cssClass="body-data-dropdown"
                                              @change="
                                                changePayMt($event, dcIdx)
                                              "
                                            ></ejs-dropdownlist>
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field applyPrice">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group text">
                                          {{
                                            numberWithCommas(dcItem.applyAmt)
                                          }}
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field discountContents">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group">
                                          <div class="form">
                                            <input-text
                                              :disabled="true"
                                              v-model="dcItem.dcContents"
                                            />
                                          </div>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                  <li class="field delete">
                                    <div class="content">
                                      <ul class="row">
                                        <li class="item form-group button">
                                          <ul class="button">
                                            <li class="delete">
                                              <erp-button
                                                  button-div="DELETE"
                                                  :is-icon-custom="true"
                                                  @click.native="deleteDcInfo(dcIdx)"
                                                  :disabled="teamChkoutFlag"
                                              >
                                                삭제
                                              </erp-button>
                                            </li>
                                          </ul>
                                          <div class="validation">
                                            <div class="e-tip-content"></div>
                                            <div class="e-tip-top"></div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                                <div class="border-left"></div>
                                <div class="border-right"></div>
                                <div class="border-top"></div>
                                <div class="border-bottom"></div>
                              </div>
                            </div>
                            <div class="border-left"></div>
                            <div class="border-right"></div>
                            <div class="border-top"></div>
                            <div class="border-bottom"></div>
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :disabled="teamChkoutFlag"
                  @click.native="confirmDiscountPopup"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeDiscountPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <search-normal-dc-popup
      v-if="isSearchNormalDcPopupOpen"
      ref="searchNormalDcPopup"
      @popupClosed="searchNormalDcPopupClosed"
    />
    <search-coupone-dc-popup
      v-if="isSearchCouponeDcPopupOpen"
      ref="searchCouponDcPopup"
      @popupClosed="searchCouponDcPopupClosed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { calculateApplyAmt } from "@/utils/gridUtil";
import searchNormalDcPopup from "@/views/common/SearchNormalDcPopup";
import searchCouponeDcPopup from "@/views/common/SearchCouponDcPopup";

import { getFixedPaymentPrice } from "@/api/common";
import InputText from "@/components/common/text/InputText";
import InputNumber from "@/components/common/InputNumber";

export default {
  name: "discountPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputNumber,
    searchNormalDcPopup,
    searchCouponeDcPopup,
    ErpButton
  },
  data() {
    return {
      rowIndex: null,
      visitInfo: {},
      greenFeeInfo: {},
      dcList: [],
      greenFeeGridColumnOptions: {
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          checkboxOnly: false,
          type: "Multiple",
          mode: "Both",
        },
        dcApplyDiv: commonCodesGetCommonCode("DC_APPLY_DIV"),
        dcApplyDivEdit: { params: { sortOrder: "none" } },
        dcMethod: commonCodesGetCommonCode("DC_METHOD"),
        dcMethodEdit: { params: { sortOrder: "none" } },
        paymtCode: commonCodesGetCommonCode("PAYMT_CODE"),
        dcApplyDivFields: { text: "comName", value: "comCode" },
        dcMethodFields: { text: "comName", value: "comCode" },
        paymtCodeFields: { text: "comName", value: "comCode" },
      },
      isSearchNormalDcPopupOpen: false,
      isSearchCouponeDcPopupOpen: false,
      teamChkoutFlag: false,
      isLockPaymtComboboxKeyDown: false,
      confirmObject: null,
      promtnDelFlag: false,
    };
  },
  methods: {
    commonCodesGetComName,
    numberWithCommas,
    confirmDiscountPopup() {
      if (this.teamChkoutFlag) {
        return;
      }

      // 요금코드 할인 내역이 있는 경우, 1건만 있어야 함
      const dcListPaymtLength = this.dcList.filter(
        (dcInfo) => dcInfo.dcMethod === "PAYMT"
      ).length;
      if (dcListPaymtLength > 1) {
        this.errorToast("요금 코드가 중복 입력되었습니다");
        return;
      }

      // 요금코드 할인 내역이 있는 경우, 요금구분을 입력하지 않았다면 에러
      const dcListPaymt = this.dcList.find(
        (dcInfo) => dcInfo.dcMethod === "PAYMT"
      );
      if (dcListPaymt && !dcListPaymt.paymtCode) {
        this.errorToast("요금구분을 선택해 주십시오");
        return;
      }

      // 할인금액 체크 : 판매금액보다 크면 안 됨
      if (this.dcList && this.dcList.length > 0) {
        if (this.dcList[this.dcList.length - 1].applyAmt < 0) {
          this.errorToast("할인금액이 판매금액보다 클 수 없습니다");
          return;
        }
      }

      // vForKey는 API와 상관 없는 property 이므로 삭제
      this.dcList.forEach((dcRule) => {
        delete dcRule._rid;
      });

      this.confirmObject = {
        greenFeeInfo: this.greenFeeInfo,
        dcList: this.dcList,
        rowIndex: this.rowIndex,
        promtnDelFlag: this.promtnDelFlag,
      };

      // 팝업을 닫을 때와, 확인 버튼을 눌러 닫을 때 같은 action. 이렇게 하지 않으면 팝업 닫은 후 셀 포커싱 시에 문제가 생김
      this.$refs.discountPopup.hide();
    },
    closeDiscountPopup() {
      this.$refs.discountPopup.hide();
    },
    onDiscountPopupClosed() {
      let closeConfirmObject = {
        rowIndex: this.rowIndex,
      };

      if (!this.confirmObject) {
        this.confirmObject = closeConfirmObject;
      }

      this.$emit("popupClosed", this.confirmObject);
    },
    showDiscountPopup(
      visitInfo,
      greenFeeInfo,
      dcList,
      rowIndex,
      teamChkoutFlag = false,
      openDcAplyDiv
    ) {
      this.rowIndex = rowIndex;
      this.visitInfo = visitInfo;
      this.greenFeeInfo = JSON.parse(JSON.stringify(greenFeeInfo));
      this.dcList = JSON.parse(JSON.stringify(dcList));
      this.teamChkoutFlag = teamChkoutFlag;

      // v-for 를 위한 key 값 추가하기
      this.addVForKey();
      this.$nextTick(() => {
        if (openDcAplyDiv) {
          if (openDcAplyDiv === "NOR") {
            this.isSearchNormalDcPopupOpen = true;
            this.$nextTick(() => {
              this.$refs.searchNormalDcPopup.showSearchNormalDcPopup(
                null,
                this.dcList
              );
            });
          } else if (openDcAplyDiv === "COUPON") {
            const args = {
              visitInfo: this.visitInfo,
              dcList: this.dcList,
              storeCode: "FRONT",
            };
            this.isSearchCouponeDcPopupOpen = true;
            this.$nextTick(() => {
              this.$refs.searchCouponDcPopup.showSearchCouponDcPopup(args);
            });
          }
        }
      });
    },
    deleteDcInfo(dcIndex) {
      if (this.teamChkoutFlag) {
        return;
      }

      if (this.dcList[dcIndex].dcApplyDiv === "PROMTN") {
        this.promtnDelFlag = true;
      }

      this.dcList.splice(dcIndex, 1);
      this.calculateApplyAmt();
    },
    addDcInfo() {
      if (this.teamChkoutFlag) {
        return;
      }

      // 추가 시 팝업 띄우기
      this.isSearchNormalDcPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.searchNormalDcPopup.showSearchNormalDcPopup(
          null,
          this.dcList
        );
      });

      // this.calculateApplyAmt();
    },
    changeDcContents(dcIdx) {
      const dcContents = this.dcList[dcIdx]?.dcContents;
      const dcAmt = this.dcList[dcIdx]?.dcAmt;
      const dcMethod = this.dcList[dcIdx]?.dcMethod;
      const lastIndex = dcContents.lastIndexOf("(");
      const newDcContents =
        (lastIndex >= 0 ? dcContents.substring(0, lastIndex) : dcContents) +
        (dcMethod === "RATE" ? "(" + numberWithCommas(dcAmt) + "%)" : dcMethod === "AMT" ? "(" + numberWithCommas(dcAmt) + ")" : "")
      ;

      this.dcList[dcIdx].dcContents = newDcContents;
    },
    async changeDcMethod(args, dcIdx, paymtCode) {
      if (args.value === "PAYMT") {
        await this.getPaymtPrice(
          dcIdx,
          this.visitInfo.bsnDate,
          this.visitInfo.holeDiv,
          paymtCode
        );
      } else {
        this.calculateApplyAmt();
      }
      this.changeDcContents(dcIdx);
    },
    changeDcAmt(args, dcIdx) {
      this.calculateApplyAmt();
      this.changeDcContents(dcIdx);
    },
    async changePayMt(args, dcIdx) {
      try {
        this.isLockPaymtComboboxKeyDown = true;
        await this.getPaymtPrice(
          dcIdx,
          this.visitInfo.bsnDate,
          this.visitInfo.holeDiv,
          args.value
        );
      } finally {
        this.isLockPaymtComboboxKeyDown = false;
      }
    },
    async getPaymtPrice(dcIdx, bsnDate, holeDiv, paymtCode) {
      if (paymtCode && paymtCode !== "NOT") {
        try {
          const {
            value: {
              price: price
            }
          } = await getFixedPaymentPrice(
            bsnDate,
            holeDiv,
            paymtCode
          );
          this.setApplyAmt(dcIdx, price);
        } catch (e) {
          this.setApplyAmt(dcIdx, 0);
          throw e;
        }
      } else {
        this.setApplyAmt(dcIdx, 0);
      }
    },
    setApplyAmt(dcIdx, pay) {
      this.dcList[dcIdx].applyAmt = pay;
      this.calculateApplyAmt();
    },
    calculateApplyAmt() {
      calculateApplyAmt(this.greenFeeInfo, this.dcList);
    },
    addVForKey() {
      this.dcList.forEach((dcRule, dcRuleIdx) => {
        dcRule._rid = dcRuleIdx + 1;
      });
    },
    changeDcApplyDiv(dcIdx) {
      if (this.dcList[dcIdx].dcApplyDiv === "NOR") {
        this.isSearchNormalDcPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.searchNormalDcPopup.showSearchNormalDcPopup(
            dcIdx,
            this.dcList
          );
        });
      } else if (this.dcList[dcIdx].dcApplyDiv === "COUPON") {
        const args = {
          visitInfo: this.visitInfo,
          dcList: this.dcList,
          dcIdx: dcIdx,
          storeCode: "FRONT", // 필요??
        };
        this.isSearchCouponeDcPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.searchCouponDcPopup.showSearchCouponDcPopup(args);
        });
      } else {
        this.errorToast("일반/쿠폰 할인만 변경하실 수 있습니다");
      }
    },
    searchNormalDcPopupClosed(args) {
      this.isSearchNormalDcPopupOpen = false;

      if (args !== undefined) {
        args.rowSelectedData.dcApplyDiv = "NOR";

        if (!!args.changeIdx || args.changeIdx === 0) {
          this.dcList[args.changeIdx] = args.rowSelectedData;
        } else {
          if (!this.dcList) {
            this.dcList = [];
          }
          this.dcList.push(args.rowSelectedData);
        }
        this.calculateApplyAmt();
      }
    },
    addCouponDcInfo() {
      if (this.teamChkoutFlag) {
        return;
      }

      const args = {
        visitInfo: this.visitInfo,
        dcList: this.dcList,
        storeCode: "FRONT",
      };
      this.isSearchCouponeDcPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.searchCouponDcPopup.showSearchCouponDcPopup(args);
      });
    },
    searchCouponDcPopupClosed(args) {
      this.isSearchCouponeDcPopupOpen = false;

      if (args !== undefined) {
        args.rowSelectedData.dcApplyDiv = "COUPON";

        if (!!args.changeIdx || args.changeIdx === 0) {
          this.dcList[args.changeIdx] = args.rowSelectedData;
        } else {
          if (!this.dcList) {
            this.dcList = [];
          }
          this.dcList.push(args.rowSelectedData);
        }
        this.calculateApplyAmt();
      }
    },
    onPaymtCodeKeydownCapture(e) {
      if (this.isLockPaymtComboboxKeyDown) {
        e.stopPropagation();
        e.preventDefault();
      }
    },
  },
};
</script>
