export function isReservedLockerNo(lockerNo) {
  if (
    lockerNo !== null &&
    lockerNo !== undefined &&
    (lockerNo.trim() === "" ||
      lockerNo.trim() === "000" ||
      lockerNo.trim() === "999")
  ) {
    return true;
  } else {
    return false;
  }
}
