<template>
  <div>
    <ejs-dialog
      ref="cashReceiptPopup"
      :header="'현금영수증 발행/취소'"
      :allowDragging="true"
      :showCloseIcon="true"
      width="600"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      @close="handleDialogClose"
    >
      <div class="window cashReceipt">
        <div class="windowContent" v-show="approveToggle">
          <div class="content-wrapper">
            <div
              style="
                position: relative;
                box-sizing: border-box;
                border-bottom: 1px solid #e0e0e0;
                background-color: #f9f9f9;
              "
            >
              <div
                style="
                  line-height: 52px;
                  text-align: center;
                  color: #000;
                  font-size: 16px;
                  font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif;
                "
              >
                현금영수증 발급
              </div>
              <div style="position: absolute; right: 0; top: 0">
                <erp-button
                  button-div="CLOSE"
                  class="button-right"
                  :use-syncfusion-component-style="false"
                  @click="approveToggle = !approveToggle"
                ></erp-button>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border: none; margin: -18px">
                    <ul class="lookupDetail-condition">
                      <li class="field">
                        <div
                          class="title"
                          style="position: relative; padding-left: 14px"
                        >
                          영업일자
                        </div>
                        <ul class="content">
                          <li class="item">
                            <input-date
                              type="lookupDetail-condition"
                              format="YYYY-MM-DD"
                              v-model="today"
                              :disabled="true"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div
                          class="title"
                          style="position: relative; padding-left: 14px"
                        >
                          영업장
                        </div>
                        <ul class="content">
                          <li class="item">
                            <input-text :value="bsnName" disabled />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div
                          class="title"
                          style="position: relative; padding-left: 14px"
                        >
                          매장명
                        </div>
                        <ul class="content">
                          <li class="item">
                            <input-text :value="storeName" disabled />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div
                          class="title"
                          style="position: relative; padding-left: 14px"
                        >
                          <div
                            style="
                              position: absolute;
                              left: 6px;
                              top: 9px;
                              width: 4px;
                              height: 4px;
                              background-color: #f92525;
                              border-radius: 50%;
                            "
                          ></div>
                          증빙종류
                        </div>
                        <ul class="content">
                          <li class="item">
                            <ejs-dropdownlist
                              ref="proofKind"
                              v-model="approvalInfo.proofKind"
                              :dataSource="proofKindList"
                              :allowFiltering="false"
                              :fields="{ text: 'comName', value: 'comCode' }"
                              cssClass="lookupDetail-condition-dropdown"
                              @change="onProofKindDropdownChange"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div
                          class="title required"
                          style="position: relative; padding-left: 14px"
                        >
                          증빙번호
                        </div>
                        <div class="content">
                          <ul
                            class="row"
                            style="list-style-type: none; padding: 0"
                          >
                            <li class="item form-group">
                              <div class="form">
                                <input-text
                                  ref="proofNo"
                                  :disabled="
                                    approvalInfo.proofKind === 'VOL' ||
                                    approvalInfo.cardProof
                                  "
                                  v-model="approvalInfo.proofNo"
                                  maxlength="20"
                                  @keypress.enter.native="focusPayAmt"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="field" v-show="van === 'NICE'">
                        <div
                          class="title"
                          style="position: relative; padding-left: 14px"
                        >
                          카드증빙
                        </div>
                        <ul class="content">
                          <li class="item">
                            <input
                              type="checkbox"
                              class="e-input"
                              :disabled="approvalInfo.proofKind === 'VOL'"
                              v-model="approvalInfo.cardProof"
                            />
                          </li>
                        </ul>
                      </li>
                      <li class="field">
                        <div
                          class="title required"
                          style="position: relative; padding-left: 14px"
                        >
                          <div
                            style="
                              position: absolute;
                              left: 6px;
                              top: 9px;
                              width: 4px;
                              height: 4px;
                              background-color: #f92525;
                              border-radius: 50%;
                            "
                          ></div>
                          승인금액
                        </div>
                        <div class="content">
                          <ul
                            class="row"
                            style="list-style-type: none; padding: 0"
                          >
                            <li class="item form-group">
                              <div class="form">
                                <input-number
                                  ref="payAmt"
                                  v-model="approvalInfo.payAmt"
                                  @keydown.enter.native="
                                    onClickedApprovalButton
                                  "
                                  :propMaxLength="8"
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowContent" v-show="!approveToggle">
          <div class="content-wrapper">
            <div
              style="
                position: relative;
                box-sizing: border-box;
                border-bottom: 1px solid #e0e0e0;
                background-color: #f9f9f9;
              "
            >
              <div
                style="
                  line-height: 52px;
                  text-align: center;
                  color: #000;
                  font-size: 16px;
                  font-family: 'NanumSquare-Regular', Arial, Verdana, sans-serif;
                "
              >
                현금영수증 <em>취소</em>
              </div>
              <div style="position: absolute; left: 0; top: 0">
                <erp-button
                  button-div="CLOSE"
                  class="button-left"
                  :use-syncfusion-component-style="false"
                  @click="approveToggle = !approveToggle"
                ></erp-button>
              </div>
            </div>
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">영업일자</div>
                    <ul class="content">
                      <li class="item dateRange">
                        <input-date-range
                          type="lookup-condition"
                          v-model="searchPayDateRange"
                          @keydown.enter.native="fetchTgPayCashList"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">증빙번호</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          v-model="cancelSearchOption.proofNo"
                          @keydown.enter="fetchTgPayCashList"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">승인번호</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          v-model="cancelSearchOption.approvalNo"
                          @keydown.enter="fetchTgPayCashList"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="onClickedSearchButton"
                  >조회</erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border: none">
                    <ejs-grid-wrapper
                      ref="cashReceiptGrid"
                      v-bind="cashReceiptGridOptions"
                      @rowSelected="onGridRowSelected"
                      @keydown.enter.native="onCashReceiptCancelButtonClicked"
                    />
                  </div>
                </section>
                <section class="article-section" style="height: auto">
                  <div class="section-body" style="border: none">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <div class="title">취소사유</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="
                                          cancelInfo.payCashProofCancelCode
                                        "
                                        :dataSource="proofCancelCodeList"
                                        :allowFiltering="false"
                                        :fields="{
                                          text: 'comName',
                                          value: 'comCode',
                                        }"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)">
                              <div class="title">증빙번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        :disabled="
                                          cancelInfo.proofKind === 'VOL' ||
                                          cancelInfo.cardProof
                                        "
                                        v-model="cancelInfo.proofNo"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: calc(100% + 1px)">
                              <div class="title">카드증빙</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                        type="checkbox"
                                        class="e-input"
                                        :disabled="
                                          cancelInfo.proofKind === 'VOL'
                                        "
                                        v-model="cancelInfo.cardProof"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter" v-show="approveToggle">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :disabled="payProcessing"
                  @click="onClickedApprovalButton"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click="onCloseButtonClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
        <div class="windowFooter" v-show="!approveToggle">
          <ul class="button">
            <li class="cancel keyColor">
              <erp-button
                  ref="cashReceiptCancelButton"
                  button-div="DELETE"
                  :is-icon-custom="true"
                  @click="onCashReceiptCancelButtonClicked"
              >
                현금영수증 취소
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click="onCloseButtonClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<style scoped>
/*
.window.cashReceipt .windowContent .content-wrapper .body-article .section-body .body-data .field {
  width: 100%;
}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-01 .article-section.section-0101 {height: auto}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-01 .article-section.section-0102 {height: auto}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-01 .article-section.section-0102 .section-body .body-data .field .content.full {width: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-01 .article-section.section-0102 .section-body .body-data .field .content.full .row .item.form-group .button .confirm {width: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-01 .article-section.section-0102 .section-body .body-data .field .content.full .row .item.form-group .button .confirm button {width: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 {height: auto}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .section-body {height: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .article-left {height: auto; width: 70%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .article-left .article-section.section-020101 {padding: 0}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .article-left .article-section.section-020101 .section-body .body-data .field .title {width: 35%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .article-left .article-section.section-020101 .section-body .body-data .field .content {width: 65%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .article-right {height: auto; width: 30%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0201 .article-right .article-section.section-020102 {height: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0202 {height: 50%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0203 {height: auto}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0203 .section-body .body-data .field .content.full {width: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0203 .section-body .body-data .field .content.full .row .item.form-group .button .delete {width: 100%}
.window.cashReceipt .windowContent .content-wrapper .body-article .article-section.section-02 .article-section.section-0203 .section-body .body-data .field .content.full .row .item.form-group .button .delete button {width: 100%}
 */

.button-right {display: block; overflow: hidden; width: 45px; height: 52px; border: none; border-radius: 0; background: transparent url('../../assets/images/common/button-right.png') no-repeat center center; cursor: pointer;}
.button-left {display: block; overflow: hidden; width: 45px; height: 52px; border: none; border-radius: 0; background: transparent url('../../assets/images/common/button-left.png') no-repeat center center; cursor: pointer;}
</style>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import InputNumber from "@/components/common/InputNumber";
import {commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getFormattedDate, getTodayNavigationDate } from "@/utils/date";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import InputDate from "@/components/common/datetime/InputDate";
import { openERPPay } from "@/utils/Pay";

import GolfERPService from "@/service/GolfERPService";
import InputText from "@/components/common/text/InputText";
import { getDeviceInfo } from "@/utils/device";
import { validateFormRefs } from "@/utils/formUtil";
import {getFormattedTelNumber} from "@/utils/string";
import BillPrintUtil from "@/utils/billPrintUtil";
import {getReceiptInfo} from "@/api/common";

export default {
  name: "CashReceiptPopup",
  mixins: [confirmDialogMixin],
  components: {
    ErpButton,
    InputText,
    InputDate,
    EjsGridWrapper,
    InputDateRange,
    InputNumber,
  },
  async created() {
    const value = await getTodayNavigationDate("YYYY-MM-DD");
    this.today = value;
    this.cancelSearchOption.payDateFrom = value;
    this.cancelSearchOption.payDateTo = value;
    await this.fetchTgPayCashList();
  },
  data() {
    return {
      receiptInfo: {},
      printFlag: getDeviceInfo().isBillPrinterUse,
      operationMessage: null,
      payProcessing: false,
      approveToggle: true,
      proofKindList: commonCodesGetCommonCode("PROOF_KIND"),
      proofCancelCodeList: commonCodesGetCommonCode("PROOF_CANCEL_CODE"),
      storeCodeList: commonCodesGetCommonCode("STORE_CODE"),
      proofTypeList: [
        { name: "휴대폰번호", value: 0 },
        { name: "소득공제카드", value: 1 },
        { name: "신용카드", value: 2 },
      ],
      approvalInfo: {
        proofKind: "VOL",
        payAmt: 0,
        proofNo: null,
        cardProof: false,
        salesDiv: "SALES", // 해당 팝업에서는 'SALES' 고정
      },
      cancelSearchOption: {
        payDateFrom: getFormattedDate(new Date()),
        payDateTo: getFormattedDate(new Date()),
        approvalNo: null,
        proofNo: null,
      },
      cancelInfo: {
        payCashProofCancelCode: "1",
        proofNo: null,
        cardProof: false,
      },
      cashReceiptList: [],
      vanModule: {},
      today: null,
      bsnInfo: null,
      storeCode: null,
      van: null,
      validateRefList: {
        proofNo: {
          maxLength: 20,
        },
        payAmt: {
          required: true,
          min: 1,
          max: 10000000,
        },
      },
    };
  },
  computed: {
    cashReceiptGridOptions() {
      return {
        provides: [Resize, ForeignKey],
        selectionSettings: { checkboxOnly: false, type: "Single", mode: "Row" },
        columns: [
          {
            field: "id",
            allowEditing: false,
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "businessName",
            headerText: "영업장",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
          {
            field: "payDate",
            headerText: "승인일자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
          {
            field: "proofNo",
            headerText: "증빙번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
          {
            field: "approvalNo",
            headerText: "승인번호",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
          {
            field: "proofKind",
            headerText: "증빙종류",
            allowEditing: false,
            editType: "dropdownedit",
            type: "string",
            minWidth: 16,
            width: 110,
            textAlign: "left",
            isCommonCodeField: true,
            dataSource: this.proofKindList,
          },
          {
            field: "approvalAmount",
            headerText: "거래금액",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "right",
            isNumericType: true,
          },
          {
            field: "approvalDateTime",
            headerText: "거래일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 180,
            textAlign: "left",
          },
          {
            field: "userName",
            headerText: "담당자",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 80,
            textAlign: "left",
          },
        ],
        dataSource: this.cashReceiptList,
      };
    },
    approvalTaxInfo() {
      return this.approvalInfo.payAmt
          ? {
            payAmt: this.approvalInfo.payAmt,
            taxAmt:
                this.approvalInfo.payAmt -
                Math.round((this.approvalInfo.payAmt / 1.1) * 0.1),
            vatAmt: Math.round((this.approvalInfo.payAmt / 1.1) * 0.1),
            notaxAmt: 0,
            svcAmt: 0,
          }
          : {
            payAmt: 0,
            taxAmt: 0,
            vatAmt: 0,
            notaxAmt: 0,
            svcAmt: 0,
          };
    },
    searchPayDateRange: {
      get() {
        return {
          from: this.cancelSearchOption.payDateFrom,
          to: this.cancelSearchOption.payDateTo,
        };
      },
      set(searchPayDateRange) {
        this.cancelSearchOption.payDateFrom = searchPayDateRange.from;
        this.cancelSearchOption.payDateTo = searchPayDateRange.to;
      },
    },
    storeName() {
      const storeCode = this.storeCodeList.find(
          (storeCode) => storeCode.comCode === this.storeCode
      );
      return storeCode ? storeCode.comName : null;
    },
    bsnName() {
      return this.bsnInfo ? this.bsnInfo.bsnName : null;
    },
  },
  methods: {
    validateFormRefs,
    missingRequireValue(paramName) {
      throw new Error(`Require Value [${paramName}] is missing`);
    },
    async showPopup(storeCode) {
      if (!storeCode) {
        this.missingRequireValue("storeCode");
      }
      this.storeCode = storeCode;
      await this.initialize();
      setTimeout(() => {
        this.$refs.proofKind.focusIn();
      }, 0);
    },
    closePopup() {
      this.$emit("popupClosed");
    },
    handleDialogClose() {
      this.closePopup();
    },
    onCloseButtonClick() {
      this.closePopup();
    },
    onGridRowSelected(args) {
      this.cancelInfo.cardProof = args.data.cardProof;
    },
    async initialize() {
      const { value: bsnInfo } = await getStoreBsnMstInfo(this.storeCode);
      this.bsnInfo = bsnInfo;
      const { vanKind } = bsnInfo;
      this.van = vanKind;
    },
    onProofKindDropdownChange(args) {
      // 자진발급 시 증빈번호를 0100001234로 변경
      // 해당 값은 vanModule 내에서 강제 변경됨에 따라 실제로는 사용되지 않는 값이 됨
      if (args.value === "VOL") {
        this.approvalInfo.proofNo = "0100001234";
        return;
      }

      if (this.approvalInfo.proofNo === "0100001234") {
        this.approvalInfo.proofNo = "";
      }
    },
    startPay() {
      if (this.payProcessing) {
        return;
      }
      this.payProcessing = true;
      this.$refs["cashReceiptPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["cashReceiptPopup"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endPay() {
      if (!this.payProcessing) {
        return;
      }
      this.payProcessing = false;
      this.$refs["cashReceiptPopup"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["cashReceiptPopup"].$el.nextElementSibling.style.zIndex) - 1
      }`;
    },
    async onClickedApprovalButton() {
      try {
        this.$refs.payAmt.blur();
        // 정산 중일 진행 불가
        if (this.payProcessing) {
          return;
        }
        // 정산 진행중 flag 전환
        this.startPay();

        // validate
        if (!this.validate()) {
          this.endPay();
          return;
        }

        // 정산 진행 중에 값이 변하더라도 액션중에는 값이 변하지 않도록 DATA COPY
        const payDiv = "CASH";
        const bsnDate = this.today;
        const salesDiv = this.approvalInfo.salesDiv;
        // const storeCode = this.storeCode;
        const payCashInfo = Object.assign({}, this.approvalInfo);
        let payAmt = this.approvalInfo.payAmt;

        // 현재 계산할 세금액 계산
        const calculatedTaxInfo = await GolfERPService.fetchDepositIncomeTax("TAX", payAmt);

        // const payRequest = {
        //   payDate: bsnDate,
        //   storeCode: storeCode,
        //   salesDivision: salesDiv,
        //   visitId: 0,
        //   golfCabinDivision: this.bsnInfo.gcDiv, // 객실 구분 미 개발에 따른 하드코딩
        //   payPreOrFutureDivision: "1", // 후불
        //   payDivision: payDiv,
        //   approvalNo: null,
        //   payAmount: payAmt,
        //   taxAmount: calculatedTaxInfo.taxAmount,
        //   notaxAmount: calculatedTaxInfo.notaxAmount,
        //   vatAmount: calculatedTaxInfo.vatAmount,
        //   serviceAmount: calculatedTaxInfo.serviceAmount,
        //   remarks: null,
        //   /** @type {{businessId: Number, posNo: String, termId: String} | null} */
        //   businessId: this.bsnInfo.bsnId,
        //   posNo: getDeviceInfo().posNo,
        //   termId: this.bsnInfo.termId,
        //   tid: null,
        //   notaxCode: "TAX",
        //   /** @type {{depositId: String, useDate: String} | null} */
        //   depositUse: null,
        //   approvedCardFlag: false,
        //   approvedCard: null
        // };

        const addTransactionRequest = {
          van: this.van,
          payDivision: null,
          approvalDivision: "OK",
          totalAmount: payAmt,
          taxAmount: calculatedTaxInfo.taxAmount,
          vatAmount: calculatedTaxInfo.vatAmount,
          notaxAmount: calculatedTaxInfo.notaxAmount,
          serviceAmount: calculatedTaxInfo.serviceAmount,
          divideTerm: null,
          cardProof: null,
          proofKind: null,
          proofNo: null,
          approvalNo: null,
          approvalDateTime: null,
          cardNo: null,
          purchaseId: null,
          purchaseName: null,
          issueCompanyName: null,
          validTerm: null,
          storeCode: this.storeCode,
          businessId: this.bsnInfo.bsnId,
          posNo: getDeviceInfo().posNo,
          termId: this.bsnInfo.termId,
          payDate: bsnDate
        };

        this.operationMessage = "정산 처리되었습니다.";

        this.updateAddTransactionRequestForCashReceipt(
          addTransactionRequest,
          payCashInfo
        );

        let cashReceiptRequest = {};
        try {
          const {tid, status, message} = await this.payVan(
            addTransactionRequest
          );

          if (status === "SELFCANCEL") {
            this.operationMessage = message;
            this.errorToast(message);
            return;
          }

          cashReceiptRequest = {
            tid: tid,
            referenceId: null,
            salesDivision: salesDiv,
            isOnlyCashData: true
          };
        } catch (e) {
          if (e instanceof Error) {
            if (e.message !== "CONFIRM") {
              await this.errorPopup(
                `${e.message}<br/>현금영수증 발급에 실패하였습니다.`
              );

              this.operationMessage = e.message;
            }
          } else {
            await this.errorPopup(
              `${e}<br/>현금영수증 발급에 실패하였습니다.`
            );

            this.operationMessage = e;
          }

          console.error(e);

          return;
        }

        // 현금영수증 발행/취소 팝업은 TG_PAY_MST 테이블에는 INSERT 하지 않는다.
        // // 정산 API 호출
        // const payment = await this.sendPayResultToServer(payRequest);
        //
        // switch (salesDiv) {
        //   case "SALES":
        //     cashReceiptRequest.referenceId = payment.pay.id;
        //     break;
        //   case "CREDIT":
        //     cashReceiptRequest.referenceId = payment.creditReturn.id;
        //     break;
        // }

        const cashId = await GolfERPService.postPayCashReceipt(cashReceiptRequest);

        await this.paySuccess(cashId, salesDiv, payDiv);
      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage = e.message;
          console.error(e);
        } else {
          this.operationMessage = e;
        }

        if (this.operationMessage !== "CONFIRM") {
          await this.errorPopup(
            "정산에 실패하였습니다.<br/>" + this.operationMessage
          );
        }
      } finally {
        if (this.payProcessing) {
          this.endPay();
        }
      }


      // const payTransactionRequest = this.getCashReceiptApprovalTransactionRequest();
      // const { tid } = await this.payVan(payTransactionRequest);
      // try {
      //   await GolfERPService.postPayCashReceipt({
      //     tid: tid,
      //     salesDivision: this.approvalInfo.salesDiv,
      //   });
      //   this.errorToast("현금영수증 발급이 정상처리 되었습니다");
      // } catch (error) {
      //   if (
      //       await this.confirm(
      //           "서버 요청중 에러가 발생하였습니다. <br/> 결제 취소하시겠습니까?"
      //       )
      //   ) {
      //     const approvedPayTransaction = await GolfERPService.getPayTransaction(
      //         tid
      //     );
      //     const cancelTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
      //         approvedPayTransaction
      //     );
      //     await this.payVan(cancelTransactionRequest);
      //   }
      // }
      // await this.fetchTgPayCashList();
    },
    async paySuccess(id, salesDiv, payDiv) {
      this.endPay();

      if (id) {
        this.print(id, payDiv, salesDiv);
      }

      await this.fetchTgPayCashList();
    },
    async print(id, payDiv, salesDiv, signYN) {
      try {
        if (!this.printFlag) {
          return;
        }

        if (id === null) {
          return;
        }

        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.


        let receiptCode = payDiv.toUpperCase() + "_RECEIPT";



        let printSetData = null;

        await this.getReceiptInfo(id, payDiv);

        printSetData = {
          storeName: commonCodesGetComName(
            "STORE_CODE",
            this.receiptInfo.storeCode
          ),
          bsnDate: this.receiptInfo.payDate,
          lockerNo: this.receiptInfo.lockerNo,
          slipNo: this.receiptInfo.slipNo,
          cardKind: this.receiptInfo.issuCompanyName,
          cardNo: this.receiptInfo.cardNo,
          validTerm: this.receiptInfo.validTerm,
          approvalDt: this.receiptInfo.approvalDt,
          purcName: this.receiptInfo.purcName,
          rePrintDt: null,
          totAmt: this.receiptInfo.approvalAmt,
          taxAmt: this.receiptInfo.taxAmt,
          noTaxAmt: this.receiptInfo.notaxAmt,
          svcAmt:
            this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
          vatAmt: this.receiptInfo.vatAmt,
          approvalNo: this.receiptInfo.approvalNo,
          divideTerm: this.receiptInfo.divideTerm,
          merchantNo: this.receiptInfo.merchantNo,
          merchantName: this.receiptInfo.bizName,
          bizNo: this.receiptInfo.bizNo,
          rprsntvName: this.receiptInfo.rprsntvName,
          addr: this.receiptInfo.addr,
          telNo: getFormattedTelNumber(this.receiptInfo.telNo),
          faxNo: getFormattedTelNumber(this.receiptInfo.faxNo),
          proofName: this.receiptInfo.proofName,
          proofNo: this.receiptInfo.proofNo,
          cardTitle:
            this.receiptInfo.approvalDiv === "OK" ||
            this.receiptInfo.approvalDiv === "T_OK"
              ? "매출전표 (신용승인)"
              : "매출전표 (신용취소)",
          cashTitle:
            (this.receiptInfo.approvalDiv === "OK"
              ? "현금승인 "
              : "현금취소 ") +
            (this.receiptInfo.proofName
              ? "(" + this.receiptInfo.proofName + ")"
              : "(미발급)"),
          transTitle:
            (this.receiptInfo.approvalDiv === "OK"
              ? "계좌이체승인 "
              : "계좌이체취소 ") +
            (this.receiptInfo.proofName
              ? "(" + this.receiptInfo.proofName + ")"
              : "(미발급)"),
          signYn: signYN,
        };
        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this,receiptCode , true, printSetData, config);

      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage += `\n[영수증 발급 에러] ${e.message}`;
        } else {
          this.operationMessage += `\n[영수증 발급 에러] ${e}`;
        }
        await this.errorPopup(
          this.$t("vanModule.popupMessage.printReceiptFail")
        );
      }
    },
    async getReceiptInfo(payId, payDiv) {
      try {
        const {
          value: { receiptInfo },
        } = await getReceiptInfo(payId, payDiv, this.approvalInfo.salesDiv, true);

        if (payDiv === "CASH") {
          receiptInfo.proofName = commonCodesGetComName(
            "PROOF_KIND",
            receiptInfo.proofKind
          );
        }

        this.receiptInfo = receiptInfo;
      } catch (e) {
        console.error("getReceiptInfo.err.===>", e);
        throw e;
      }
    },
    async sendPayResultToServer(payRequest) {
      try {
        return await GolfERPService.postPay(payRequest);
      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage = e.message;
          console.error(e);
        } else {
          this.operationMessage = e;
        }

        throw e;
      }
    },
    updateAddTransactionRequestForCashReceipt(
      addTransactionRequest,
      payCashInfo
    ) {
      addTransactionRequest.cardProof = payCashInfo.cardProof;
      addTransactionRequest.proofKind = payCashInfo.proofKind;
      addTransactionRequest.proofNo = payCashInfo.proofNo;
      addTransactionRequest.payDivision = "CASH";
    },
    async onClickedSearchButton() {
      await this.fetchTgPayCashList();
    },
    async fetchTgPayCashList() {
      this.cancelInfo.cardProof = false;

      this.cashReceiptList = await GolfERPService.fetchCashReceipts(
        this.cancelSearchOption.payDateFrom,
        this.cancelSearchOption.payDateTo,
        this.cancelSearchOption.approvalNo,
        this.cancelSearchOption.proofNo
      );
    },
    async onCashReceiptCancelButtonClicked() {
      try {
        this.$refs.cashReceiptCancelButton.blur();
        const selectedRows = this.$refs.cashReceiptGrid.getSelectedRecords();
        if (selectedRows.length === 0) {
          this.errorToast("취소할 현금 영수증을 선택해 주세요");
          return;
        }

        const row = selectedRows[0];
        const cashReceipt = this.cashReceiptList.find(
          (value) => value.id === row.id
        );

        // 디밍 처리
        this.startPay();

        if (!(await this.confirm("현금 영수증을 취소하시겠습니까?"))) {
          return;
        }

        const payDiv = "CASH";

        const payTransactionRequest = this.createPayTransactionRequestByPayCashInfo(
          cashReceipt
        );
        const { tid, status, message} = await this.payVan(payTransactionRequest);

        if (status === "SELFCANCEL") {
          this.operationMessage = message;
          this.errorToast(message);
          return;
        }

        const cancelCashId = await GolfERPService.patchPayCashReceipt(
          cashReceipt.id,
          tid
        );

        if (status === "ALREADY_CANCELED") {
          this.infoToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
        } else {
          this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
          // this.popupData.isPayCash = true;
          // this.print(cancelCashId);
        }

        // try {
        //   // 현금영수증 발행/취소 팝업에서의 현금영수증 취소는 TG_PAY_MST 테이블의 데이터는 삭제처리 하지 않는다.
        //   // await cancelPayInfo(payCancelInfo);
        //
        //   this.print(cancelCashId);
        // } catch (e) {
        //   return;
        // }

        await this.paySuccess(cancelCashId, this.approvalInfo.salesDiv, payDiv);

        // const payTransactionRequest = this.getCashReceiptCancelTransactionRequest(
        //   cashReceipt
        // );
        // let isAlreadyCancel = false;
        // const {status} = await this.payVan(payTransactionRequest);
        // if (status === "ALREADY_CANCELED") {
        //   isAlreadyCancel = true;
        // }
        //
        // await GolfERPService.patchPayCashReceipt(row.id);
        // if (isAlreadyCancel) {
        //   this.errorToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
        // } else {
        //   this.errorToast("현금영수증 취소가 완료되었습니다");
        // }
        // await this.fetchTgPayCashList();
      } catch (e) {
        if (e instanceof Error) {
          this.pinnedToast({
            message: this.$t("vanModule.popupMessage.approveCancelFail") +
              "<br><br>" +
              e.message
          });
          this.operationMessage = e.message;
        } else {
          this.pinnedToast({
            message: this.$t("vanModule.popupMessage.approveCancelFail") + "<br><br>" + e
          });
          this.operationMessage = e;
        }
      } finally {
        this.endPay();
      }
    },
    createPayTransactionRequestByPayCashInfo(payCashInfo) {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "CANCEL",
        totalAmount: payCashInfo.approvalAmount,
        taxAmount: payCashInfo.taxAmount,
        vatAmount: payCashInfo.vatAmount,
        notaxAmount: payCashInfo.notaxAmount,
        serviceAmount: payCashInfo.serviceAmount,
        proofKind: payCashInfo.proofKind,
        //proofNo: this.approvalInfo.proofNo, null값이라 수정
        proofNo: this.cancelInfo.proofNo,
        cardProof: this.approvalInfo.cardProof,
        proofCancelCode: this.cancelInfo.payCashProofCancelCode,
        approvalNo: payCashInfo.approvalNo,
        approvalDateTime: payCashInfo.approvalDateTime,
        storeCode: payCashInfo.storeCode,
        businessId: payCashInfo.businessId,
        posNo: getDeviceInfo().posNo,
        termId: this.bsnInfo.termId,
        payDate: this.today,
        visitId: payCashInfo.visitId
      };
    },
    async payVan(transactionRequest) {
      const { tid, status, message } = await openERPPay(transactionRequest);

      switch (status) {
        case "APPROVED":
        case "CANCELED":
        case "ALREADY_CANCELED":
        case "SELFCANCEL":
          return { tid: tid, status: status, message: message };
        case "READY":
        case "REQUEST":
          throw new Error("결제가 취소되었습니다.");
        case "FAIL":
        case "ROLLBACK":
          throw new Error(message);
      }
    },
    getCashReceiptApprovalTransactionRequest() {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "OK",
        totalAmount: this.approvalTaxInfo.payAmt,
        taxAmount: this.approvalTaxInfo.taxAmt,
        vatAmount: this.approvalTaxInfo.vatAmt,
        notaxAmount: this.approvalTaxInfo.notaxAmt,
        serviceAmount: this.approvalTaxInfo.svcAmt,
        cardProof: this.approvalInfo.cardProof,
        proofKind: this.approvalInfo.proofKind,
        proofNo: this.approvalInfo.proofNo,
        storeCode: this.storeCode,
        businessId: this.bsnInfo.bsnId,
        posNo: getDeviceInfo().posNo,
        termId: this.bsnInfo.termId,
      };
    },
    getCashReceiptCancelTransactionRequest(cashReceipt) {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "CANCEL",
        totalAmount: cashReceipt.approvalAmount,
        taxAmount: cashReceipt.taxAmount,
        vatAmount: cashReceipt.vatAmount,
        notaxAmount: cashReceipt.notaxAmount,
        serviceAmount: cashReceipt.serviceAmount,
        cardProof: this.cancelInfo.cardProof,
        proofKind: cashReceipt.proofKind,
        proofNo: this.cancelInfo.proofNo,
        proofCancelCode: this.cancelInfo.payCashProofCancelCode,
        approvalNo: cashReceipt.approvalNo,
        approvalDateTime: cashReceipt.approvalDateTime,
        storeCode: this.storeCode,
        businessId: cashReceipt.businessId,
        posNo: getDeviceInfo().posNo,
        termId: cashReceipt.termId,
      };
    },
    getCancelTransactionRequestByApprovedPayTransaction(
      approvedPayTransaction
    ) {
      return {
        van: approvedPayTransaction.van,
        payDivision: approvedPayTransaction.payDivision,
        approvalDivision: "CANCEL",
        totalAmount: approvedPayTransaction.totalAmount,
        taxAmount: approvedPayTransaction.taxAmount,
        vatAmount: approvedPayTransaction.vatAmount,
        notaxAmount: approvedPayTransaction.notaxAmount,
        serviceAmount: approvedPayTransaction.serviceAmount,
        cardProof: this.approvalInfo.cardProof,
        proofKind: approvedPayTransaction.proofKind,
        proofNo: this.approvalInfo.proofNo,
        divideTerm: approvedPayTransaction.divideTerm,
        approvalNo: approvedPayTransaction.approvalNo,
        approvalDateTime: approvedPayTransaction.approvalDateTime,
        storeCode: this.storeCode,
        businessId: approvedPayTransaction.businessId,
        posNo: getDeviceInfo().posNo,
        termId: approvedPayTransaction.termId,
      };
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
    focusPayAmt() {
      this.$refs.payAmt.focus();
    },
  },
};
</script>
