<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="noFrontAndCartSalesSlipPopup"
      :header="'입장료 및 카트 전표 확인 [' + bsnDate + ']'"
      :allowDragging="true"
      :showCloseIcon="true"
      width="657"
      height="250"
      :position="{ X: 'right', Y: 'bottom' }"
      :isModal="false"
      :close="onNoFrontAndCartSalesSlipPopupClosed"
    >
      <div class="window rainSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="grid"
                      :provides="gridProvides"
                      :columns="gridColumns"
                      :dataSource="gridDataSource"
                      :isInPopup="true"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="closeNoFrontAndCartSalesSlipPopup">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "NoFrontAndCartSalesSlipPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      gridProvides: [Resize, ForeignKey],
      gridColumns: [
        {
          field: 'chkinId',
          type: 'number',
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: 'startTime',
          headerText: '시간',
          textAlign: 'center',
          width: 90,
          type: 'string',
        },
        {
          field: 'startCourse',
          headerText: '코스',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'visitName',
          headerText: '내장자',
          width: 120,
          type: 'string',
        },
        {
          field: 'lockerNo',
          headerText: '락카',
          textAlign: 'center',
          width: 70,
          type: 'string',
        },
        {
          field: 'paymtCode',
          headerText: '요금구분',
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'PAYMT_CODE',
          width: 90,
          type: 'string',
        },
        {
          field: 'totAmt',
          headerText: '요금',
          textAlign: 'right',
          isNumericType: true,
          width: 90,
          type: 'number',
        }
      ],
      gridDataSource: [],
      bsnDate: null
    };
  },
  methods: {
    showPopup(args) {
      console.log('args.===>', args);
      this.bsnDate = args.bsnDate;
      this.gridDataSource = args.gridDataSource;
    },
    onNoFrontAndCartSalesSlipPopupClosed() {
      this.$emit("popupClosed");
    },
    closeNoFrontAndCartSalesSlipPopup() {
      this.$refs.noFrontAndCartSalesSlipPopup.hide();
    }
  },
};
</script>
