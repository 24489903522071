<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      header="갤러리 등록"
      height="181"
      isModal="true"
      ref="galleryAddPopup"
      showCloseIcon="true"
      width="337"
      v-bind="dialogProps"
      @close="onGalleryAddPopupClosed"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window galleryRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">갤러리명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="galleryName"
                                        v-model.trim="galleryName"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">부 구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="partDiv"
                                        v-model="partDiv"
                                        :dataSource="
                                          galleryAddDropdownOptions.partDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          galleryAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                        @change="onGalleryAddPartDivChange"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field groupName">
                              <!-- 필수 : required -->
                              <div class="title">단체명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="grpName"
                                        v-model.trim="grpName"
                                        @change="onGrpNameChanged"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          @click.native="
                                            onGroupPopupOpen(true)
                                          "
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ grpNo }}
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-icon-custom="true"
                  @click.native="onGalleryAdd"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeGalleryAddPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";

import { getGroupList } from "@/api/group";
import { saveGallery } from "@/api/frontManagement";

import groupPopup from "../../views/golf-reservation/popup/GroupPopup";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

export default {
  name: "galleryAddPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    groupPopup,
    ErpButton
  },
  props: { bsnDate: String },
  mounted() {
    this.show();
  },
  data() {
    return {
      galleryName: null,
      partDiv: null,
      grpNo: null,
      grpName: null,
      grpKind: null,
      galleryAddDropdownOptions: {
        partDivOptions: commonCodesGetCommonCode("PART_DIV"),
        fields: { text: "comName", value: "comCode" },
      },
      isGroupPopupOpen: false,
    };
  },
  computed: {
    dialogProps() {
      return {
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
      };
    },
  },
  methods: {
    show() {
      this.$refs["galleryAddPopup"].show();
    },
    hide() {
      this.$refs["galleryAddPopup"].hide();
    },
    onGalleryAdd() {
      if (this.galleryName === null || this.galleryName.trim() === "") {
        this.errorToast("갤러리명을 입력해 주십시오");
        this.$refs.galleryName.focusIn();
        return;
      }
      if (this.partDiv === null) {
        this.errorToast("부 구분을 선택해 주십시오");
        this.$refs.partDiv.focusIn();
        return;
      }

      let data = {
        galleryInfo: {
          galleryName: this.galleryName, // 갤러리명
          partDiv: this.partDiv, // 부구분
        },
      };
      if (this.grpNo !== null) {
        data.galleryInfo.grpNo = this.grpNo; // 단체번호
        data.galleryInfo.grpKind = this.grpKind; // 단체종류
        data.galleryInfo.grpName = this.grpName; // 단체명
      }

      saveGallery(this.bsnDate, data)
        .then(() => {
          this.infoToast(this.$t("main.popupMessage.saved"));
          this.hide();
        })
        .catch((error) => {
          console.log("saveGallery.err.===>", error);
        });
    },
    closeGalleryAddPopup() {
      this.hide();
    },
    onGalleryAddPopupClosed() {
      this.$emit("popupClosed", { isReload: true });
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onGalleryAddPartDivChange() {
      console.log("partDivChange.===>", this.partDiv);
    },
    onGrpNameChanged(args) {
      console.log("onGrpNameChanged.===>", args.event);
      if (args.event) {
        console.log(
          "## 단체명을 바꾼 후 Focus out. 단체 조회 후 결과에 따라 단체 팝업을 띄운다. 단체 검색어 :" +
            args.value
        );
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        getGroupList({ searchValue: args.value })
          .then((response) => {
            const groupList = response.value.groupInfoList;
            if (groupList && groupList.length > 1) {
              this.onGroupPopupOpen();
            } else if (groupList && groupList.length === 1) {
              // 그룹정보 설정
              this.setGrpInfos(groupList[0]);
            } else {
              this.onGroupPopupOpen(false, "TEMP");
            }
          })
          .catch((error) => {
            console.log("getGroupList.err.===>", error);
          });
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.grpName
        ? (groupPopupData.grpName = this.grpName)
        : null;
      groupPopupData.resveDates = [this.bsnDate];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.grpName;
      }

      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }
    },
    setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.grpNo = grpInfo.grpNo;
      this.grpKind = grpInfo.grpKind;
      this.grpName = grpInfo.grpName;
    },
    clearGrpInfo() {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.grpNo = null;
      this.grpKind = null;
      this.grpName = null;
    },
  },
};
</script>
