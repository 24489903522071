<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="walkInAddPopup"
      :header="`워크인 등록`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="543"
      :height="isTimeListGridShow ? 600 : 300"
      :isModal="true"
      :close="onWalkInAddPopupClosed"
    >
      <div class="window walkInRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">정보 입력</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field groupName">
                              <!-- 필수 : required -->
                              <div class="title">단체명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="grpName"
                                        v-model.trim="grpName"
                                        @change="onGrpNameChanged"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          @click.native="onGroupPopupOpen(true)"
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="grpKind"
                                        v-model="grpKind"
                                        :dataSource="
                                          walkInAddDropdownOptions.grpKindOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          walkInAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ grpNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field reservationName">
                              <!-- 필수 : required -->
                              <div class="title required">예약자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        id="resveName"
                                        name="resveName"
                                        ref="resveName"
                                        v-model.trim="resveName"
                                        @change="onResveNameChanged"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          @click.native="
                                            onMemberPopupOpen(
                                              true,
                                              'RESVE',
                                              resveName,
                                              null
                                            )
                                          "
                                        >
                                          검색
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">회원번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ memberNo }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">회원구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="memberDiv"
                                        name="memberDiv"
                                        ref="memberDiv"
                                        v-model="memberDiv"
                                        :dataSource="
                                          walkInAddDropdownOptions.memberDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          walkInAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">회원등급</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="memberGrade"
                                        name="memberGrade"
                                        ref="memberGrade"
                                        v-model="memberGrade"
                                        :dataSource="
                                          walkInAddDropdownOptions.memberGradeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          walkInAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">연락자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        id="contactName"
                                        name="contactName"
                                        ref="contactName"
                                        v-model.trim="contactName"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">연락처</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        id="contactTelInfo"
                                        name="contactTelInfo"
                                        v-model="contactTel"
                                        :max-length="11"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">예약종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="resveKind"
                                        name="resveKind"
                                        ref="resveKind"
                                        v-model="resveKind"
                                        :dataSource="
                                          walkInAddDropdownOptions.resveKindOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          walkInAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">예약채널</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        id="resveChannel"
                                        name="resveChannel"
                                        ref="resveChannel"
                                        v-model="resveChannel"
                                        :dataSource="
                                          walkInAddDropdownOptions.resveChannelOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="
                                          walkInAddDropdownOptions.fields
                                        "
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        id="resveRemarks"
                                        v-model="resveRemarks"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02" v-if="isTimeListGridShow">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">시간 선택</div>
                      <div class="header-caption">
                        [{{ numberWithCommas(timeListData.length) }}]
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <ejs-grid-wrapper
                      v-bind="timeListGridOptions"
                      @rowSelected="onRowSelected"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="add keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="openResveTimeAddPopup"
              >
                시간 추가
              </erp-button>
            </li>
            <li class="confirm keyColor" v-if="!isTimeListGridShow">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  :is-shortcut-button="true"
                  @click.native="onWalkInAdd"
              >
                적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeWalkInAddPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <resve-time-add-popup
      v-if="isResveTimeAddPopupOpen"
      ref="resveTimeAddPopup"
      @popupClosed="onResveTimeAddPopupClosed"
    ></resve-time-add-popup>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    ></group-popup>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    ></member-select-popup>
  </div>
</template>

<script>
import { Resize, ForeignKey } from "@syncfusion/ej2-vue-grids";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  commonCodesGetCommonCode,
  commonCodesGetJsonData,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import commonMixin from "@/views/layout/mixin/commonMixin";

import resveTimeAddPopup from "./ResveTimeAddPopup";
import groupPopup from "../../golf-reservation/popup/GroupPopup";
import memberSelectPopup from "@/views/common/MemberSelectPopup";

import { getTimeTable } from "@/api/timeTable";
import { getGroupList } from "@/api/group";
import { getMemberList } from "@/api/member";
import { validateFormRefs } from "@/utils/formUtil";
import { numberWithCommas } from "@/utils/number";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import {getDeviceInfo} from "@/utils/device";

export default {
  name: "walkInAddPopup",
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    EjsGridWrapper,
    resveTimeAddPopup,
    groupPopup,
    memberSelectPopup,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      isTimeListGridShow: false,
      isResveTimeAddPopupOpen: false,
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      timeListData: [],
      bsnDate: null,
      sexCode: null,
      grpName: null,
      grpNo: null,
      grpKind: commonCodeGetComCodeDefaultValue("GRP_KIND"),
      resveName: null,
      memberNo: null,
      membershipId: null,
      memberDiv: null,
      memberGrade: null,
      contactName: null,
      contactTel: null,
      resveKind: "DAY",
      resveChannel: commonCodeGetComCodeDefaultValue("RESVE_CHANNEL"),
      resveRemarks: null,
      walkInAddDropdownOptions: {
        fields: { text: "comName", value: "comCode" },
        grpKindOptions: commonCodesGetCommonCode("GRP_KIND"),
        memberDivOptions: commonCodesGetCommonCode("MEMBER_DIV"),
        memberGradeOptions: commonCodesGetCommonCode("MEMBER_GRADE"),
        resveKindOptions: commonCodesGetCommonCode("RESVE_KIND"),
        resveChannelOptions: commonCodesGetCommonCode("RESVE_CHANNEL"),
      },
      validateRefList: {
        resveName: { required: true },
        memberDiv: { required: true },
        memberGrade: { required: true },
        contactName: { required: true },
        resveKind: { required: true },
        resveChannel: { required: true },
      },
      timeListViewOptions: {
        holeDivDataSource: commonCodesGetCommonCode("HOLE_DIV"),
        partDivDataSource: commonCodesGetCommonCode("PART_DIV"),
        resveCourseDataSource: commonCodesGetCommonCode("COURSE_CODE"),
      },
      selectedTime: null,
    };
  },
  computed: {
    timeListGridOptions() {
      return {
        provides: [Resize, ForeignKey],
        dataSource: this.timeListData,
        columns: [
          {
            field: "timeId",
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "partDiv",
            headerText: "부",
            type: "string",
            minWidth: 16,
            textAlign: "left",
            isCommonCodeField: true,
            dataSource: this.timeListViewOptions.partDivDataSource,
          },
          {
            field: "resveTime",
            headerText: "시간",
            type: "string",
            minWidth: 16,
            textAlign: "left",
            isTimeType: true,
          },
          {
            field: "resveCourse",
            headerText: "코스",
            type: "string",
            minWidth: 16,
            textAlign: "left",
            isCommonCodeField: true,
            dataSource: this.timeListViewOptions.resveCourseDataSource,
          },
          {
            field: "holeDiv",
            headerText: "홀",
            type: "string",
            minWidth: 16,
            textAlign: "left",
            isCommonCodeField: true,
            dataSource: this.timeListViewOptions.holeDivDataSource,
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    async onWalkInAdd() {
      if (this.validate()) {
        // time 체크
        if (this.selectedTime === null) {
          this.errorToast("시간을 선택하시거나 추가해 주십시오");
          return;
        }
        await this.saveWalkin();
      }
    },
    async saveWalkin() {
      // data 구성
      let data = {
        visitDate: this.bsnDate, // 내장일자
        resveName: this.resveName, // 예약자명
        membershipId: this.membershipId, // 회원권ID
        memberDiv: this.memberDiv, // 회원구분
        memberGrade: this.memberGrade, // 회원등급
        contactName: this.contactName, // 연락자명
        contactTel: this.contactTel, // 연락처
        resveKind: this.resveKind, // 예약종류
        resveChannel: this.resveChannel, // 예약채널
        resveRemarks: this.resveRemarks, // 예약비고
        sexCode: this.sexCode, // 성별코드
        selectedTime: this.selectedTime,
      };

      if (this.grpNo !== null) {
        data.grpNo = this.grpNo;
        data.grpKind = this.grpKind;
      }

      await GolfErpAPI.postRegisterWalkIn(
        {
          timeId: this.selectedTime.timeId,
          partDivision: this.selectedTime.partDiv,
          reservationTime: this.selectedTime.resveTime,
          reservationCourse: this.selectedTime.resveCourse,
          holeDivision: this.selectedTime.holeDiv,
        },
        this.bsnDate || null,
        this.resveKind || null,
        this.resveChannel || null,
        this.resveName || null,
        this.resveRemarks || null,
        this.membershipId || null,
        this.memberNo || null,
        this.memberDiv || null,
        this.memberGrade || null,
        this.contactName || null,
        this.contactTel || null,
        this.grpNo || null,
        this.grpKind || null,
        this.grpName || null,
        getDeviceInfo().posNo || null
      );
      this.infoToast(this.$t("main.popupMessage.saved"));
      this.$emit("popupClosed", { isReload: true });
    },
    closeWalkInAddPopup() {
      this.$refs.walkInAddPopup.hide();
    },
    onWalkInAddPopupClosed() {
      this.$emit("popupClosed");
    },
    async showWalkInAddPopup(bsnDate) {
      this.bsnDate = bsnDate;
      if (this.isTimeListGridShow) {
        await this.getTimeTable(bsnDate);
      }
    },
    async getTimeTable(bsnDate) {
      const bsnCode = null;
      const partDiv = null;
      const resveCourse = null;
      const resveTime = null;
      const timeStatus = "EMPTY";

      const response = await getTimeTable(
        true,
        bsnCode,
        partDiv,
        resveCourse,
        bsnDate,
        bsnDate,
        resveTime,
        resveTime,
        timeStatus
      );
      this.timeListData = response.value.timeTableList;
    },
    async onGrpNameChanged(args) {
      if (args.event) {
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        const response = await getGroupList({ searchValue: args.value });
        const groupList = response.value.groupInfoList;
        if (groupList && groupList.length > 1) {
          this.onGroupPopupOpen();
        } else if (groupList && groupList.length === 1) {
          // 그룹정보 설정
          this.setGrpInfos(groupList[0]);
        } else {
          this.onGroupPopupOpen(false, "TEMP");
        }
      }
    },
    onGroupPopupOpen(isOpenedWithButton = false, grpKind) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.grpName
        ? (groupPopupData.grpName = this.grpName)
        : null;
      groupPopupData.resveDates = [this.bsnDate];
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      groupPopupData.grpKind = grpKind;
      if (grpKind) {
        groupPopupData.grpName = null;
        groupPopupData.tempGrpName = this.grpName;
      }
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    openResveTimeAddPopup() {
      if (this.validate()) {
        this.isResveTimeAddPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.resveTimeAddPopup.showResveTimeAddPopup(
            this.bsnDate,
            "18",
            "walkIn"
          );
        });
      }
    },
    async onResveTimeAddPopupClosed(event) {
      this.isResveTimeAddPopupOpen = false;
      if (event) {
        this.selectedTime = event;
        await this.saveWalkin();
      }
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }
    },
    setGrpInfos(grpInfo) {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.grpNo = grpInfo.grpNo;
      this.grpKind = grpInfo.grpKind;
      this.grpName = grpInfo.grpName;
    },
    clearGrpInfo() {
      // 같은 이름 입력시 change 이벤트 작동안하는 부분을 위한 input-text 초기화.
      this.$refs.grpName.setEmpty();
      this.grpNo = null;
      this.grpKind = null;
      this.grpName = null;
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (!data.popupData.isOpenedWithButton) {
        // textbox를 통해 팝업을 띄웠는데, 선택하지 않고 닫은 경우
        this.setResvNonMemberInfos();
      }
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      if (data.selectedRowData) {
        this.setResvMemberInfos(data.selectedRowData);
        // this.reservationCheck(null, null, data.selectedRowData.membershipId, data.selectedRowData.memberNo) // 위약 체크
      }
    },
    setResvMemberInfos(memberInfo) {
      this.resveName = memberInfo.memberName;
      this.membershipId = memberInfo.membershipId;
      this.memberNo = memberInfo.memberNo;
      this.memberDiv = memberInfo.memberDiv;
      this.memberGrade = memberInfo.memberGrade;
      this.sexCode = memberInfo.sexCode;
      this.contactTel = memberInfo.hpNo;
      this.contactName = memberInfo.memberName;
      this.resveKind = "DAY";
      this.resveChannel = commonCodeGetComCodeDefaultValue("RESVE_CHANNEL");

      // 회원권정보 조회
      // this.getReservationDetailMemberLinkList(memberInfo.memberNo)

      // validator message 갱신
      // this.reNewValidateMessage()
    },
    setResvNonMemberInfos() {
      this.membershipId = null;
      this.memberNo = null;
      this.memberDiv = "NOM";
      this.memberGrade = commonCodesGetJsonData("MEMBER_DIV", "NOM").find(
        (codeJson) => codeJson.IDX === 4
      ).ATTRB;
      this.sexCode = "M";
      this.contactName = this.resveName;
      this.resveKind = "DAY";
      this.resveChannel = commonCodeGetComCodeDefaultValue("RESVE_CHANNEL");

      // validator message 갱신
      // this.reNewValidateMessage()
    },
    async onResveNameChanged(args) {
      if (args.event) {
        // console.log('## 예약자명을 바꾼 후 Focus out. 예약자 조회 후 결과에 따라 예약 팝업을 띄운다. 예약자명 검색어 :' + args.value)
        // alert(args.value + '로 검색한 건이 0건이면 비회원 처리, 1건이면 해당 회원을 예약자로 설정, 2건 이상이면 회원정보 팝업을 띄운다')

        // [예약자 Case 분기(항목은 ReservationInfo.java 참고)]
        // #1. 수정인 경우: 예약번호 설정. 추가인 경우 : 예약번호 없음
        // #2. 회원/비회원 위임자의 경우
        // - 회원인 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우) : 예약자명, 회원권 ID, 회원번호, 회원구분, 회원등급, 성별코드, 연락처 설정. 연락자명을 예약자명과 동일하게 설정
        // - 비회원인 경우(검색한 건이 0건이거나, 띄운 회원정보 팝업을 닫는다
        //      : 비회원 선택 시 회원권 ID, 회원번호 Clear, 성별코드(M). 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정.
        //      : 연락자명은 현재 남아있는 예약자명과 동일하게 설정. 나머지는 그대로 놔 둠
        const response = await getMemberList({ memberNameNo: args.value });
        const memberList = response.value.memberList;
        if (memberList && memberList.length > 0) {
          // 1건 이상 검색 : 팝업을 띄운다
          this.onMemberPopupOpen(false, "RESV", args.value, null);
          // } else if (memberList && memberList.length === 1) { // 1건 검색 : 회원 설정
          //   this.setResvMemberInfos(memberList[0]);
          //   // this.reservationCheck(null, null, memberList[0].membershipId, memberList[0].memberNo) // 위약 체크
        } else {
          // 검색 건수 없음 : 비회원 설정
          this.setResvNonMemberInfos();
        }
      }
    },
    onRowSelected(args) {
      this.selectedTime = args.data;
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
