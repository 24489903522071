<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="rainSettingPopup"
      :header="`악천후 ${rainHeaderTitle}`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="320"
      :isModal="true"
      :close="onRainSettingPopupClosed"
    >
      <div class="window rainSetting">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-text">
                      {{ confirmMessage }}
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                button-div="SAVE"
                @click.native="onRainSetting"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="closeRainSettingPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { rainCancel } from "@/api/frontManagement";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "rainSettingPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  data() {
    return {
      rainHeaderTitle: null,
      confirmMessage: '선택한 타임을 "악천후 취소" 적용 하시겠습니까?',
      selectedRowcnt: 0,
      popupData: null,
      cancelStatus: null,
    };
  },
  methods: {
    async onRainSetting() {
      // console.log(JSON.stringify(this.popupData, null, 2))
      // console.log(this.cancelStatus)

      await rainCancel(this.cancelStatus, this.popupData);
      this.infoToast("설정되었습니다");
      this.$emit("popupClosed", { isReload: true });
    },
    closeRainSettingPopup() {
      this.$refs.rainSettingPopup.hide();
    },
    onRainSettingPopupClosed() {
      this.$emit("popupClosed");
    },
    showRainSettingPopup(popupData, cancelStatus) {
      this.popupData = popupData;
      this.cancelStatus = cancelStatus;

      if (cancelStatus === "NOCANCEL") {
        this.confirmMessage = "선택한 타임을 예약 상태로 변경하시곘습니까?";
      } else if (cancelStatus === "CANCEL") {
        this.confirmMessage =
          "선택한 타임을 악천후(우천취소) 상태로 변경하시겠습니까";
      }

      this.selectedRowcnt = popupData.visitTimeInfoLists.length;
      this.rainHeaderTitle = "취소 적용 [선택 " + this.selectedRowcnt + "팀]";
    },
  },
};
</script>
